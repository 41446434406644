import { MediaType } from '../../../slimbe/components/PresentationCard/CardMedia';
import { DefaultApi } from '../autogenerated/slimbeApiClient/apis/DefaultApi';
import { GetProfileDto } from '../autogenerated/slimbeApiClient/models/GetProfileDto';

import { CollectionCard } from './CollectionCard';

export type Profile = GetProfileDto;

export interface ProfilesContextInterface {
  getCoaches: (count?: number) => Promise<Profile[]>;
  getAsCardCollection: (
    count?: number,
  ) => Promise<CollectionCard[] | undefined>;
  getByUserUID: (userUID: string | undefined) => Promise<Profile | undefined>;
  getById: (id: string | null | undefined) => Promise<Profile | undefined>;
  addSlimbeProfile: (
    email: string,
    displayName: string,
    newsletter: boolean,
    uid: string,
  ) => Promise<Profile | undefined>;
  updateSlimbeProfile: (profile: Profile) => Promise<void>;
  delete: (id: string) => Promise<void>;
  searchCardCollection: (
    searchText: string,
    disciplineArray: string[],
    areaArray: string[],
  ) => Promise<CollectionCard[] | undefined>;
}

export const ProfilesContext = (api: DefaultApi) => {
  const getAsCardCollection = (profiles: Profile[]) => {
    return profiles.map(p => {
      return {
        id: p?.id || '',
        userUID: p?.uid || '',
        title: p?.name || '',
        mediaType: MediaType.Image,
        src: p?.image || '',
        avatar: p?.image || '',
        author: p?.name || '',
        description: p?.shortDescription || '',
        time: p?.time,
        disciplines: p?.disciplineArray || [],
      };
    });
  };

  return {
    getCoaches: async (count?: number) => {
      return api.profileControllerGetAll(count);
    },

    getAsCardCollection: async (count?: number) => {
      const collection = await api.profileControllerGetAll(count);
      return Promise.all(getAsCardCollection(collection));
    },

    getByUserUID: async (userUID: string | undefined) => {
      if (!userUID) {
        return;
      }

      const profs = await api.profileControllerGetByUid(userUID);
      if (profs.length === 1) {
        return profs[0] as Promise<Profile>;
      }
    },

    getById: async (id: string | null | undefined) => {
      if (!id || id === undefined) {
        return;
      }
      return await api.profileControllerGet(id);
    },

    addSlimbeProfile: async (
      email: string,
      displayName: string,
      newsletter: boolean,
      uid: string,
    ) => {
      return api.profileControllerCreate({
        email: email || '',
        name: displayName || '',
        isAdmin: false,
        newsletter: newsletter,
        image: `https://avatars.dicebear.com/api/bottts/${uid}.svg?bold=true&background=transparent`,
      });
    },

    updateSlimbeProfile: async (profile: Profile) => {
      if (!profile.id) {
        return;
      }
      return api.profileControllerUpdate(profile.id, profile);
    },

    delete: async (id: string) => {
      await api.profileControllerDelete(id);
    },

    searchCardCollection: async (
      searchText: string,
      disciplineArray: string[],
      areaArray: string[],
    ) => {
      const collection = await api.profileControllerSearch(
        searchText,
        disciplineArray,
        areaArray,
      );
      return Promise.all(getAsCardCollection(collection));
    },
  };
};
