import React, { useEffect, useState } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Image, Modal } from 'react-bootstrap';

export interface ImageWithMagnifierProps {
  src: string;
  alt: string;
  description?: string;
  show?: boolean;
  clickableZoom?: boolean;
  setShow?: (show: boolean) => void;
}
export const ImageWithMagnifier: React.FC<ImageWithMagnifierProps> = ({
  src,
  alt,
  description,
  show,
  setShow,
  clickableZoom,
}) => {
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    show && setModalShow(show);
  }, [show]);

  const onShowOrHide = (show: boolean) => {
    setShow && setShow(show);
    setModalShow(show);
  };

  const onKeypress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      onShowOrHide(true);
      event.preventDefault();
    }
  };
  return (
    <>
      <div className="image-magnifier">
        {clickableZoom ? (
          <>
            <Image src={src} alt={alt} onClick={() => onShowOrHide(true)} />
            <Icon.ZoomIn
              onClick={() => onShowOrHide(true)}
              onKeyPress={onKeypress}
              tabIndex={0}
            />
          </>
        ) : (
          <>
            <Image src={src} alt={alt} />
            <Icon.ZoomIn />
          </>
        )}
      </div>
      <Modal
        show={modalShow}
        onHide={() => onShowOrHide(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="image-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{alt}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={src} alt={alt} />
          {description && <p className="mt-3">{description}</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};
