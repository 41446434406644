import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import { EditableFormNode } from '../EditableCollection/EditableNode';

import { EditStringNodeProps } from './EditStringNodeProps';

export const ProfileNameNode: React.FC<EditStringNodeProps> = ({
  value,
  mode,
  onSave,
}) => {
  const [title, setTitle] = useState<string>(value);
  const titleRef = useRef<HTMLInputElement>(null);
  const setFocusTitle = () => {
    titleRef.current?.focus();
  };
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const txt = e.target.value;
    setTitle(txt);
    if (mode.newRecord) {
      await onSave(txt);
    }
  };

  return (
    <EditableFormNode
      mode={mode}
      viewNode={<h1 className="anime-name">{title}</h1>}
      editableNode={
        <div className="form-name">
          <Form.Group className="form-floating" controlId="name">
            <Form.Control
              type="text"
              defaultValue={title}
              onChange={onChange}
              ref={titleRef}
            />
            <Form.Label>Imię i nazwisko, pseudonim</Form.Label>
          </Form.Group>
        </div>
      }
      onSave={() => onSave(title)}
      onClose={() => setTitle(value)}
      setFocus={setFocusTitle}
    />
  );
};
