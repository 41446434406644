/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateProfileDto
 */
export interface CreateUpdateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    uid?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProfileDto
     */
    time?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    isAdmin?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    newsletter?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProfileDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    www?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    fb?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    instagram?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProfileDto
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateProfileDto
     */
    disciplineArray?: Array<string> | null;
}

export function CreateUpdateProfileDtoFromJSON(json: any): CreateUpdateProfileDto {
    return CreateUpdateProfileDtoFromJSONTyped(json, false);
}

export function CreateUpdateProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'uid': !exists(json, 'uid') ? undefined : json['uid'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
        'newsletter': !exists(json, 'newsletter') ? undefined : json['newsletter'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'www': !exists(json, 'www') ? undefined : json['www'],
        'fb': !exists(json, 'fb') ? undefined : json['fb'],
        'instagram': !exists(json, 'instagram') ? undefined : json['instagram'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'disciplineArray': !exists(json, 'disciplineArray') ? undefined : json['disciplineArray'],
    };
}

export function CreateUpdateProfileDtoToJSON(value?: CreateUpdateProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'image': value.image,
        'description': value.description,
        'uid': value.uid,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'isAdmin': value.isAdmin,
        'newsletter': value.newsletter,
        'active': value.active,
        'phone': value.phone,
        'www': value.www,
        'fb': value.fb,
        'instagram': value.instagram,
        'shortDescription': value.shortDescription,
        'disciplineArray': value.disciplineArray,
    };
}


