import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { Photo } from '../../common/slimbeCore/contexts/PhotosContext';
import { PhotoForm } from '../components/Photos/PhotoForm';

interface ParamTypes {
  id: string;
  editMode: string;
}
export const PhotoFormView: React.FC = () => {
  const slimbeApi = useSlimbeCore();
  const { id, editMode } = useParams<ParamTypes>();
  const [photo, setPhoto] = useState<Photo>();

  useEffect(() => {
    let mounted = true;

    id &&
      slimbeApi.photos.getById(id).then(ph => {
        if (mounted) {
          setPhoto(ph);
        }
      });

    return () => {
      mounted = false;
    };
  }, [id, slimbeApi.photos]);

  return (
    <>
      {photo && <PhotoForm photo={photo} editMode={editMode === 'true'} />}
      {!id && <PhotoForm editMode={false} />}
    </>
  );
};
