import React, { ErrorInfo, ReactNode } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Component } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    console.error('ErrorBoundary caught the error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Modal centered={true} backdrop="static" show={true}>
          <Modal.Body>
            <Alert variant="danger" className="m-0">
              <Alert.Heading>Błąd systemu</Alert.Heading>
              <hr />
              Jeśli zdarzenie będzie się powtarzać, proszę powiadomić
              Administratora Systemu.
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => window.open('/', '_self')}
                  variant="outline-danger"
                >
                  Zamknij
                </Button>
              </div>
            </Alert>
          </Modal.Body>
        </Modal>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
