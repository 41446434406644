import React, { useState } from 'react';

import {
  Configuration,
  DefaultApi,
  RequestOpts,
} from './autogenerated/slimbeApiClient';
import {
  ArticlesContext,
  ArticlesContextInterface,
} from './contexts/ArticlesContext';
import {
  ProfilesContext,
  ProfilesContextInterface,
} from './contexts/ProfilesContext';
import {
  WorkoutsContext,
  WorkoutsContextInterface,
} from './contexts/WorkoutsContext';
import { TagEntry } from './contexts/DisciplinesContext';
import {
  SignInContext,
  SignInContextInterface,
} from './contexts/SignInContext';
import { LikesContext, LikesContextInterface } from './contexts/LikesContext';
import {
  SlogansContext,
  SlogansContextInterface,
} from './contexts/SlogansContext';
import {
  PhotosContext,
  PhotosContextInterface,
} from './contexts/PhotosContext';

export interface SlimbeCoreContextType {
  profiles: ProfilesContextInterface;
  signIn: SignInContextInterface;
  articles: ArticlesContextInterface;
  workouts: WorkoutsContextInterface;
  likes: LikesContextInterface;
  slogans: SlogansContextInterface;
  photos: PhotosContextInterface;
  areasEntry: Promise<TagEntry[]>;
  disciplinesEntry: Promise<TagEntry[]>;
}

const getContext: (api: DefaultApi) => SlimbeCoreContextType = api => {
  const areasEntryCount = async () => {
    const areas = await api.areaControllerGetAll();
    const areasEntry = areas.map(async doc => {
      return {
        value: doc.id,
        label: doc.name,
        image: doc.image,
        idParent: doc.parentId,
      };
    });
    const areasFirst = await Promise.all(areasEntry);
    return [...areasFirst];
  };

  const disciplineEntryCount = async () => {
    const disciplines = await api.disciplineControllerGetAll();
    const disciplinesEntry = disciplines.map(async doc => {
      return {
        value: doc.id,
        label: doc.name,
      };
    });
    return await Promise.all(disciplinesEntry);
  };

  const areasArray = areasEntryCount() as Promise<TagEntry[]>;
  const disciplinesArray = disciplineEntryCount() as Promise<TagEntry[]>;

  return {
    areasEntry: areasArray,
    disciplinesEntry: disciplinesArray,
    profiles: ProfilesContext(api),
    likes: LikesContext(api),
    slogans: SlogansContext(api),
    articles: ArticlesContext(api),
    workouts: WorkoutsContext(api),
    photos: PhotosContext(api),
    signIn: SignInContext(),
  };
};

class APIWithHandler extends DefaultApi {
  constructor(
    configuration: Configuration,
    // private responseExceptionHandler: (response: Response) => Response,
    // private runExclusive: (callback: () => Promise<void>) => Promise<void>,
  ) {
    super(configuration);
  }
  protected async request(context: RequestOpts): Promise<Response> {
    // let response = new Response();

    const token = localStorage.getItem('mzt-token') || undefined;
    context.headers['Authorization'] = 'Bearer ' + token;
    return await super.request(context);
  }
}

export const SlimbeCoreContext = React.createContext(
  getContext(
    new APIWithHandler(
      new Configuration({
        basePath: process.env.REACT_APP_SLIMBEAPI_URL,
      }),
    ),
  ),
);

export const SlimbeCoreProvider: React.FC = ({ children }) => {
  const [context, setDefaultContext] = useState(
    getContext(
      new APIWithHandler(
        new Configuration({
          basePath: process.env.REACT_APP_SLIMBEAPI_URL,
        }),
      ),
    ),
  );
  const value = React.useMemo(
    () => ({
      ...context,
      setDefaultContext,
    }),
    [context],
  );

  return (
    <SlimbeCoreContext.Provider value={value}>
      {children}
    </SlimbeCoreContext.Provider>
  );
};
