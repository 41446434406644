// eslint-disable-next-line import/named
import { UserCredential } from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../../common/navigation';
import RegisterAgreeDialog from '../../../slimbe/components/RegisterProfile/RegisterAgreeDialog';
import ValidationAlert from '../ValidationAlert';

import { SignIn } from './SignIn';

export type Props = {
  open: boolean;
  onSignIn: (usr: string, pwd: string) => Promise<UserCredential | void>;
  signInWithGoogle: () => Promise<UserCredential | void>;
  signInWithFacebook: () => Promise<UserCredential | void>;
};

export const SignInDialog: React.FC<Props> = ({
  onSignIn,
  signInWithGoogle,
  signInWithFacebook,
}) => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();
  const auth = useAuth();

  const mountedRef = useRef(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [userCred, setUserCred] = useState<UserCredential | void>();

  const [needUpdate, setNeedUpdate] = useState<boolean>(false);

  const [showRegisterAgree, setShowRegisterAgree] = useState(false);

  useEffect(() => {
    mountedRef.current = true;

    if (needUpdate && userCred) {
      setNeedUpdate(false);
      slimbeApi.profiles
        .getByUserUID(userCred.user.uid)
        .then(prof => {
          if (!prof && mountedRef.current) {
            setShowRegisterAgree(true);
          } else {
            auth.updateProfile(prof);
            nav.myProfile();
          }
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem profilu. ${error}`),
        );
    }
    return () => {
      mountedRef.current = false;
    };
  }, [needUpdate, auth, nav, slimbeApi.profiles, userCred]);

  const handleSignIn = async (usr: string, pwd: string) => {
    onSignIn(usr, pwd)
      .then(uC => {
        if (mountedRef.current) {
          setUserCred(uC);
          setNeedUpdate(true);
        }
      })
      .catch(err => {
        if (mountedRef.current) {
          setErrorText('Błąd podczas logowania: ' + (err as Error).message);
          setShowError(true);
        }
      });
  };

  const handleGoogleSignIn = async () => {
    signInWithGoogle()
      .then(uC => {
        if (mountedRef.current) {
          setUserCred(uC);
          setNeedUpdate(true);
        }
      })
      .catch(err => {
        if (mountedRef.current) {
          setErrorText('Błąd podczas logowania: ' + (err as Error).message);
          setShowError(true);
        }
      });
  };

  const handleFacebookSignIn = async () => {
    signInWithFacebook()
      .then(uC => {
        if (mountedRef.current) {
          setUserCred(uC);
          setNeedUpdate(true);
        }
      })
      .catch(err => {
        if (mountedRef.current) {
          setErrorText('Błąd podczas logowania: ' + (err as Error).message);
          setShowError(true);
        }
      });
  };

  return (
    <>
      <RegisterAgreeDialog
        show={showRegisterAgree}
        userCred={userCred}
        onHide={() => {
          setShowRegisterAgree(false);
        }}
      />
      <ValidationAlert
        heading={'LOGOWANIE'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />

      <SignIn
        onSignIn={handleSignIn}
        signInWithGoogle={handleGoogleSignIn}
        signInWithFacebook={handleFacebookSignIn}
      />
    </>
  );
};
