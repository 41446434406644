import { DefaultApi } from '../autogenerated/slimbeApiClient/apis/DefaultApi';
import { GetLikeDto } from '../autogenerated/slimbeApiClient/models/GetLikeDto';

import { ArticleEntity } from './ArticlesContext';
import { Profile } from './ProfilesContext';
import { WorkoutEntity } from './WorkoutsContext';

export interface LikesContextInterface {
  createForProfile: (
    guid: string,
    profile: Profile,
  ) => Promise<GetLikeDto | undefined>;
  getCountForProfile: (profileId: string) => Promise<number>;
  createForArticle: (
    guid: string,
    article: ArticleEntity,
  ) => Promise<GetLikeDto | undefined>;
  getCountForArticle: (articleId: string) => Promise<number>;
  createForWorkout: (
    guid: string,
    workout: WorkoutEntity,
  ) => Promise<GetLikeDto | undefined>;
  getCountForWorkout: (workoutId: string) => Promise<number>;
  getByGuidProfile: (guid: string, profileId: string) => Promise<boolean>;
  getByGuidArticle: (guid: string, articleId: string) => Promise<boolean>;
  getByGuidWorkout: (guid: string, workoutId: string) => Promise<boolean>;
}

export const LikesContext = (api: DefaultApi) => {
  return {
    createForProfile: async (guid: string, profile: Profile) => {
      if (!profile) {
        return;
      }
      return api.likeControllerCreate({ guid: guid, profile: profile });
    },

    getCountForProfile: async (profileId: string) => {
      return api.likeControllerGetCountByProfile(profileId);
    },

    createForArticle: async (guid: string, article: ArticleEntity) => {
      if (!article) {
        return;
      }
      return api.likeControllerCreate({ guid: guid, article: article });
    },

    getCountForArticle: async (articleId: string) => {
      return api.likeControllerGetCountByArticle(articleId);
    },

    createForWorkout: async (guid: string, workout: WorkoutEntity) => {
      if (!workout) {
        return;
      }
      return api.likeControllerCreate({ guid: guid, workout: workout });
    },

    getCountForWorkout: async (workoutId: string) => {
      return api.likeControllerGetCountByWorkout(workoutId);
    },

    getByGuidProfile: async (guid: string, profileId: string) => {
      return api
        .likeControllerGetByGuidProfile(guid, profileId)
        .then(profiles => profiles.length > 0);
    },

    getByGuidArticle: (guid: string, articleId: string) => {
      return api
        .likeControllerGetByGuidArticle(guid, articleId)
        .then(articles => articles.length > 0);
    },

    getByGuidWorkout: (guid: string, workoutId: string) => {
      return api
        .likeControllerGetByGuidWorkout(guid, workoutId)
        .then(workouts => workouts.length > 0);
    },
  };
};
