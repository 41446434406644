/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUpdateArticleDto,
    CreateUpdateArticleDtoFromJSON,
    CreateUpdateArticleDtoToJSON,
    CreateUpdatePhotoDto,
    CreateUpdatePhotoDtoFromJSON,
    CreateUpdatePhotoDtoToJSON,
    CreateUpdateProfileDto,
    CreateUpdateProfileDtoFromJSON,
    CreateUpdateProfileDtoToJSON,
    CreateUpdateSloganDto,
    CreateUpdateSloganDtoFromJSON,
    CreateUpdateSloganDtoToJSON,
    CreateUpdateWorkoutDto,
    CreateUpdateWorkoutDtoFromJSON,
    CreateUpdateWorkoutDtoToJSON,
    GetAreaDto,
    GetAreaDtoFromJSON,
    GetAreaDtoToJSON,
    GetArticleDto,
    GetArticleDtoFromJSON,
    GetArticleDtoToJSON,
    GetDisciplineDto,
    GetDisciplineDtoFromJSON,
    GetDisciplineDtoToJSON,
    GetLikeDto,
    GetLikeDtoFromJSON,
    GetLikeDtoToJSON,
    GetPhotoDto,
    GetPhotoDtoFromJSON,
    GetPhotoDtoToJSON,
    GetProfileDto,
    GetProfileDtoFromJSON,
    GetProfileDtoToJSON,
    GetSloganDto,
    GetSloganDtoFromJSON,
    GetSloganDtoToJSON,
    GetWorkoutDto,
    GetWorkoutDtoFromJSON,
    GetWorkoutDtoToJSON,
} from '../models';

export interface AreaControllerGetAllRequest {
    limit?: number;
}

export interface AreaControllerGetDetailedRequest {
    parentId: string;
}

export interface ArticleControllerCreateRequest {
    getArticleDto: GetArticleDto;
}

export interface ArticleControllerDeleteRequest {
    id: string;
}

export interface ArticleControllerGetRequest {
    id: string;
}

export interface ArticleControllerGetAllRequest {
    limit?: number;
}

export interface ArticleControllerGetByUserUidRequest {
    userUid: string;
}

export interface ArticleControllerSearchRequest {
    text: string;
    disciplines: Array<string>;
    areas: Array<string>;
}

export interface ArticleControllerUpdateRequest {
    id: string;
    createUpdateArticleDto: CreateUpdateArticleDto;
}

export interface DisciplineControllerGetRequest {
    id: string;
}

export interface LikeControllerCreateRequest {
    getLikeDto: GetLikeDto;
}

export interface LikeControllerDeleteRequest {
    id: string;
}

export interface LikeControllerGetByGuidArticleRequest {
    guid: string;
    articleId: string;
}

export interface LikeControllerGetByGuidProfileRequest {
    guid: string;
    profileId: string;
}

export interface LikeControllerGetByGuidWorkoutRequest {
    guid: string;
    workoutId: string;
}

export interface LikeControllerGetCountByArticleRequest {
    articleId: string;
}

export interface LikeControllerGetCountByProfileRequest {
    profileId: string;
}

export interface LikeControllerGetCountByWorkoutRequest {
    workoutId: string;
}

export interface PhotoControllerCreateRequest {
    getPhotoDto: GetPhotoDto;
}

export interface PhotoControllerDeleteRequest {
    id: string;
}

export interface PhotoControllerGetRequest {
    id: string;
}

export interface PhotoControllerGetByUserUIDRequest {
    userUID: string;
}

export interface PhotoControllerUpdateRequest {
    id: string;
    createUpdatePhotoDto: CreateUpdatePhotoDto;
}

export interface ProfileControllerCreateRequest {
    getProfileDto: GetProfileDto;
}

export interface ProfileControllerDeleteRequest {
    id: string;
}

export interface ProfileControllerGetRequest {
    id: string;
}

export interface ProfileControllerGetAllRequest {
    limit?: number;
}

export interface ProfileControllerGetByUidRequest {
    uid: string;
}

export interface ProfileControllerSearchRequest {
    text: string;
    disciplines: Array<string>;
    areas: Array<string>;
}

export interface ProfileControllerUpdateRequest {
    id: string;
    createUpdateProfileDto: CreateUpdateProfileDto;
}

export interface SloganControllerGetOneByTypeRequest {
    type: string;
}

export interface SloganControllerUpdateRequest {
    id: string;
    createUpdateSloganDto: CreateUpdateSloganDto;
}

export interface WorkoutControllerCreateRequest {
    getWorkoutDto: GetWorkoutDto;
}

export interface WorkoutControllerDeleteRequest {
    id: string;
}

export interface WorkoutControllerGetRequest {
    id: string;
}

export interface WorkoutControllerGetAllRequest {
    limit?: number;
}

export interface WorkoutControllerGetByUserUidRequest {
    userUid: string;
}

export interface WorkoutControllerSearchRequest {
    text: string;
    disciplines: Array<string>;
    areas: Array<string>;
}

export interface WorkoutControllerUpdateRequest {
    id: string;
    createUpdateWorkoutDto: CreateUpdateWorkoutDto;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async areaControllerGetAllRaw(requestParameters: AreaControllerGetAllRequest): Promise<runtime.ApiResponse<Array<GetAreaDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/area`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAreaDtoFromJSON));
    }

    /**
     */
    async areaControllerGetAll(limit?: number): Promise<Array<GetAreaDto>> {
        const response = await this.areaControllerGetAllRaw({ limit: limit });
        return await response.value();
    }

    /**
     */
    async areaControllerGetDetailedRaw(requestParameters: AreaControllerGetDetailedRequest): Promise<runtime.ApiResponse<Array<GetAreaDto>>> {
        if (requestParameters.parentId === null || requestParameters.parentId === undefined) {
            throw new runtime.RequiredError('parentId','Required parameter requestParameters.parentId was null or undefined when calling areaControllerGetDetailed.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.parentId !== undefined) {
            queryParameters['parentId'] = requestParameters.parentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/area/getDetailed/byParentId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAreaDtoFromJSON));
    }

    /**
     */
    async areaControllerGetDetailed(parentId: string): Promise<Array<GetAreaDto>> {
        const response = await this.areaControllerGetDetailedRaw({ parentId: parentId });
        return await response.value();
    }

    /**
     */
    async areaControllerGetGeneralRaw(): Promise<runtime.ApiResponse<Array<GetAreaDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/area/getGeneral`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetAreaDtoFromJSON));
    }

    /**
     */
    async areaControllerGetGeneral(): Promise<Array<GetAreaDto>> {
        const response = await this.areaControllerGetGeneralRaw();
        return await response.value();
    }

    /**
     */
    async articleControllerCreateRaw(requestParameters: ArticleControllerCreateRequest): Promise<runtime.ApiResponse<GetArticleDto>> {
        if (requestParameters.getArticleDto === null || requestParameters.getArticleDto === undefined) {
            throw new runtime.RequiredError('getArticleDto','Required parameter requestParameters.getArticleDto was null or undefined when calling articleControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetArticleDtoToJSON(requestParameters.getArticleDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async articleControllerCreate(getArticleDto: GetArticleDto): Promise<GetArticleDto> {
        const response = await this.articleControllerCreateRaw({ getArticleDto: getArticleDto });
        return await response.value();
    }

    /**
     */
    async articleControllerDeleteRaw(requestParameters: ArticleControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async articleControllerDelete(id: string): Promise<void> {
        await this.articleControllerDeleteRaw({ id: id });
    }

    /**
     */
    async articleControllerGetRaw(requestParameters: ArticleControllerGetRequest): Promise<runtime.ApiResponse<GetArticleDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetArticleDtoFromJSON(jsonValue));
    }

    /**
     */
    async articleControllerGet(id: string): Promise<GetArticleDto> {
        const response = await this.articleControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async articleControllerGetAllRaw(requestParameters: ArticleControllerGetAllRequest): Promise<runtime.ApiResponse<Array<GetArticleDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/article`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetArticleDtoFromJSON));
    }

    /**
     */
    async articleControllerGetAll(limit?: number): Promise<Array<GetArticleDto>> {
        const response = await this.articleControllerGetAllRaw({ limit: limit });
        return await response.value();
    }

    /**
     */
    async articleControllerGetByUserUidRaw(requestParameters: ArticleControllerGetByUserUidRequest): Promise<runtime.ApiResponse<Array<GetArticleDto>>> {
        if (requestParameters.userUid === null || requestParameters.userUid === undefined) {
            throw new runtime.RequiredError('userUid','Required parameter requestParameters.userUid was null or undefined when calling articleControllerGetByUserUid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/article/get/byUid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetArticleDtoFromJSON));
    }

    /**
     */
    async articleControllerGetByUserUid(userUid: string): Promise<Array<GetArticleDto>> {
        const response = await this.articleControllerGetByUserUidRaw({ userUid: userUid });
        return await response.value();
    }

    /**
     */
    async articleControllerSearchRaw(requestParameters: ArticleControllerSearchRequest): Promise<runtime.ApiResponse<Array<GetArticleDto>>> {
        if (requestParameters.text === null || requestParameters.text === undefined) {
            throw new runtime.RequiredError('text','Required parameter requestParameters.text was null or undefined when calling articleControllerSearch.');
        }

        if (requestParameters.disciplines === null || requestParameters.disciplines === undefined) {
            throw new runtime.RequiredError('disciplines','Required parameter requestParameters.disciplines was null or undefined when calling articleControllerSearch.');
        }

        if (requestParameters.areas === null || requestParameters.areas === undefined) {
            throw new runtime.RequiredError('areas','Required parameter requestParameters.areas was null or undefined when calling articleControllerSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.disciplines) {
            queryParameters['disciplines'] = requestParameters.disciplines;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/article/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetArticleDtoFromJSON));
    }

    /**
     */
    async articleControllerSearch(text: string, disciplines: Array<string>, areas: Array<string>): Promise<Array<GetArticleDto>> {
        const response = await this.articleControllerSearchRaw({ text: text, disciplines: disciplines, areas: areas });
        return await response.value();
    }

    /**
     */
    async articleControllerUpdateRaw(requestParameters: ArticleControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling articleControllerUpdate.');
        }

        if (requestParameters.createUpdateArticleDto === null || requestParameters.createUpdateArticleDto === undefined) {
            throw new runtime.RequiredError('createUpdateArticleDto','Required parameter requestParameters.createUpdateArticleDto was null or undefined when calling articleControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/article/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateArticleDtoToJSON(requestParameters.createUpdateArticleDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async articleControllerUpdate(id: string, createUpdateArticleDto: CreateUpdateArticleDto): Promise<void> {
        await this.articleControllerUpdateRaw({ id: id, createUpdateArticleDto: createUpdateArticleDto });
    }

    /**
     */
    async disciplineControllerGetRaw(requestParameters: DisciplineControllerGetRequest): Promise<runtime.ApiResponse<GetDisciplineDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling disciplineControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discipline/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDisciplineDtoFromJSON(jsonValue));
    }

    /**
     */
    async disciplineControllerGet(id: string): Promise<GetDisciplineDto> {
        const response = await this.disciplineControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async disciplineControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetDisciplineDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/discipline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetDisciplineDtoFromJSON));
    }

    /**
     */
    async disciplineControllerGetAll(): Promise<Array<GetDisciplineDto>> {
        const response = await this.disciplineControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async likeControllerCreateRaw(requestParameters: LikeControllerCreateRequest): Promise<runtime.ApiResponse<GetLikeDto>> {
        if (requestParameters.getLikeDto === null || requestParameters.getLikeDto === undefined) {
            throw new runtime.RequiredError('getLikeDto','Required parameter requestParameters.getLikeDto was null or undefined when calling likeControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/like`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetLikeDtoToJSON(requestParameters.getLikeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLikeDtoFromJSON(jsonValue));
    }

    /**
     */
    async likeControllerCreate(getLikeDto: GetLikeDto): Promise<GetLikeDto> {
        const response = await this.likeControllerCreateRaw({ getLikeDto: getLikeDto });
        return await response.value();
    }

    /**
     */
    async likeControllerDeleteRaw(requestParameters: LikeControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling likeControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async likeControllerDelete(id: string): Promise<void> {
        await this.likeControllerDeleteRaw({ id: id });
    }

    /**
     */
    async likeControllerGetByGuidArticleRaw(requestParameters: LikeControllerGetByGuidArticleRequest): Promise<runtime.ApiResponse<Array<GetLikeDto>>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling likeControllerGetByGuidArticle.');
        }

        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling likeControllerGetByGuidArticle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.guid !== undefined) {
            queryParameters['guid'] = requestParameters.guid;
        }

        if (requestParameters.articleId !== undefined) {
            queryParameters['articleId'] = requestParameters.articleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/get/byGuidArticle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetLikeDtoFromJSON));
    }

    /**
     */
    async likeControllerGetByGuidArticle(guid: string, articleId: string): Promise<Array<GetLikeDto>> {
        const response = await this.likeControllerGetByGuidArticleRaw({ guid: guid, articleId: articleId });
        return await response.value();
    }

    /**
     */
    async likeControllerGetByGuidProfileRaw(requestParameters: LikeControllerGetByGuidProfileRequest): Promise<runtime.ApiResponse<Array<GetLikeDto>>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling likeControllerGetByGuidProfile.');
        }

        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling likeControllerGetByGuidProfile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.guid !== undefined) {
            queryParameters['guid'] = requestParameters.guid;
        }

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/get/byGuidProfile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetLikeDtoFromJSON));
    }

    /**
     */
    async likeControllerGetByGuidProfile(guid: string, profileId: string): Promise<Array<GetLikeDto>> {
        const response = await this.likeControllerGetByGuidProfileRaw({ guid: guid, profileId: profileId });
        return await response.value();
    }

    /**
     */
    async likeControllerGetByGuidWorkoutRaw(requestParameters: LikeControllerGetByGuidWorkoutRequest): Promise<runtime.ApiResponse<Array<GetLikeDto>>> {
        if (requestParameters.guid === null || requestParameters.guid === undefined) {
            throw new runtime.RequiredError('guid','Required parameter requestParameters.guid was null or undefined when calling likeControllerGetByGuidWorkout.');
        }

        if (requestParameters.workoutId === null || requestParameters.workoutId === undefined) {
            throw new runtime.RequiredError('workoutId','Required parameter requestParameters.workoutId was null or undefined when calling likeControllerGetByGuidWorkout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.guid !== undefined) {
            queryParameters['guid'] = requestParameters.guid;
        }

        if (requestParameters.workoutId !== undefined) {
            queryParameters['workoutId'] = requestParameters.workoutId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/get/byGuidWorkout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetLikeDtoFromJSON));
    }

    /**
     */
    async likeControllerGetByGuidWorkout(guid: string, workoutId: string): Promise<Array<GetLikeDto>> {
        const response = await this.likeControllerGetByGuidWorkoutRaw({ guid: guid, workoutId: workoutId });
        return await response.value();
    }

    /**
     */
    async likeControllerGetCountByArticleRaw(requestParameters: LikeControllerGetCountByArticleRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.articleId === null || requestParameters.articleId === undefined) {
            throw new runtime.RequiredError('articleId','Required parameter requestParameters.articleId was null or undefined when calling likeControllerGetCountByArticle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.articleId !== undefined) {
            queryParameters['articleId'] = requestParameters.articleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/get/CountByArticle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async likeControllerGetCountByArticle(articleId: string): Promise<number> {
        const response = await this.likeControllerGetCountByArticleRaw({ articleId: articleId });
        return await response.value();
    }

    /**
     */
    async likeControllerGetCountByProfileRaw(requestParameters: LikeControllerGetCountByProfileRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling likeControllerGetCountByProfile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.profileId !== undefined) {
            queryParameters['profileId'] = requestParameters.profileId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/get/CountByProfile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async likeControllerGetCountByProfile(profileId: string): Promise<number> {
        const response = await this.likeControllerGetCountByProfileRaw({ profileId: profileId });
        return await response.value();
    }

    /**
     */
    async likeControllerGetCountByWorkoutRaw(requestParameters: LikeControllerGetCountByWorkoutRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.workoutId === null || requestParameters.workoutId === undefined) {
            throw new runtime.RequiredError('workoutId','Required parameter requestParameters.workoutId was null or undefined when calling likeControllerGetCountByWorkout.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.workoutId !== undefined) {
            queryParameters['workoutId'] = requestParameters.workoutId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/like/get/CountByWorkout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async likeControllerGetCountByWorkout(workoutId: string): Promise<number> {
        const response = await this.likeControllerGetCountByWorkoutRaw({ workoutId: workoutId });
        return await response.value();
    }

    /**
     */
    async photoControllerCreateRaw(requestParameters: PhotoControllerCreateRequest): Promise<runtime.ApiResponse<GetPhotoDto>> {
        if (requestParameters.getPhotoDto === null || requestParameters.getPhotoDto === undefined) {
            throw new runtime.RequiredError('getPhotoDto','Required parameter requestParameters.getPhotoDto was null or undefined when calling photoControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/photo`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPhotoDtoToJSON(requestParameters.getPhotoDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPhotoDtoFromJSON(jsonValue));
    }

    /**
     */
    async photoControllerCreate(getPhotoDto: GetPhotoDto): Promise<GetPhotoDto> {
        const response = await this.photoControllerCreateRaw({ getPhotoDto: getPhotoDto });
        return await response.value();
    }

    /**
     */
    async photoControllerDeleteRaw(requestParameters: PhotoControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling photoControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/photo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async photoControllerDelete(id: string): Promise<void> {
        await this.photoControllerDeleteRaw({ id: id });
    }

    /**
     */
    async photoControllerGetRaw(requestParameters: PhotoControllerGetRequest): Promise<runtime.ApiResponse<GetPhotoDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling photoControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/photo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPhotoDtoFromJSON(jsonValue));
    }

    /**
     */
    async photoControllerGet(id: string): Promise<GetPhotoDto> {
        const response = await this.photoControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async photoControllerGetByUserUIDRaw(requestParameters: PhotoControllerGetByUserUIDRequest): Promise<runtime.ApiResponse<Array<GetPhotoDto>>> {
        if (requestParameters.userUID === null || requestParameters.userUID === undefined) {
            throw new runtime.RequiredError('userUID','Required parameter requestParameters.userUID was null or undefined when calling photoControllerGetByUserUID.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userUID !== undefined) {
            queryParameters['userUID'] = requestParameters.userUID;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/photo/get/byUserUID`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetPhotoDtoFromJSON));
    }

    /**
     */
    async photoControllerGetByUserUID(userUID: string): Promise<Array<GetPhotoDto>> {
        const response = await this.photoControllerGetByUserUIDRaw({ userUID: userUID });
        return await response.value();
    }

    /**
     */
    async photoControllerUpdateRaw(requestParameters: PhotoControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling photoControllerUpdate.');
        }

        if (requestParameters.createUpdatePhotoDto === null || requestParameters.createUpdatePhotoDto === undefined) {
            throw new runtime.RequiredError('createUpdatePhotoDto','Required parameter requestParameters.createUpdatePhotoDto was null or undefined when calling photoControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/photo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdatePhotoDtoToJSON(requestParameters.createUpdatePhotoDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async photoControllerUpdate(id: string, createUpdatePhotoDto: CreateUpdatePhotoDto): Promise<void> {
        await this.photoControllerUpdateRaw({ id: id, createUpdatePhotoDto: createUpdatePhotoDto });
    }

    /**
     */
    async profileControllerCreateRaw(requestParameters: ProfileControllerCreateRequest): Promise<runtime.ApiResponse<GetProfileDto>> {
        if (requestParameters.getProfileDto === null || requestParameters.getProfileDto === undefined) {
            throw new runtime.RequiredError('getProfileDto','Required parameter requestParameters.getProfileDto was null or undefined when calling profileControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetProfileDtoToJSON(requestParameters.getProfileDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerCreate(getProfileDto: GetProfileDto): Promise<GetProfileDto> {
        const response = await this.profileControllerCreateRaw({ getProfileDto: getProfileDto });
        return await response.value();
    }

    /**
     */
    async profileControllerDeleteRaw(requestParameters: ProfileControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profileControllerDelete(id: string): Promise<void> {
        await this.profileControllerDeleteRaw({ id: id });
    }

    /**
     */
    async profileControllerGetRaw(requestParameters: ProfileControllerGetRequest): Promise<runtime.ApiResponse<GetProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProfileDtoFromJSON(jsonValue));
    }

    /**
     */
    async profileControllerGet(id: string): Promise<GetProfileDto> {
        const response = await this.profileControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async profileControllerGetAllRaw(requestParameters: ProfileControllerGetAllRequest): Promise<runtime.ApiResponse<Array<GetProfileDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetAll(limit?: number): Promise<Array<GetProfileDto>> {
        const response = await this.profileControllerGetAllRaw({ limit: limit });
        return await response.value();
    }

    /**
     */
    async profileControllerGetByUidRaw(requestParameters: ProfileControllerGetByUidRequest): Promise<runtime.ApiResponse<Array<GetProfileDto>>> {
        if (requestParameters.uid === null || requestParameters.uid === undefined) {
            throw new runtime.RequiredError('uid','Required parameter requestParameters.uid was null or undefined when calling profileControllerGetByUid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profile/get/byUid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerGetByUid(uid: string): Promise<Array<GetProfileDto>> {
        const response = await this.profileControllerGetByUidRaw({ uid: uid });
        return await response.value();
    }

    /**
     */
    async profileControllerSearchRaw(requestParameters: ProfileControllerSearchRequest): Promise<runtime.ApiResponse<Array<GetProfileDto>>> {
        if (requestParameters.text === null || requestParameters.text === undefined) {
            throw new runtime.RequiredError('text','Required parameter requestParameters.text was null or undefined when calling profileControllerSearch.');
        }

        if (requestParameters.disciplines === null || requestParameters.disciplines === undefined) {
            throw new runtime.RequiredError('disciplines','Required parameter requestParameters.disciplines was null or undefined when calling profileControllerSearch.');
        }

        if (requestParameters.areas === null || requestParameters.areas === undefined) {
            throw new runtime.RequiredError('areas','Required parameter requestParameters.areas was null or undefined when calling profileControllerSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.disciplines) {
            queryParameters['disciplines'] = requestParameters.disciplines;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/profile/search/s`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProfileDtoFromJSON));
    }

    /**
     */
    async profileControllerSearch(text: string, disciplines: Array<string>, areas: Array<string>): Promise<Array<GetProfileDto>> {
        const response = await this.profileControllerSearchRaw({ text: text, disciplines: disciplines, areas: areas });
        return await response.value();
    }

    /**
     */
    async profileControllerUpdateRaw(requestParameters: ProfileControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profileControllerUpdate.');
        }

        if (requestParameters.createUpdateProfileDto === null || requestParameters.createUpdateProfileDto === undefined) {
            throw new runtime.RequiredError('createUpdateProfileDto','Required parameter requestParameters.createUpdateProfileDto was null or undefined when calling profileControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/profile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateProfileDtoToJSON(requestParameters.createUpdateProfileDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async profileControllerUpdate(id: string, createUpdateProfileDto: CreateUpdateProfileDto): Promise<void> {
        await this.profileControllerUpdateRaw({ id: id, createUpdateProfileDto: createUpdateProfileDto });
    }

    /**
     */
    async sloganControllerGetAllRaw(): Promise<runtime.ApiResponse<Array<GetSloganDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/slogan`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSloganDtoFromJSON));
    }

    /**
     */
    async sloganControllerGetAll(): Promise<Array<GetSloganDto>> {
        const response = await this.sloganControllerGetAllRaw();
        return await response.value();
    }

    /**
     */
    async sloganControllerGetOneByTypeRaw(requestParameters: SloganControllerGetOneByTypeRequest): Promise<runtime.ApiResponse<GetSloganDto>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling sloganControllerGetOneByType.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/slogan/get/oneByType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSloganDtoFromJSON(jsonValue));
    }

    /**
     */
    async sloganControllerGetOneByType(type: string): Promise<GetSloganDto> {
        const response = await this.sloganControllerGetOneByTypeRaw({ type: type });
        return await response.value();
    }

    /**
     */
    async sloganControllerUpdateRaw(requestParameters: SloganControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sloganControllerUpdate.');
        }

        if (requestParameters.createUpdateSloganDto === null || requestParameters.createUpdateSloganDto === undefined) {
            throw new runtime.RequiredError('createUpdateSloganDto','Required parameter requestParameters.createUpdateSloganDto was null or undefined when calling sloganControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/slogan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateSloganDtoToJSON(requestParameters.createUpdateSloganDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sloganControllerUpdate(id: string, createUpdateSloganDto: CreateUpdateSloganDto): Promise<void> {
        await this.sloganControllerUpdateRaw({ id: id, createUpdateSloganDto: createUpdateSloganDto });
    }

    /**
     */
    async workoutControllerCreateRaw(requestParameters: WorkoutControllerCreateRequest): Promise<runtime.ApiResponse<GetWorkoutDto>> {
        if (requestParameters.getWorkoutDto === null || requestParameters.getWorkoutDto === undefined) {
            throw new runtime.RequiredError('getWorkoutDto','Required parameter requestParameters.getWorkoutDto was null or undefined when calling workoutControllerCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetWorkoutDtoToJSON(requestParameters.getWorkoutDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkoutDtoFromJSON(jsonValue));
    }

    /**
     */
    async workoutControllerCreate(getWorkoutDto: GetWorkoutDto): Promise<GetWorkoutDto> {
        const response = await this.workoutControllerCreateRaw({ getWorkoutDto: getWorkoutDto });
        return await response.value();
    }

    /**
     */
    async workoutControllerDeleteRaw(requestParameters: WorkoutControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workoutControllerDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async workoutControllerDelete(id: string): Promise<void> {
        await this.workoutControllerDeleteRaw({ id: id });
    }

    /**
     */
    async workoutControllerGetRaw(requestParameters: WorkoutControllerGetRequest): Promise<runtime.ApiResponse<GetWorkoutDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workoutControllerGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWorkoutDtoFromJSON(jsonValue));
    }

    /**
     */
    async workoutControllerGet(id: string): Promise<GetWorkoutDto> {
        const response = await this.workoutControllerGetRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async workoutControllerGetAllRaw(requestParameters: WorkoutControllerGetAllRequest): Promise<runtime.ApiResponse<Array<GetWorkoutDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetWorkoutDtoFromJSON));
    }

    /**
     */
    async workoutControllerGetAll(limit?: number): Promise<Array<GetWorkoutDto>> {
        const response = await this.workoutControllerGetAllRaw({ limit: limit });
        return await response.value();
    }

    /**
     */
    async workoutControllerGetByUserUidRaw(requestParameters: WorkoutControllerGetByUserUidRequest): Promise<runtime.ApiResponse<Array<GetWorkoutDto>>> {
        if (requestParameters.userUid === null || requestParameters.userUid === undefined) {
            throw new runtime.RequiredError('userUid','Required parameter requestParameters.userUid was null or undefined when calling workoutControllerGetByUserUid.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userUid !== undefined) {
            queryParameters['userUid'] = requestParameters.userUid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workout/get/byUid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetWorkoutDtoFromJSON));
    }

    /**
     */
    async workoutControllerGetByUserUid(userUid: string): Promise<Array<GetWorkoutDto>> {
        const response = await this.workoutControllerGetByUserUidRaw({ userUid: userUid });
        return await response.value();
    }

    /**
     */
    async workoutControllerSearchRaw(requestParameters: WorkoutControllerSearchRequest): Promise<runtime.ApiResponse<Array<GetWorkoutDto>>> {
        if (requestParameters.text === null || requestParameters.text === undefined) {
            throw new runtime.RequiredError('text','Required parameter requestParameters.text was null or undefined when calling workoutControllerSearch.');
        }

        if (requestParameters.disciplines === null || requestParameters.disciplines === undefined) {
            throw new runtime.RequiredError('disciplines','Required parameter requestParameters.disciplines was null or undefined when calling workoutControllerSearch.');
        }

        if (requestParameters.areas === null || requestParameters.areas === undefined) {
            throw new runtime.RequiredError('areas','Required parameter requestParameters.areas was null or undefined when calling workoutControllerSearch.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.disciplines) {
            queryParameters['disciplines'] = requestParameters.disciplines;
        }

        if (requestParameters.areas) {
            queryParameters['areas'] = requestParameters.areas;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/workout/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetWorkoutDtoFromJSON));
    }

    /**
     */
    async workoutControllerSearch(text: string, disciplines: Array<string>, areas: Array<string>): Promise<Array<GetWorkoutDto>> {
        const response = await this.workoutControllerSearchRaw({ text: text, disciplines: disciplines, areas: areas });
        return await response.value();
    }

    /**
     */
    async workoutControllerUpdateRaw(requestParameters: WorkoutControllerUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling workoutControllerUpdate.');
        }

        if (requestParameters.createUpdateWorkoutDto === null || requestParameters.createUpdateWorkoutDto === undefined) {
            throw new runtime.RequiredError('createUpdateWorkoutDto','Required parameter requestParameters.createUpdateWorkoutDto was null or undefined when calling workoutControllerUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/workout/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUpdateWorkoutDtoToJSON(requestParameters.createUpdateWorkoutDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async workoutControllerUpdate(id: string, createUpdateWorkoutDto: CreateUpdateWorkoutDto): Promise<void> {
        await this.workoutControllerUpdateRaw({ id: id, createUpdateWorkoutDto: createUpdateWorkoutDto });
    }

}
