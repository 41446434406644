import React from 'react';
import { Image } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export enum MediaType {
  Video = 1,
  Image,
}

export type CardMediaProps = {
  title: string;
  mediaType: MediaType;
  src?: string | undefined | null;
};

export const CardMedia: React.FC<CardMediaProps> = ({
  title,
  mediaType,
  src,
}) => {
  return (
    <>
      {mediaType === MediaType.Video ? (
        src ? (
          <div className="embed-responsive embed-responsive-16by9">
            <iframe
              className="embed-responsive-item"
              src={src}
              title={'Wideo ' + title}
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
            ></iframe>
          </div>
        ) : (
          <div className="my-media-video-default">
            <Icon.CameraVideoOff size={150} />
          </div>
        )
      ) : (
        <div className="my-media-img">
          {src ? (
            <Image src={src} />
          ) : (
            <div className="my-media-default">
              <Icon.BookHalf size={150} />
              <Icon.Activity size={80} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
