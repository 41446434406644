import { DefaultApi } from '../autogenerated/slimbeApiClient/apis/DefaultApi';
import { MediaType } from '../../../slimbe/components/PresentationCard/CardMedia';
import { GetPhotoDto } from '../autogenerated/slimbeApiClient/models/GetPhotoDto';

import { CollectionCard } from './CollectionCard';

export type Photo = GetPhotoDto;

export interface PhotosContextInterface {
  getByUserUIDAsCardCollection: (
    userUID?: string | null,
  ) => Promise<CollectionCard[] | undefined>;
  getByUserUID: (userUID?: string) => Promise<Photo[] | undefined>;
  add: (photo: Photo) => Promise<GetPhotoDto>;
  update: (photo: Photo) => Promise<void>;
  getById: (id?: string) => Promise<Photo | undefined>;
  delete: (id: string) => Promise<void>;
}

export const PhotosContext = (api: DefaultApi) => {
  const getByUserUID = async (userUID?: string | null | undefined) => {
    if (!userUID) {
      return;
    }

    return await api.photoControllerGetByUserUID(userUID);
  };

  const getAsCardCollection = (photos: Photo[]) => {
    return photos.map(a => {
      return {
        id: a?.id || '',
        userUID: a?.user?.uid || '',
        title: a?.topic || '',
        mediaType: MediaType.Image,
        src: a?.image,
        avatar: a?.user?.image || '',
        author: a?.user?.name || '',
        description: a?.shortDescription || '',
        time: a?.time,
        disciplines: [],
        areas: [],
      };
    });
  };

  return {
    getByUserUIDAsCardCollection: async (userUID?: string | null) => {
      const collection = await getByUserUID(userUID);
      return Promise.all(getAsCardCollection(collection || []));
    },

    getByUserUID: async (userUID?: string | undefined) => getByUserUID(userUID),

    add: async (photo: Photo) => {
      return await api.photoControllerCreate({
        ...photo,
      });
    },

    update: async (photo: Photo) => {
      if (!photo.id) {
        return;
      }

      return api.photoControllerUpdate(photo.id, {
        ...photo,
      });
    },

    getById: async (id?: string | undefined) => {
      if (!id || id === undefined) {
        return;
      }
      return await api.photoControllerGet(id);
    },

    delete: async (id: string) => {
      return api.photoControllerDelete(id);
    },
  };
};
