/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Row, Container, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { Profile } from '../../../common/slimbeCore/contexts/ProfilesContext';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { DescriptionNode } from '../FormControl/DescriptionNode';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { ProfileNameNode } from '../FormControl/ProfileNameNode';
import { ActionFormButtons } from '../FormControl/ActionFormButtons';
import { useNavigation } from '../../../common/navigation';
import { useAuth } from '../../../common/hooks/useAuth';
import { Like } from '../Like/Like';
import { ProfilePasswordNode } from '../FormControl/ProfilePasswordNode';
import { TabTitle } from '../../../app/components/TabTitle';
import { CollectionWall } from '../Collections/CollectionWall';
import { ProfileInputHyperLinkNode } from '../FormControl/ProfileInputNode';
import { ShortDescriptionNode } from '../FormControl/ShortDescriptionNode';
import { DisciplinesSelector } from '../Tags/DisciplinesSelector';

import { ProfilePhoto } from './ProfilePhoto';

export enum ProfileTab {
  Profile = 'profile',
  Workouts = 'workouts',
  Articles = 'articles',
  Photos = 'photos',
  Contact = 'contact',
  Settings = 'settings',
}

export type ProfileProps = {
  editMode: boolean;
  profile: Profile;
  activeTab?: string;
};

export const ProfileInfo: React.FC<ProfileProps> = ({
  editMode,
  profile,
  activeTab = ProfileTab.Profile,
}) => {
  const nav = useNavigation();
  const auth = useAuth();
  const slimbeApi = useSlimbeCore();
  const [upProfile, setUpProfile] = useState<Profile>(profile);
  const [activeKey, setActiveKey] = useState<string>(activeTab);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [owner, setOwner] = useState(false);
  const [workoutsCount, setWorkoutsCount] = useState<number | undefined>();
  const [articlesCount, setArticlesCount] = useState<number | undefined>();
  const [photosCount, setPhotosCount] = useState<number | undefined>();

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    const userUID = '' + auth?.currentUser?.uid;
    const isAdmin = auth?.userProfile?.isAdmin || false;
    const isOwner = userUID === profile.uid;
    /// tryb edycji tylko w przypadku gdy: w trybie edycji wchodzę na swój profil
    /// viewMode tylko w przypadku gdy: wchodzę bez edycji na swój profile lub wchodzę jako administrator na cudzy profil
    setEditable(isOwner && editMode);
    setViewMode((!editMode && isOwner) || (isAdmin && !isOwner));
    setOwner(isOwner);
  }, [auth?.currentUser, auth?.userProfile, profile.uid, editMode]);

  useEffect(() => {
    setUpProfile(profile);
  }, [profile]);

  const handleSave = async (obj?: Partial<Profile>) => {
    try {
      if (obj) {
        const newProfile = { ...upProfile, ...obj };
        setUpProfile(newProfile);
        await slimbeApi.profiles.updateSlimbeProfile(newProfile);
        owner && auth.updateProfile(newProfile);
      }
      return true;
    } catch (err) {
      setErrorText(`Zapis profilu jest niemożliwy. ${(err as Error).message}`);
      setShowError(true);
      return false;
    }
  };
  const handleSaveCurrentUserEmail = async (email: string) => {
    try {
      owner && email && (await slimbeApi.signIn.updateEmail(email));
      owner && auth.updateProfile(upProfile);
      owner && auth.updateEmailCurrentUser();
      return true;
    } catch (err) {
      setErrorText(
        `Zmiana adresu email do autoryzacji jest niemożliwa. ${
          (err as Error).message
        }`,
      );
      setShowError(true);
      return false;
    }
  };

  const changeViewMode = (active: boolean) => {
    setViewMode(active);
    setEditable(!active);
    setActiveKey(ProfileTab.Profile);
  };

  const handleDelete = async () => {
    profile.id && (await slimbeApi.profiles.delete(profile.id));
    if (profile.uid === auth.currentUser?.uid) {
      void auth.logout();
    }
    nav.coaches();
  };

  const paramMode: ParamModeProps = {
    newRecord: false,
    viewMode: viewMode,
    editable: editable,
  };

  return (
    <Container className="p-3 my-profile-view">
      <ActionFormButtons
        objectName={'profil użytkownika'}
        mode={paramMode}
        onDelete={handleDelete}
        setViewMode={changeViewMode}
      />
      <RowProfileHeader
        profile={profile}
        mode={paramMode}
        onSave={handleSave}
      />
      <Row className="gy-3">
        <Col className="col-md-12">
          <Tabs
            activeKey={activeKey}
            onSelect={key => setActiveKey(key ? key : ProfileTab.Profile)}
            className="mb-2 mt-3 d-flex justify-content-end"
          >
            <Tab eventKey={ProfileTab.Profile} title="Opis">
              <DescriptionNode
                value={profile?.description || ''}
                mode={paramMode}
                onSave={desc => handleSave({ description: desc })}
              />
            </Tab>
            <Tab
              eventKey={ProfileTab.Workouts}
              title={
                <TabTitle
                  name="Treningi"
                  length={workoutsCount}
                  showZero={false}
                />
              }
            >
              <CollectionWall
                smallCard={false}
                editable={editable && owner}
                objectName="trening"
                whatCollection="treningów"
                getCardCollecion={() =>
                  slimbeApi.workouts.getByUserUIDAsCardCollection(profile?.uid)
                }
                navCardView={nav.workoutFormView}
                setCountCollection={setWorkoutsCount}
              />
            </Tab>
            <Tab
              eventKey={ProfileTab.Articles}
              title={
                <TabTitle
                  name="Artykuły"
                  length={articlesCount}
                  showZero={false}
                />
              }
            >
              <CollectionWall
                smallCard={false}
                editable={editable && owner}
                objectName="artykuł"
                whatCollection="artykułów"
                getCardCollecion={() =>
                  slimbeApi.articles.getByUserUIDAsCardCollection(profile?.uid)
                }
                navCardView={nav.articleFormView}
                setCountCollection={setArticlesCount}
              />
            </Tab>
            <Tab
              eventKey={ProfileTab.Photos}
              title={
                <TabTitle
                  name="Zdjęcia"
                  length={photosCount}
                  showZero={false}
                />
              }
            >
              <CollectionWall
                smallCard={true}
                editable={editable && owner}
                objectName="zdjęcie"
                whatCollection="zdjęć"
                getCardCollecion={() =>
                  slimbeApi.photos.getByUserUIDAsCardCollection(profile?.uid)
                }
                navCardView={nav.photoFormView}
                setCountCollection={setPhotosCount}
                showImageOnSelect={true}
              />
            </Tab>
            <Tab eventKey={ProfileTab.Contact} title="Kontakt">
              <FormProfileContact
                profile={profile}
                mode={paramMode}
                onSave={handleSave}
              />
            </Tab>

            {editable && owner && (
              <Tab eventKey={ProfileTab.Settings} title="Ustawienia">
                <FormProfileSetting
                  mode={paramMode}
                  onSave={handleSaveCurrentUserEmail}
                />
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
      <ValidationAlert
        heading={'Zapis profilu'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </Container>
  );
};

type RowFormProps = {
  profile: Profile;
  mode: ParamModeProps;
  onSave: (obj?: Partial<Profile>) => Promise<boolean>;
};

const RowProfileHeader: React.FC<RowFormProps> = ({
  profile,
  mode,
  onSave,
}) => {
  return (
    <>
      <Row className="gy-3">
        <Col className="col-md-3 d-flex justify-content-center">
          <ProfilePhoto
            profile={profile}
            //imageChange={propertyChange}
            onSave={onSave}
            editable={mode.editable}
          />
        </Col>
        <Col className="col-md-9 d-flex justify-content-center align-self-center">
          <Row className="gy-3 w-100">
            <Like object={profile} />
            <Col className="col-12 col-md-12 p-0">
              <ProfileNameNode
                value={profile?.name || ''}
                mode={mode}
                onSave={txt => onSave({ name: txt })}
              />
            </Col>
            <Col className="col-12 col-md-12 p-0">
              <ShortDescriptionNode
                value={profile?.shortDescription || ''}
                mode={mode}
                onSave={txt => onSave({ shortDescription: txt })}
              />
            </Col>
            <Col className="col-12 col-sm-12 col-md-12 p-0 align-self-center">
              <DisciplinesSelector
                stringArray={profile?.disciplineArray || []}
                mode={mode}
                onSave={disc => onSave({ disciplineArray: disc })}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
type FormProfileSettingProps = {
  mode: ParamModeProps;
  onSave: (email: string) => Promise<boolean>;
};

const FormProfileSetting: React.FC<FormProfileSettingProps> = ({
  mode,
  onSave,
}) => {
  const auth = useAuth();
  return (
    <div>
      <Row className="gy-3 mt-3 mx-0">
        <ProfileInputHyperLinkNode
          icon={Icon.EnvelopeCheck}
          label="Adres e-mail (do autoryzacji)"
          placeholder="Adres e-mail"
          type="email"
          mode={mode}
          value={auth.currentUser?.email || ''}
          onSave={onSave}
        />
        <ProfilePasswordNode />
      </Row>
    </div>
  );
};

const FormProfileContact: React.FC<RowFormProps> = ({
  profile,
  mode,
  onSave,
}) => {
  return (
    <div>
      <Row className="gy-3 mt-3 mx-0">
        <ProfileInputHyperLinkNode
          icon={Icon.Envelope}
          label="Adres e-mail"
          placeholder="Adres e-mail"
          type="email"
          mode={mode}
          value={profile?.email || ''}
          onSave={txt => onSave({ email: txt })}
        />
        <ProfileInputHyperLinkNode
          icon={Icon.Telephone}
          label="Telefon"
          placeholder="Wpisz telefon kontaktowy"
          type="phone"
          mode={mode}
          value={profile?.phone || ''}
          onSave={txt => onSave({ phone: txt })}
        />
        <ProfileInputHyperLinkNode
          icon={Icon.Globe}
          label="Adres WWW"
          placeholder="Wpisz adres swojej strony"
          type="www"
          mode={mode}
          value={profile?.www || ''}
          onSave={txt => onSave({ www: txt })}
        />
        <ProfileInputHyperLinkNode
          icon={Icon.Facebook}
          label="FB"
          placeholder="Wpisz adres swojej strony FB"
          type="www"
          mode={mode}
          value={profile?.fb || ''}
          onSave={txt => onSave({ fb: txt })}
        />
        <ProfileInputHyperLinkNode
          icon={Icon.Instagram}
          label="Instagram"
          placeholder="Wpisz adres swojego Instagrama"
          type="www"
          mode={mode}
          value={profile?.instagram || ''}
          onSave={txt => onSave({ instagram: txt })}
        />
      </Row>
    </div>
  );
};
