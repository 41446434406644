/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSloganDto
 */
export interface CreateUpdateSloganDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSloganDto
     */
    type: CreateUpdateSloganDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSloganDto
     */
    text?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSloganDto
     */
    time?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSloganDto
     */
    userUID?: string | null;
}

export function CreateUpdateSloganDtoFromJSON(json: any): CreateUpdateSloganDto {
    return CreateUpdateSloganDtoFromJSONTyped(json, false);
}

export function CreateUpdateSloganDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSloganDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'userUID': !exists(json, 'userUID') ? undefined : json['userUID'],
    };
}

export function CreateUpdateSloganDtoToJSON(value?: CreateUpdateSloganDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'text': value.text,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'userUID': value.userUID,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateUpdateSloganDtoTypeEnum {
    Main = 'main',
    ArticleMain = 'articleMain',
    WorkoutMain = 'workoutMain',
    ProfileMain = 'profileMain',
    ArticleTitle = 'articleTitle',
    WorkoutTitle = 'workoutTitle',
    ProfileTitle = 'profileTitle'
}


