import React from 'react';
import { Badge } from 'react-bootstrap';

type TabTitleProps = {
  name: string;
  length?: number;
  showZero: boolean;
};

export const TabTitle: React.FC<TabTitleProps> = ({
  name,
  length,
  showZero,
}) => {
  const count = length ? length : 0;
  return (
    <span>
      {name}{' '}
      {showZero ? <Badge>{count}</Badge> : count > 0 && <Badge>{count}</Badge>}
    </span>
  );
};
