import React from 'react';

interface MainProps {
  children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }: MainProps) => {
  return <div className="page-content">{children}</div>;
};

export default Main;
