import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import { CollectionCard } from '../../../common/slimbeCore/contexts/CollectionCard';
import { EditableCollection } from '../EditableCollection/EditableCollection';

import { RowCardCollection } from './RowCardCollection';
import { RowSmallCardCollection } from './RowSmallCardCollection';

export interface CollectionWallProps {
  smallCard: boolean;
  editable?: boolean;
  objectName: string;
  whatCollection: string;
  getCardCollecion: (
    count?: number | undefined,
  ) => Promise<CollectionCard[] | undefined>;
  navCardView: (id: string | undefined, editMode?: boolean) => void;
  setCountCollection?: (count: number | undefined) => void;
  showImageOnSelect?: boolean;
}

export const CollectionWall: React.FC<CollectionWallProps> = ({
  smallCard,
  editable = false,
  objectName,
  whatCollection,
  getCardCollecion,
  navCardView,
  setCountCollection,
  showImageOnSelect,
}) => {
  const [collection, setCollection] = useState<CollectionCard[] | undefined>(
    [],
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let mounted = true;

    getCardCollecion(undefined)
      ?.then(coll => {
        if (mounted) {
          setCountCollection && setCountCollection(coll?.length);
          setCollection(coll);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem kolekcji kart. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [getCardCollecion, setCountCollection]);

  return (
    <div className="p-3 m-2">
      {ready ? (
        <EditableCollection
          onAdd={() => navCardView(undefined)}
          name={objectName}
          editable={editable}
        >
          {collection && collection?.length > 0 ? (
            !smallCard ? (
              <RowCardCollection
                collection={collection}
                actionOnEdit={id => () => navCardView(id, true)}
                onSelect={id => () => navCardView(id)}
                showImageOnSelect={showImageOnSelect}
              />
            ) : (
              <RowSmallCardCollection
                collection={collection}
                actionOnEdit={id => () => navCardView(id, true)}
                onSelect={id => () => navCardView(id)}
                showImageOnSelect={showImageOnSelect}
              />
            )
          ) : (
            <h3 className="text-center pt-1">Brak {whatCollection} :-(</h3>
          )}
        </EditableCollection>
      ) : (
        <h3 className="text-center pt-3">
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">
              Trwa ładowanie {whatCollection}...
            </span>
          </Spinner>{' '}
          Trwa ładowanie {whatCollection}...
        </h3>
      )}
    </div>
  );
};
