import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';

import { useAuth } from '../../../common/hooks/useAuth';
import { useNavigation } from '../../../common/navigation';
import { ViewNodeWhitEditAction } from '../EditableCollection/EditableNode';

import { ParamModeProps } from './EditStringNodeProps';

export interface AuthorNodeProps {
  mode: ParamModeProps;
  author: string;
  avatar: string;
  userId: string;
}

export const AuthorNode: React.FC<AuthorNodeProps> = ({
  mode,
  author,
  avatar,
  userId,
}) => {
  const nav = useNavigation();
  const auth = useAuth();
  const [authorLoc, setAuthorLoc] = useState<string>(author);
  const [userIdLoc, setUserIdLoc] = useState<string>(userId);
  const [avatarLoc, setAvatarLoc] = useState<string>(avatar);

  useEffect(() => {
    if (mode.newRecord) {
      setAuthorLoc(auth?.userProfile?.name || '');
      setUserIdLoc(auth?.currentUser?.id || '');
      setAvatarLoc(auth?.userProfile?.image || '');
    }
  }, [
    auth?.currentUser?.id,
    auth?.userProfile?.image,
    auth?.userProfile?.name,
    mode.newRecord,
  ]);

  return (
    <ViewNodeWhitEditAction
      active={mode.editable && !mode.newRecord}
      onEdit={() => nav.coachView(userIdLoc)}
      narrow={true}
    >
      {!mode.newRecord ? (
        <Button variant="author" onClick={() => nav.coachView(userIdLoc)}>
          {avatarLoc && (
            <Image
              src={avatarLoc}
              alt="Avatar autora"
              className="img-avatar small-avatar me-2"
            />
          )}
          <span>{authorLoc}</span>
        </Button>
      ) : (
        <Button variant="author">
          {avatarLoc && (
            <Image
              src={avatarLoc}
              alt="Avatar autora"
              className="img-avatar small-avatar me-2"
            />
          )}
          <span>{authorLoc}</span>
        </Button>
      )}
    </ViewNodeWhitEditAction>
  );
};
