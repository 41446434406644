import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/named
import { MultiValue } from 'react-select';

import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { TagEntry } from '../../../common/slimbeCore/contexts/DisciplinesContext';

import { TagsSelect } from './TagsSelect';

interface AreasSelectProps {
  areaArray: string[];
  propertyChange: (areaArray: string[]) => void;
  mainAreasChange?: (mainAreaArray: string[]) => void;
  focus?: boolean;
}

export const AreasSelect: React.FC<AreasSelectProps> = props => {
  const slimbeApi = useSlimbeCore();

  const [tagsAreaAll, setTagsAreaAll] = useState<TagEntry[]>([]);
  const [tagsArea, setTagsArea] = useState<TagEntry[]>([]);
  const [tagsAreaDetails, setTagsAreaDetails] = useState<TagEntry[]>([]);

  const [selectedArea, setSelectedArea] = useState<TagEntry[]>([]);
  const [selectedAreaDetails, setSelectedAreaDetails] = useState<TagEntry[]>(
    [],
  );

  useEffect(() => {
    let mounted = true;

    slimbeApi.areasEntry
      .then(t => {
        if (mounted) {
          setTagsAreaAll(t || []);
          setTagsArea(
            (t as TagEntry[]).filter(o => !(o as TagEntry).idParent) || [],
          );
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem obszarów. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [slimbeApi]);

  useEffect(() => {
    const selection = [];
    const selectionDetails = [];

    for (const v of props.areaArray) {
      const pos = tagsAreaAll.find(o => o.value === v);

      if (pos && !pos?.idParent) {
        selection.push(pos);
      }

      if (pos?.idParent && props.areaArray.includes(pos?.idParent)) {
        selectionDetails.push(pos);
      }
    }

    const m = selection.map(o => o.value);
    setSelectedArea(selection);
    setSelectedAreaDetails(selectionDetails);
    setTagsAreaDetails(
      tagsAreaAll.filter(o => o.idParent && m.includes(o.idParent)),
    );
  }, [props.areaArray, tagsAreaAll]);

  const handleOnChange = (options: MultiValue<TagEntry>) => {
    const newArea = [
      ...(options.map(o => (o as TagEntry).value) || []),
      ...(selectedAreaDetails.map(o => o.value) || []),
    ];

    const selectionDetails = [];
    for (const v of newArea) {
      const pos = tagsAreaAll.filter(o => o.value === v)[0];

      if (pos.idParent !== null && pos.idParent !== undefined) {
        if (newArea.includes(pos.idParent)) {
          selectionDetails.push(pos);
        }
      }
    }

    setSelectedAreaDetails(selectionDetails);
    const mainAreas = options.map(o => (o as TagEntry).value) || [];

    props.mainAreasChange && props.mainAreasChange(mainAreas);

    props.propertyChange([
      ...mainAreas,
      ...(selectionDetails.map(o => o.value) || []),
    ]);
  };

  return (
    <>
      <TagsSelect
        tags={tagsArea}
        selected={selectedArea}
        onChange={handleOnChange}
        showTags={false}
        focus={props.focus}
        withCheckbox={true}
      />
      <div className="pt-3">
        <TagsSelect
          label={'Obszar szczegółowy'}
          tags={tagsAreaDetails}
          selected={selectedAreaDetails}
          onChange={options =>
            props.propertyChange([
              ...(selectedArea.map(o => o.value) || []),
              ...(options.map(o => (o as TagEntry).value) || []),
            ])
          }
          showTags={false}
          withCheckbox={true}
          focus={false}
        />
      </div>
    </>
  );
};
