import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const ActiveContrast = 'active-contrast';
export const LikeGuid = 'likeGuid';

export const useCookies = () => {
  const get = (name: string) => {
    return Cookies.get(name);
  };

  const set = (name: string, value: string) => {
    return Cookies.set(name, value, {
      sameSite: 'None',
      secure: true,
      path: '/',
    });
  };

  return {
    activeContrast: get(ActiveContrast),
    setActiveContrast: (value: string) => set(ActiveContrast, value),

    likeGuid: () => {
      let likeGuid = get(LikeGuid);
      if (!likeGuid) {
        likeGuid = uuidv4();
        if (likeGuid) {
          set(LikeGuid, likeGuid);
        }
      }
      return likeGuid;
    },
    setLike: (value: string) => set(LikeGuid, value),
  };
};
