import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { SearchBox } from '../components/Search/SearchBox';
import { SearchResults } from '../components/Search/SearchResults';

export const SearchView: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [disciplineArray, setdisciplineArray] = useState<string[]>([]);
  const [areaArray, setAreaArray] = useState<string[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);

  const handleSearch = async (
    searchTextParam: string,
    disciplineArrayParam: string[],
    areaArrayParam: string[],
  ) => {
    setShowResults(true);
    setSearchText(searchTextParam);
    setdisciplineArray(disciplineArrayParam);
    setAreaArray(areaArrayParam);
  };

  return (
    <Container className="main-content-container px-4">
      <SearchBox onSearch={handleSearch} />
      <Row className="gy-3 justify-content-center search-tab mt-3">
        <Col className="col-12">
          <SearchResults
            showResults={showResults}
            searchText={searchText}
            disciplineArray={disciplineArray}
            areaArray={areaArray}
          />
        </Col>
      </Row>
    </Container>
  );
};
