import React from 'react';
import { Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import momencik from '../../../common/momencik';
import { CollectionCard } from '../../../common/slimbeCore/contexts/CollectionCard';
import { PresentationCard } from '../PresentationCard/PresentationCard';
import { SmallPresentationCard } from '../PresentationCard/SmallPresentationCard';

export interface CarouselCollectionProps {
  smallCard: boolean;
  collection: CollectionCard[];
  onClickMore: () => void;
  actionOnEdit: (id: string) => () => void;
  onSelect: (id: string) => () => void;
}

export const CarouselCollection: React.FC<CarouselCollectionProps> = ({
  smallCard,
  collection,
  onClickMore,
  actionOnEdit,
  onSelect,
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    largeDesktop: {
      breakpoint: { max: 1400, min: 1200 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    desktopLg: {
      breakpoint: { max: 1200, min: 992 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 768, min: 576 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };
  return (
    <Carousel
      responsive={responsive}
      additionalTransfrom={0}
      autoPlaySpeed={10000}
      autoPlay={false}
      centerMode={false}
      arrows={true}
      className=""
      draggable={true}
      focusOnSelect={false}
      itemClass="m-2 item-carousel"
      infinite={false}
      keyBoardControl={true}
      minimumTouchDrag={80}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
    >
      {Object.keys(collection).map((key, idx) =>
        !smallCard ? (
          <PresentationCard
            key={idx}
            userUID={collection[idx].userUID}
            actionOnEdit={actionOnEdit(collection[idx].id)}
            title={collection[idx].title}
            mediaType={collection[idx].mediaType}
            src={collection[idx].src}
            author={collection[idx].author}
            avatar={collection[idx].avatar}
            description={collection[idx].description}
            time={momencik(collection[idx].time)}
            onSelect={onSelect(collection[idx].id)}
            areas={collection[idx].areas}
            disciplines={collection[idx].disciplines}
            duration={collection[idx].duration}
            clickActive={false}
          />
        ) : (
          <SmallPresentationCard
            key={idx}
            title={collection[idx].title}
            src={collection[idx].src}
            disciplines={collection[idx].disciplines}
            description={collection[idx].description}
            onSelect={onSelect(collection[idx].id)}
            clickActive={false}
          />
        ),
      )}
      <Button variant="my-outline-opposed-btn more-btn" onClick={onClickMore}>
        Zobacz więcej...
      </Button>
    </Carousel>
  );
};
