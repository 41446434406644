import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from 'react';

import { AuthState } from '../../common/auth';
import { useAuth } from '../../common/hooks/useAuth';
import { useNavigation } from '../../common/navigation';

interface SessionTimeoutProps {
  timeoutInMinutes: number;
}

const SessionTimeout = (props: SessionTimeoutProps) => {
  const sessionTimeoutInMinutes = props.timeoutInMinutes;
  const auth = useAuth();
  const nav = useNavigation();

  const [events] = useState(['click', 'load', 'scroll', 'keydown']);
  const startTimerInterval = useRef<NodeJS.Timeout>();

  const resetTimer = useCallback(() => {
    if (auth.state === AuthState.LoggedIn) {
      const timeStamp = new Date();
      localStorage.setItem('lastTimeStamp', timeStamp.toString());
    } else {
      localStorage.removeItem('lastTimeStamp');
    }
  }, [auth.state]);

  const checkInactive = useCallback(
    (timeString: string) => {
      const diff = new Date().getTime() - new Date(timeString).getTime();
      const diffMin = diff / 1000 / 60;

      if (diffMin >= sessionTimeoutInMinutes) {
        localStorage.removeItem('lastTimeStamp');
        if (startTimerInterval.current) {
          clearInterval(startTimerInterval.current);
          startTimerInterval.current = undefined;
        }
        void auth.logout();
        nav.startPage();
      }
    },
    [auth, nav, sessionTimeoutInMinutes],
  );

  const timeChecker = useCallback(() => {
    if (auth.state === AuthState.LoggedIn) {
      if (!startTimerInterval.current) {
        startTimerInterval.current = setInterval(() => {
          const storedTimeStamp = localStorage.getItem('lastTimeStamp');
          checkInactive(storedTimeStamp || '');
        }, 10000);
      }
    } else {
      if (startTimerInterval.current) {
        clearInterval(startTimerInterval.current);
        startTimerInterval.current = undefined;
      }
    }
  }, [auth.state, checkInactive]);

  useEffect(() => {
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [events, resetTimer]);

  useEffect(() => {
    resetTimer();
    timeChecker();
  }, [resetTimer, timeChecker, auth.state]);
  return <Fragment />;
};

export default SessionTimeout;
