import { DefaultApi } from '../autogenerated/slimbeApiClient/apis/DefaultApi';
import { CreateUpdateSloganDtoFromJSON } from '../autogenerated/slimbeApiClient/models/CreateUpdateSloganDto';
import { GetSloganDto } from '../autogenerated/slimbeApiClient/models/GetSloganDto';
import { SloganType } from '../validation/schemas';

export type Slogan = GetSloganDto;

export interface SlogansContextInterface {
  getAll: () => Promise<Slogan[]>;
  getOneByType: (type: SloganType) => Promise<Slogan>;
  update: (slogan: Slogan) => Promise<void>;
}

export const SlogansContext = (api: DefaultApi) => {
  return {
    getAll: async () => {
      return api.sloganControllerGetAll();
    },

    getOneByType: async (type: SloganType) => {
      return await api.sloganControllerGetOneByType(type);
    },

    update: async (slogan: Slogan) => {
      if (!slogan.id) {
        return;
      }

      return api.sloganControllerUpdate(
        slogan.id,
        CreateUpdateSloganDtoFromJSON(slogan),
      );
    },
  };
};
