import React, { useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';

export type Props = {
  onResetPassword: (email: string) => void;
};

export const ResetPassword: React.FC<Props> = ({ onResetPassword }) => {
  const [email, setEmail] = useState('');
  const [emailRequiredError, setEmailRequiredError] = useState('');

  const handleResetPassword = async () => {
    setEmailRequiredError('');
    if (!email) {
      setEmailRequiredError('Podaj adres e-mail.');
      return;
    }

    await onResetPassword(email);
  };

  return (
    <main className="form-signin">
      <Form>
        <Image className="img-logo-slimbe mb-3 mt-5" alt="Logo Slimbe" />
        <h3 className="fw-normal">
          Jesteś w gronie najlepszych <strong>trenerów personalnych</strong>.
        </h3>
        <Form.Group className="form-floating mt-3" controlId="email">
          <Form.Control
            type="email"
            placeholder="Adres e-mail"
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Label>Adres e-mail</Form.Label>
        </Form.Group>
        <span className="input-form-error">{emailRequiredError}</span>
      </Form>
      <Button
        type="submit"
        className="w-100 btn btn-lg btn-login mt-3"
        onClick={handleResetPassword}
      >
        Resetuj hasło
      </Button>
    </main>
  );
};
