import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Image, Card } from 'react-bootstrap';

import { ViewNodeWhitEditAction } from '../EditableCollection/EditableNode';
import { DisciplineBadges } from '../Tags/TagsBadges';
import { ImageWithMagnifier } from '../Images/ImageWithMagnifier';

export interface SmallPresentationCardProps {
  userUID?: string | undefined | null;
  actionOnEdit?: () => void;
  title: string;
  description: string;
  disciplines: string[];
  src: string | undefined | null;
  onSelect: () => void;
  clickActive: boolean;
  showImageOnSelect?: boolean;
}

export const SmallPresentationCard: React.FC<SmallPresentationCardProps> = ({
  userUID,
  actionOnEdit,
  title,
  disciplines,
  description,
  src,
  onSelect,
  clickActive,
  showImageOnSelect,
}) => {
  const maxDisciplineTags = 5;

  const [editable, setEditable] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);

  useEffect(() => {
    if (userUID) {
      const auth = getAuth();
      setEditable(auth?.currentUser?.uid === userUID);
    }
  }, [userUID]);

  const actionOnSelect = () => {
    showImageOnSelect ? setModalShow(!modalShow) : onSelect();
  };

  const onKeypress = (e: { nativeEvent: { key: string } }) => {
    if (e.nativeEvent.key === 'Enter') {
      actionOnSelect();
    }
  };

  return (
    <ViewNodeWhitEditAction active={editable} onEdit={actionOnEdit}>
      <Card
        className="my-small-card mb-4"
        onDoubleClick={!clickActive ? onSelect : undefined}
        onClick={clickActive ? actionOnSelect : undefined}
        tabIndex={0}
        onKeyPress={onKeypress}
      >
        {' '}
        {src &&
          (showImageOnSelect ? (
            <ImageWithMagnifier
              src={src}
              alt={title}
              description={description}
              show={modalShow}
              setShow={setModalShow}
            />
          ) : (
            <Image src={src} alt={title} />
          ))}
        <Card.Body>
          <Card.Title
            className="my-title block-with-text"
            onKeyPress={onKeypress}
            onClick={onSelect}
          >
            {title}
          </Card.Title>{' '}
          {disciplines && (
            <div className="mb-3">
              <DisciplineBadges
                stringArray={disciplines}
                limit={maxDisciplineTags}
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </ViewNodeWhitEditAction>
  );
};
