import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { Workout } from '../../common/slimbeCore/contexts/WorkoutsContext';
import { WorkoutForm } from '../components/Workouts/WorkoutForm';

interface ParamTypes {
  id: string;
  editMode: string;
}
export const WorkoutFormView: React.FC = () => {
  const slimbeApi = useSlimbeCore();
  const { id, editMode } = useParams<ParamTypes>();
  const [workout, setWorkout] = useState<Workout>();

  useEffect(() => {
    let mounted = true;

    id &&
      slimbeApi.workouts.getById(id).then(wrk => {
        if (mounted) {
          setWorkout(wrk);
        }
      });

    return () => {
      mounted = false;
    };
  }, [id, slimbeApi.workouts]);

  return (
    <>
      {workout && (
        <WorkoutForm workout={workout} editMode={editMode === 'true'} />
      )}
      {!id && <WorkoutForm editMode={false} />}
    </>
  );
};
