import React from 'react';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

export type EditableCollectionProps = {
  onAdd: () => void;
  name: string;
  editable?: boolean;
  children: JSX.Element;
};

export const EditableCollection: React.FC<EditableCollectionProps> = ({
  onAdd,
  name,
  editable = false,
  children,
}) => {
  const onKeypress = (e: { nativeEvent: { key: string } }) => {
    if (e.nativeEvent.key === 'Enter') {
      onAdd();
    }
  };
  return (
    <div className="function-field">
      {editable && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Dodaj {name}</Tooltip>}
        >
          <Icon.PlusLg
            className="function-icon icon icon-big"
            onClick={onAdd}
            onKeyPress={onKeypress}
            tabIndex={0}
          />
        </OverlayTrigger>
      )}{' '}
      <Container fluid className="editable-collection pt-5 ps-0 pe-0">
        {children}
      </Container>
    </div>
  );
};
