/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetProfileDto,
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSloganDto
 */
export interface GetSloganDto {
    /**
     * 
     * @type {string}
     * @memberof GetSloganDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSloganDto
     */
    type: GetSloganDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSloganDto
     */
    text?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetSloganDto
     */
    time?: Date | null;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetSloganDto
     */
    user?: GetProfileDto | null;
}

export function GetSloganDtoFromJSON(json: any): GetSloganDto {
    return GetSloganDtoFromJSONTyped(json, false);
}

export function GetSloganDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSloganDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'user': !exists(json, 'user') ? undefined : GetProfileDtoFromJSON(json['user']),
    };
}

export function GetSloganDtoToJSON(value?: GetSloganDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'text': value.text,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'user': GetProfileDtoToJSON(value.user),
    };
}

/**
* @export
* @enum {string}
*/
export enum GetSloganDtoTypeEnum {
    Main = 'main',
    ArticleMain = 'articleMain',
    WorkoutMain = 'workoutMain',
    ProfileMain = 'profileMain',
    ArticleTitle = 'articleTitle',
    WorkoutTitle = 'workoutTitle',
    ProfileTitle = 'profileTitle'
}


