import React, { useEffect, useRef, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
// eslint-disable-next-line import/named
import { UserCredential } from 'firebase/auth';

import { Profile } from '../../../common/slimbeCore/contexts/ProfilesContext';
import { useNavigation } from '../../../common/navigation';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useAuth } from '../../../common/hooks/useAuth';

import { SignInWithGoogleFb } from './SignInWithGoogleFb';
import { RegisterProfile } from './RegisterProfile';
import RegisterAgreeDialog from './RegisterAgreeDialog';

export const RegisterProfileDialog: React.FC = () => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();
  const auth = useAuth();
  const mountedRef = useRef(false);

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [userCred, setUserCred] = useState<UserCredential | void>();
  const [needUpdate, setNeedUpdate] = useState<boolean>(false);

  const [showRegisterAgree, setShowRegisterAgree] = useState(false);

  useEffect(() => {
    mountedRef.current = true;

    if (needUpdate && userCred) {
      setNeedUpdate(false);
      slimbeApi.profiles
        .getByUserUID(userCred.user.uid)
        .then(prof => {
          if (!prof && mountedRef.current) {
            setShowRegisterAgree(true);
          } else {
            auth.updateProfile(prof);
            nav.myProfile();
          }
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem profilu. ${error}`),
        );
    }
    return () => {
      mountedRef.current = false;
    };
  }, [needUpdate, auth, nav, slimbeApi.profiles, userCred]);

  const handleRegisterProfile = async (profile: Profile, password: string) => {
    if (profile?.email && profile.name) {
      await slimbeApi.signIn
        .addUserWithEmailAndPassword(profile?.email, password, profile.name)
        .then(uC => {
          if (mountedRef.current) {
            setUserCred(uC);
            setNeedUpdate(true);
          }
        })
        .catch(err => {
          if (mountedRef.current) {
            setErrorText('Błąd podczas rejestracji: ' + (err as Error).message);
            setShowError(true);
          }
        });
    }
  };

  const handleGoogleSignIn = async () => {
    slimbeApi.signIn
      .signInWithGoogle()
      .then(uC => {
        if (mountedRef.current) {
          setUserCred(uC);
          setNeedUpdate(true);
        }
      })
      .catch(err => {
        if (mountedRef.current) {
          setErrorText('Błąd podczas rejestracji: ' + (err as Error).message);
          setShowError(true);
        }
      });
  };

  const handleFacebookSignIn = async () => {
    slimbeApi.signIn
      .signInWithFacebook()
      .then(uC => {
        if (mountedRef.current) {
          setUserCred(uC);
          setNeedUpdate(true);
        }
      })
      .catch(err => {
        if (mountedRef.current) {
          setErrorText('Błąd podczas rejestracji: ' + (err as Error).message);
          setShowError(true);
        }
      });
  };

  return (
    <>
      <RegisterAgreeDialog
        show={showRegisterAgree}
        userCred={userCred}
        onHide={() => {
          setShowRegisterAgree(false);
        }}
      />
      <ValidationAlert
        heading={'REJESTRACJA'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
      <main className="form-signin">
        <Image
          className="img-logo-slimbe mb-3 mt-5"
          alt="Logo Slimbe"
          height={57}
        />
        <h1 className="h1 fw-bold">Utwórz konto</h1>
        <h3>
          Masz już konto?
          <Button
            onClick={nav.login}
            type="button"
            href="/login"
            className="btn btn-register mainbarlink"
          >
            Zaloguj się
          </Button>
        </h3>
        <RegisterProfile registerProfile={handleRegisterProfile} />
        <div className="row mt-4 mb-4">
          <div className="col-5">
            <hr />
          </div>
          <div className="col-2">LUB</div>
          <div className="col-5">
            <hr />
          </div>
        </div>
        <SignInWithGoogleFb
          signInWithGoogle={handleGoogleSignIn}
          signInWithFacebook={handleFacebookSignIn}
        />
      </main>
    </>
  );
};
