import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// eslint-disable-next-line import/named
import Modal, { ModalProps } from 'react-bootstrap/Modal';
// eslint-disable-next-line import/named
import { Variant } from 'react-bootstrap/types';

interface AlertModalProps extends ModalProps {
  variant?: Variant;
}

export default (props: AlertModalProps) => {
  const buttonVariant = props.variant && 'outline-' + props.variant;
  return (
    <Modal
      {...props}
      centered={true}
      backdrop="static"
      backdropClassName="modal-on-modal-backdrop"
      className="modal-on-modal"
    >
      <Modal.Body>
        <Alert variant={props.variant} className="m-0">
          <Alert.Heading>{props.children}</Alert.Heading>
          {props.onHide && (
            <>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={props.onHide} variant={buttonVariant}>
                  Zamknij
                </Button>
              </div>
            </>
          )}
        </Alert>
      </Modal.Body>
    </Modal>
  );
};
