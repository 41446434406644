import React, { useEffect, useState } from 'react';

import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { TagEntry } from '../../../common/slimbeCore/contexts/DisciplinesContext';
import { getTagsFromStringArray } from '../../../common/slimbeCore/contexts/tagsUtils';

import { TagsImg } from './TagsImg';

interface TagsImgsProps {
  tags?: TagEntry[];
}

export const TagsImgs: React.FC<TagsImgsProps> = props => {
  return (
    <>
      {props.tags &&
        props.tags.length > 0 &&
        props.tags.map(t => (
          <TagsImg key={t.value} image={t.image} label={t.label} />
        ))}
    </>
  );
};

interface AreaImageProps {
  stringArray: string[];
}

export const AreaImage: React.FC<AreaImageProps> = ({ stringArray }) => {
  const slimbeApi = useSlimbeCore();
  const [tags, setTags] = useState<TagEntry[]>([]);

  useEffect(() => {
    let mounted = true;
    slimbeApi.areasEntry
      .then(entry => {
        if (mounted) {
          setTags(getTagsFromStringArray(entry, stringArray));
        }
      })
      .catch(err => console.log('Nie udało pobrać się tagów obszarów', err));
    return () => {
      mounted = false;
    };
  }, [slimbeApi.areasEntry, stringArray]);

  return (
    <div className="body-image">
      <TagsImg image="Body" />
      <div className="body-areas">
        {tags &&
          tags.length > 0 &&
          tags
            .filter(o => !o.idParent && o.image)
            .map(t => (
              <div key={t.value} className="body-area">
                <TagsImg key={t.value} image={t.image} />
              </div>
            ))}
      </div>
    </div>
  );
};
