/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { ParamModeProps } from '../FormControl/EditStringNodeProps';

export type EditableFormNodeProps = {
  narrow?: boolean;
  mode: ParamModeProps;
  viewNode: JSX.Element;
  editableNode: JSX.Element;
  onSave?: () => Promise<boolean>;
  setFocus?: () => void;
  onClose?: () => void;
  defaultPressEnter?: boolean;
};

/// onlyView - true, gdy ma to być tryb tylko podglądu
/// activeEditMode - true, tryb edycji, tryb podglądu w edycji
/// actionButton - true, gdy mają pojawiać się przyciski
/// onlyView=false, activeEditMode=true, actionButton=false - tryb dodawania
/// onlyView=false, activeEditMode=false, actionButton=false - tryb dodawania (podglad)
/// onlyView=false, activeEditMode=false, actionButton=true - tryb edycji
/// onlyView=false, activeEditMode=false, actionButton=false - tryb edycji (podgląd)
/// onlyView=true - tryb podglądu

export const EditableFormNode: React.FC<EditableFormNodeProps> = ({
  narrow,
  mode,
  viewNode,
  editableNode,
  onSave,
  setFocus,
  onClose,
  defaultPressEnter,
}) => {
  const onlyView = !mode.editable;
  const activeEditMode = mode.newRecord && !mode.viewMode;
  const actionButton = !mode.newRecord && !mode.viewMode;

  return (
    <EditableNode
      narrow={narrow}
      onlyView={onlyView}
      actionButton={actionButton}
      activeEditMode={activeEditMode}
      viewNode={viewNode}
      editableNode={editableNode}
      onSave={onSave}
      setFocus={setFocus}
      onClose={onClose}
      defaultPressEnter={defaultPressEnter}
    />
  );
};

export type EditableNodeProps = {
  narrow?: boolean;
  onlyView: boolean;
  activeEditMode: boolean;
  actionButton: boolean;
  viewNode: JSX.Element;
  editableNode: JSX.Element;
  onSave?: () => Promise<boolean>;
  setFocus?: () => void;
  onClose?: () => void;
  defaultPressEnter?: boolean;
};

export const EditableNode: React.FC<EditableNodeProps> = ({
  narrow,
  onlyView,
  activeEditMode,
  actionButton,
  viewNode,
  editableNode,
  onSave,
  setFocus,
  onClose,
  defaultPressEnter,
}) => {
  const [editableField, setEditableField] = useState<boolean>(false);
  const [startEdit, setStartEdit] = useState<boolean>(false);

  useEffect(() => {
    startEdit && setFocus && setFocus();
    startEdit && setStartEdit(false);
  }, [setFocus, startEdit]);

  useEffect(() => {
    setEditableField(activeEditMode || false);
  }, [activeEditMode]);

  const editField = async (open: boolean, save = false) => {
    let succes = true;
    if (!open) {
      if (save && onSave) {
        succes = await onSave();
      }
      !save && onClose && onClose();
    }
    if (succes) {
      setEditableField(open);
      setStartEdit(open);
    }
  };

  const keyPress = (
    event: React.KeyboardEvent,
    open: boolean,
    save = false,
  ) => {
    if (actionButton) {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        void editField(open, save);
        event.preventDefault();
      }
    }
  };

  return (
    <>
      {!editableField || onlyView ? (
        <ViewNodeWhitEditAction
          narrow={narrow}
          active={!onlyView && (actionButton || false)}
          onEdit={() => editField(true)}
        >
          {viewNode}
        </ViewNodeWhitEditAction>
      ) : (
        <div
          className="edit-field"
          onKeyPress={event => {
            !defaultPressEnter && keyPress(event, false, true);
          }}
        >
          {editableNode}
          {actionButton && (
            <div className="icon">
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Zapisz i zamknij</Tooltip>}
                >
                  <Icon.CheckLg
                    tabIndex={0}
                    className="function-icon"
                    onClick={() => editField(false, true)}
                    onKeyPress={event => keyPress(event, false, true)}
                  />
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Anuluj i zamknij</Tooltip>}
                >
                  <Icon.XLg
                    tabIndex={0}
                    className="function-icon"
                    onKeyPress={event => keyPress(event, false)}
                    onClick={() => editField(false)}
                  />
                </OverlayTrigger>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export type ViewNodeWhitEditActionProps = {
  active: boolean;
  onEdit?: () => void;
  narrow?: boolean;
};

export const ViewNodeWhitEditAction: React.FC<ViewNodeWhitEditActionProps> = ({
  active,
  onEdit,
  children,
  narrow,
}) => {
  const editDescription = () => {
    onEdit && onEdit();
  };

  const keyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      onEdit && onEdit();
      event.preventDefault();
    }
  };

  return (
    <>
      {' '}
      {!active ? (
        <>{children}</>
      ) : (
        <div className={narrow ? 'edit-field narrow' : 'edit-field'}>
          {children}
          <OverlayTrigger placement="top" overlay={<Tooltip>Edytuj</Tooltip>}>
            <Icon.PencilFill
              tabIndex={0}
              className="function-icon icon"
              onClick={() => editDescription()}
              onKeyPress={event => keyPress(event)}
            />
          </OverlayTrigger>
        </div>
      )}
    </>
  );
};
