import { MediaType } from '../../../slimbe/components/PresentationCard/CardMedia';
import { SlimbeUser } from '../../auth';
import { DefaultApi } from '../autogenerated/slimbeApiClient/apis/DefaultApi';
import { GetWorkoutDto } from '../autogenerated/slimbeApiClient/models/GetWorkoutDto';

import { CollectionCard } from './CollectionCard';

export type WorkoutEntity = GetWorkoutDto;

export type Workout = {
  workout: WorkoutEntity;
  author?: string | null;
};

export interface WorkoutsContextInterface {
  getWorkouts: (count?: number) => Promise<Workout[] | undefined>;
  getAsCardCollection: (
    count?: number,
  ) => Promise<CollectionCard[] | undefined>;
  getByUserUIDAsCardCollection: (
    userUID?: string | null,
  ) => Promise<CollectionCard[] | undefined>;
  getByUserUID: (userUID?: string | null) => Promise<Workout[] | undefined>;
  addWorkout: (workout: WorkoutEntity, user: SlimbeUser | undefined) => void;
  updateWorkout: (workout: WorkoutEntity) => void;
  getById: (id: string) => Promise<Workout | undefined>;
  delete: (id: string) => void;
  searchCardCollection: (
    searchText: string,
    disciplineArray: string[],
    areaArray: string[],
  ) => Promise<CollectionCard[] | undefined>;
}

export const WorkoutsContext = (api: DefaultApi) => {
  const getWorkouts = async (count?: number) => {
    const workouts = await api.workoutControllerGetAll(count);

    const workoutsWithAdd = workouts.map(async doc => {
      return {
        workout: doc,
        author: doc.user?.name,
      };
    });
    return Promise.all(workoutsWithAdd) as Promise<Workout[]>;
  };
  const getByUserUID = async (userUID?: string | null) => {
    if (!userUID) {
      return;
    }

    const workouts = await api.workoutControllerGetByUserUid(userUID);

    const workoutsWithAdd = workouts.map(async doc => {
      return {
        workout: doc,
        author: doc.user?.name,
      };
    });
    return Promise.all(workoutsWithAdd) as Promise<Workout[] | undefined>;
  };

  const getAsCardCollection = (workouts: Workout[]) => {
    return workouts.map(w => {
      return {
        id: w?.workout.id || '',
        userUID: w?.workout.user?.uid || '',
        title: w?.workout.topic || '',
        mediaType: MediaType.Video,
        src: w?.workout.link,
        avatar: w?.workout.user?.image || '',
        author: w?.author || '',
        description: w?.workout.shortDescription || '',
        time: w?.workout.time,
        areas: w?.workout.areaArray || [],
        disciplines: w?.workout.disciplineArray || [],
      };
    });
  };
  const search = async (
    searchText: string,
    disciplineArray: string[],
    areaArray: string[],
  ) => {
    const workouts = await api.workoutControllerSearch(
      searchText,
      disciplineArray,
      areaArray,
    );

    const foundWorkouts = workouts.map(async doc => {
      return {
        workout: doc,
        author: doc.user?.name,
      };
    });
    return Promise.all(foundWorkouts) as Promise<Workout[]>;
  };

  return {
    getWorkouts: async (count?: number) => getWorkouts(count),

    getAsCardCollection: async (count?: number) => {
      const collection = await getWorkouts(count);
      return Promise.all(getAsCardCollection(collection));
    },

    getByUserUIDAsCardCollection: async (userUID?: string | null) => {
      const collection = await getByUserUID(userUID);
      return Promise.all(getAsCardCollection(collection || []));
    },

    getByUserUID: async (userUID?: string | null) => getByUserUID(userUID),

    addWorkout: async (workout: WorkoutEntity) => {
      return await api.workoutControllerCreate({ ...workout });
    },

    updateWorkout: async (workout: WorkoutEntity) => {
      if (!workout.id) {
        return;
      }

      return api.workoutControllerUpdate(workout.id, {
        ...workout,
      });
    },

    getById: async (id: string) => {
      if (!id || id === undefined) {
        return;
      }
      const workout = await api.workoutControllerGet(id);
      if (workout) {
        return {
          workout: workout,
          author: workout.user?.name,
        } as Workout;
      } else {
        console.log('No such document!');
      }
    },

    delete: async (id: string) => {
      return await api.workoutControllerDelete(id);
    },

    searchCardCollection: async (
      searchText: string,
      disciplineArray: string[],
      areaArray: string[],
    ) => {
      const collection = await search(searchText, disciplineArray, areaArray);
      return Promise.all(getAsCardCollection(collection || []));
    },
  };
};
