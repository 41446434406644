import React from 'react';

import { ReactComponent as Body } from './Body.svg';
import { ReactComponent as Back } from './Back.svg';
import { ReactComponent as Buttocks } from './Buttocks.svg';
import { ReactComponent as Chest } from './Chest.svg';
import { ReactComponent as Hand } from './Hand.svg';
import { ReactComponent as Leg } from './Leg.svg';
import { ReactComponent as Neck } from './Neck.svg';
import { ReactComponent as Abs } from './Abs.svg';

interface TagsImgProps {
  image?: string;
  label?: string;
}

export const TagsImg: React.FC<TagsImgProps> = props => {
  return (
    <>
      {props.image === 'Body' && <Body fill="currentColor" />}
      {props.image === 'Back' && <Back fill="currentColor" />}
      {props.image === 'Buttocks' && <Buttocks fill="currentColor" />}
      {props.image === 'Chest' && <Chest fill="currentColor" />}
      {props.image === 'Hand' && <Hand fill="currentColor" />}
      {props.image === 'Leg' && <Leg fill="currentColor" />}
      {props.image === 'Neck' && <Neck fill="currentColor" />}
      {props.image === 'Abs' && <Abs fill="currentColor" />}
    </>
  );
};
