import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';

import { useCookies } from '../../common/hooks/useCookies';

import NavMain from './NavMain';
import Main from './Main';
import { Footer } from './Footer';

interface LayoutProps {
  location: string;
}

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  const cookies = useCookies();
  const [activeContrast, setActiveContrast] = useState(cookies.activeContrast);

  const changeActiveContrast = (className: string) => {
    if (
      activeContrast &&
      activeContrast !== '' &&
      activeContrast !== className
    ) {
      document.body.classList.remove(activeContrast);
    }
    setActiveContrast(className);
    cookies.setActiveContrast(className);
  };

  useEffect(() => {
    if (activeContrast) {
      cookies.setActiveContrast(activeContrast);
      if (activeContrast !== '') {
        document.body.classList.add(activeContrast);
      } else {
        document.body.classList.add('defaultContrast');
      }
    }
  }, [cookies, activeContrast]);

  return (
    <div className="page-container">
      <CookieConsent
        location="bottom"
        buttonText="Rozumiem"
        cookieName="myAgreeForCookie"
        expires={150}
      >
        Ta strona używa ciasteczek.
      </CookieConsent>

      <NavMain
        activePage={location}
        setContrast={className => changeActiveContrast(className)}
      />
      <Main>{children}</Main>
      <Footer />
    </div>
  );
};
export default Layout;
