import React from 'react';

import { ResetPasswordDialog } from '../components/ResetPassword/ResetPasswordDialog';
import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';

export const PasswordResetView: React.FC = () => {
  const slimbeApi = useSlimbeCore();
  const onPasswordReset = async (email: string) => {
    return await slimbeApi.signIn.resetPassword(email);
  };

  return <ResetPasswordDialog onResetPassword={onPasswordReset} />;
};
