/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetArticleDto,
    GetArticleDtoFromJSON,
    GetArticleDtoFromJSONTyped,
    GetArticleDtoToJSON,
    GetProfileDto,
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
    GetWorkoutDto,
    GetWorkoutDtoFromJSON,
    GetWorkoutDtoFromJSONTyped,
    GetWorkoutDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetLikeDto
 */
export interface GetLikeDto {
    /**
     * 
     * @type {string}
     * @memberof GetLikeDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLikeDto
     */
    guid?: string | null;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetLikeDto
     */
    profile?: GetProfileDto | null;
    /**
     * 
     * @type {GetArticleDto}
     * @memberof GetLikeDto
     */
    article?: GetArticleDto | null;
    /**
     * 
     * @type {GetWorkoutDto}
     * @memberof GetLikeDto
     */
    workout?: GetWorkoutDto | null;
    /**
     * 
     * @type {Date}
     * @memberof GetLikeDto
     */
    time?: Date | null;
}

export function GetLikeDtoFromJSON(json: any): GetLikeDto {
    return GetLikeDtoFromJSONTyped(json, false);
}

export function GetLikeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLikeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'profile': !exists(json, 'profile') ? undefined : GetProfileDtoFromJSON(json['profile']),
        'article': !exists(json, 'article') ? undefined : GetArticleDtoFromJSON(json['article']),
        'workout': !exists(json, 'workout') ? undefined : GetWorkoutDtoFromJSON(json['workout']),
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
    };
}

export function GetLikeDtoToJSON(value?: GetLikeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'profile': GetProfileDtoToJSON(value.profile),
        'article': GetArticleDtoToJSON(value.article),
        'workout': GetWorkoutDtoToJSON(value.workout),
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
    };
}


