import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { TabTitle } from '../../../app/components/TabTitle';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../../common/navigation';
import { CollectionWall } from '../Collections/CollectionWall';

type SearchResultsProps = {
  showResults: boolean;
  searchText: string;
  disciplineArray: string[];
  areaArray: string[];
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  showResults,
  searchText,
  disciplineArray,
  areaArray,
}) => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();

  const [workoutsCount, setWorkoutsCount] = useState<number | undefined>();
  const [articlesCount, setArticlesCount] = useState<number | undefined>();
  const [coachesCount, setCoachesCount] = useState<number | undefined>();

  return (
    <>
      {showResults ? (
        <Tabs
          defaultActiveKey="workouts"
          className="mb-2 mt-3 d-flex justify-content-center"
        >
          <Tab
            eventKey="workouts"
            title={
              <TabTitle
                name="Treningi"
                length={workoutsCount}
                showZero={true}
              />
            }
          >
            <CollectionWall
              smallCard={false}
              editable={false}
              objectName="trening"
              whatCollection="treningów"
              getCardCollecion={() =>
                slimbeApi.workouts.searchCardCollection(
                  searchText,
                  disciplineArray,
                  areaArray,
                )
              }
              navCardView={nav.workoutFormView}
              setCountCollection={setWorkoutsCount}
            />
          </Tab>
          <Tab
            eventKey="articles"
            title={
              <TabTitle
                name="Artykuły"
                length={articlesCount}
                showZero={true}
              />
            }
          >
            <CollectionWall
              smallCard={false}
              editable={false}
              objectName="artykuł"
              whatCollection="artykułów"
              getCardCollecion={() =>
                slimbeApi.articles.searchCardCollection(
                  searchText,
                  disciplineArray,
                  areaArray,
                )
              }
              navCardView={nav.articleFormView}
              setCountCollection={setArticlesCount}
            />
          </Tab>
          <Tab
            eventKey="coaches"
            title={
              <TabTitle name="Trenerzy" length={coachesCount} showZero={true} />
            }
          >
            <CollectionWall
              smallCard={true}
              editable={false}
              objectName="trener"
              whatCollection="trenerów"
              getCardCollecion={() =>
                slimbeApi.profiles.searchCardCollection(
                  searchText,
                  disciplineArray,
                  areaArray,
                )
              }
              navCardView={nav.profileView}
              setCountCollection={setCoachesCount}
            />
          </Tab>
        </Tabs>
      ) : (
        <h3 className="text-center pt-3">Podaj kryteria wyszukiwania</h3>
      )}
    </>
  );
};
