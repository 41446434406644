import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { SmallPresentationCard } from '../PresentationCard/SmallPresentationCard';

import { RowCardCollectionProps } from './RowCardCollectionProps';

export const RowSmallCardCollection: React.FC<RowCardCollectionProps> = ({
  collection,
  actionOnEdit,
  onSelect,
  showImageOnSelect,
}) => {
  const card = (idx: number, row: number) => {
    return (
      idx % 4 === row && (
        <SmallPresentationCard
          userUID={collection[idx].userUID}
          actionOnEdit={actionOnEdit(collection[idx].id)}
          key={idx}
          title={collection[idx].title}
          src={collection[idx].src}
          disciplines={collection[idx].disciplines}
          description={collection[idx].description}
          onSelect={onSelect(collection[idx].id)}
          clickActive={true}
          showImageOnSelect={showImageOnSelect}
        />
      )
    );
  };

  return (
    <>
      <Container fluid className="main-content-container px-4">
        <Row xs={1} md={4} className="g-4">
          <Col>{Object.keys(collection).map((key, idx) => card(idx, 0))}</Col>
          <Col>{Object.keys(collection).map((key, idx) => card(idx, 1))}</Col>
          <Col>{Object.keys(collection).map((key, idx) => card(idx, 2))}</Col>
          <Col>{Object.keys(collection).map((key, idx) => card(idx, 3))}</Col>
        </Row>
      </Container>
    </>
  );
};
