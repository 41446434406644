import { TagEntry } from './DisciplinesContext';

export const getTagsFromStringArray = (
  tags: TagEntry[] | null | undefined,
  tagsArray: string[] | undefined | null,
) => {
  if (!tags || !tagsArray) {
    return [];
  }
  const selection = [];
  for (const v of tagsArray || []) {
    const tag = tags.filter(o => o.value === v);
    if (tag.length === 1) {
      selection.push(tag[0]);
    }
  }
  return selection;
};
