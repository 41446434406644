export const errorChangePasswordEmailMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
) => {
  let message = 'Zmiana danych logowania nie powiodła się.';
  if (error?.code) {
    switch (error.code) {
      case 'auth/email-already-exists':
      case 'auth/email-already-in-use':
        message = 'E-mail użytkownika jest już zajęty.';
        break;
      case 'auth/invalid-email':
        message = 'Niepoprawny adres e-mail.';
        break;
      case 'auth/weak-password':
        message = 'Hasło powinno składać się z co najmniej 6 znaków.';
        break;
      case 'auth/requires-recent-login':
        message =
          'Zbyt dawne logowanie. W celu zmiany danych logowania należy się wylogować i ponownie zalogować do systemu.';
        break;
      default:
        message = 'Zmiana danych logowania nie powiodła się.';
        break;
    }
  }
  throw new Error(message);
};

export const errorLoginMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
) => {
  let message = 'Błędne dane logowania.';
  if (error?.code) {
    switch (error.code) {
      case 'auth/email-already-exists':
      case 'auth/email-already-in-use':
        message = 'E-mail użytkownika jest już zajęty.';
        throw new Error(message);
      case 'auth/invalid-email':
        message = 'Niepoprawny adres e-mail.';
        throw new Error(message);
      case 'auth/weak-password':
        message = 'Hasło powinno składać się z co najmniej 6 znaków.';
        throw new Error(message);
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
        message = 'Logowanie zostało przerwane przez użytkownika';
        break;
      default:
        message = 'Błędne dane logowania.';
        throw new Error(message);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resetPasswordErrorMessage = (error: any) => {
  let message = undefined;
  if (error?.code) {
    // to stop information leak only some errors are treated as errors.
    switch (error.code) {
      case 'auth/invalid-email':
        message = 'Niepoprawny adres e-mail.';
        break;
    }
  }
  if (message) {
    throw new Error(message);
  }
};
