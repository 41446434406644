import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import { Profile } from '../../../common/slimbeCore/contexts/ProfilesContext';

export type Props = {
  registerProfile: (profile: Profile, password: string) => void;
};

export const RegisterProfile: React.FC<Props> = ({ registerProfile }) => {
  const [profile, setProfile] = useState<Profile | undefined>();
  const [password, setPassword] = useState<string>();
  const [passwordRepeat, setPasswordRepeat] = useState<string>();
  const [emailRequiredError, setEmailRequiredError] = useState<string>();
  const [nameRequiredError, setNameRequiredError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  function execCaptcha(): string | null {
    let token: string | null = null;
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.execute();
      token = recaptchaRef.current.getValue();
    }
    return token;
  }

  const checkProfileFieldsFailed = (profile: Profile): boolean => {
    return !profile || !profile?.email || !profile?.name;
  };

  const setProfileFieldsErrors = (profile: Profile | undefined): void => {
    if (!profile?.email) {
      setEmailRequiredError('Podaj adres e-mail.');
    }
    if (!profile?.name) {
      setNameRequiredError('Podaj imię i nazwisko, pseudonim.');
    }
  };

  const setPasswordsFieldsErrors = (
    password: string | undefined,
    passwordRepeat: string | undefined,
  ): void => {
    if (!password) {
      setPasswordError('Wpisz hasło.');
    }
    if (password !== passwordRepeat) {
      setPasswordError('Hasła się nie zgadzają.');
    }
  };

  const handleSave = async () => {
    const token = execCaptcha();
    if (token) {
      await handleValidated(token);
    }
  };

  const handleValidated = async (token: string | null) => {
    const validatedHuman = !!token;
    setEmailRequiredError('');
    setPasswordError('');
    setNameRequiredError('');
    if (
      !validatedHuman ||
      password !== passwordRepeat ||
      !profile ||
      !password ||
      checkProfileFieldsFailed(profile)
    ) {
      setProfileFieldsErrors(profile);
      setPasswordsFieldsErrors(password, passwordRepeat);
      return;
    }
    await registerProfile(profile, password);
  };

  const propertyChange = (obj: Partial<Profile>) => {
    setProfile({ ...(profile as Profile), ...obj });
  };

  async function onRecChange(value: string | null) {
    await handleValidated(value);
  }

  return (
    <>
      <Form>
        <Form.Group className="form-floating mt-3" controlId="name">
          <Form.Control
            type="name"
            placeholder="Imię i nazwisko, pseudonim"
            defaultValue={profile?.name || ''}
            onChange={e => propertyChange({ name: e.target.value })}
          />
          <Form.Label>Imię i nazwisko, pseudonim</Form.Label>
        </Form.Group>
        <span className="input-form-error">{nameRequiredError}</span>

        <Form.Group className="form-floating mt-3" controlId="email">
          <Form.Control
            type="email"
            placeholder="Adres e-mail"
            defaultValue={profile?.email || ''}
            onChange={e => propertyChange({ email: e.target.value })}
          />
          <Form.Label>Adres e-mail</Form.Label>
        </Form.Group>
        <span className="input-form-error">{emailRequiredError}</span>
        <Form.Group className="form-floating mt-3" controlId="password">
          <Form.Control
            type="password"
            placeholder="Hasło"
            onChange={e => setPassword(e.target.value)}
          />
          <Form.Label>Hasło</Form.Label>
        </Form.Group>
        <span className="input-form-error">{passwordError}</span>
        <Form.Group className="form-floating mt-3" controlId="repeatPassword">
          <Form.Control
            type="password"
            placeholder="Powtórzone hasło"
            onChange={e => setPasswordRepeat(e.target.value)}
          />
          <Form.Label>Powtórzone hasło</Form.Label>
        </Form.Group>
        <div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_SITE_KEY + ''}
            size="invisible"
            onChange={onRecChange}
            ref={recaptchaRef}
          />
        </div>
      </Form>
      <Button className="w-100 btn btn-lg btn-login mt-3" onClick={handleSave}>
        Zarejestruj się
      </Button>
    </>
  );
};
