/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetProfileDto,
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPhotoDto
 */
export interface GetPhotoDto {
    /**
     * 
     * @type {string}
     * @memberof GetPhotoDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPhotoDto
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPhotoDto
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetPhotoDto
     */
    image?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetPhotoDto
     */
    time?: Date | null;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetPhotoDto
     */
    user?: GetProfileDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPhotoDto
     */
    active?: boolean | null;
}

export function GetPhotoDtoFromJSON(json: any): GetPhotoDto {
    return GetPhotoDtoFromJSONTyped(json, false);
}

export function GetPhotoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPhotoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'user': !exists(json, 'user') ? undefined : GetProfileDtoFromJSON(json['user']),
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function GetPhotoDtoToJSON(value?: GetPhotoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'topic': value.topic,
        'shortDescription': value.shortDescription,
        'image': value.image,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'user': GetProfileDtoToJSON(value.user),
        'active': value.active,
    };
}


