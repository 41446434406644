import React from 'react';

import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../common/navigation';
import { CollectionWall } from '../components/Collections/CollectionWall';

export const ArticlesView: React.FC = () => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();

  return (
    <>
      <CollectionWall
        smallCard={false}
        editable={false}
        objectName="artykuł"
        whatCollection="artykułów"
        getCardCollecion={slimbeApi.articles.getAsCardCollection}
        navCardView={nav.articleFormView}
      />
    </>
  );
};
