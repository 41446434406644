/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { DisciplinesSelect } from '../Tags/DisciplinesSelect';
import { AreasSelect } from '../Tags/AreasSelect';

type SearchBoxProps = {
  onSearch: (
    searchText: string,
    disciplineArray: string[],
    areaArray: string[],
  ) => void;
};

export const SearchBox: React.FC<SearchBoxProps> = ({ onSearch }) => {
  const [searchText, setSearchText] = useState('');
  const [disciplineArray, setDisciplineArray] = useState<string[]>([]);
  const [areaArray, setAreaArray] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isFillSearch, setIsFillSearch] = useState(false);

  useEffect(() => {
    setIsFillSearch(
      searchText.length > 0 ||
        areaArray.length > 0 ||
        disciplineArray.length > 0,
    );
  }, [areaArray, disciplineArray, searchText]);

  const handleSearch = async () => {
    onSearch(searchText, disciplineArray, areaArray);
  };

  const handleAdvancedSearch = async () => {
    setShowFilters(!showFilters);
  };

  const keyPressSearch = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      void handleSearch();
      event.preventDefault();
    }
  };

  const resetSearch = () => {
    setSearchText('');
    setDisciplineArray([]);
    setAreaArray([]);
    setIsFillSearch(false);
  };

  return (
    <>
      <Row className="gy-3 justify-content-center pt-5">
        <Col className="col-8 col-md-8 pe-1">
          <InputGroup>
            <FormControl
              className="form-control-lg"
              placeholder="Wyszukaj"
              aria-label="Wpisz frazę do wyszukania"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={event => keyPressSearch(event)}
            />
            {isFillSearch && (
              <Button
                variant="btn btn-my-outline-btn btn-lg"
                onClick={resetSearch}
              >
                <Icon.XLg className="black-icon" />
              </Button>
            )}
            <Button
              variant="btn btn-my-outline-btn btn-lg"
              aria-label={
                showFilters
                  ? 'Pokaż wyszukiwanie zaawansowane'
                  : 'Ukryj wyszukiwanie zaawansowane'
              }
              onClick={handleAdvancedSearch}
            >
              {showFilters ? (
                <Icon.ArrowBarUp className="black-icon" />
              ) : (
                <Icon.Sliders className="black-icon" />
              )}
            </Button>
          </InputGroup>
        </Col>
        <Col className="col-4 col-md-2 ps-1">
          <Button
            type="submit"
            className="btn btn-lg btn-my-outline-opposed-btn col-12"
            onClick={handleSearch}
          >
            Szukaj
          </Button>
        </Col>
        {showFilters && (
          <Col className="col-12 col-md-10 p-3">
            <div className="select-control">
              <span className="form-label">Dyscypliny</span>
              <div className="pt-3">
                <DisciplinesSelect
                  disciplineArray={disciplineArray}
                  propertyChange={dsc => setDisciplineArray(dsc)}
                  focus={true}
                />
              </div>
            </div>
            <div className="select-control h-80">
              <span className="form-label">Obszary</span>
              <div className="pt-3">
                <AreasSelect
                  areaArray={areaArray}
                  propertyChange={areas => setAreaArray(areas)}
                  focus={false}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
