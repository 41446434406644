import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import parse from 'html-react-parser';

import RichTextEdit from '../../../app/components/RichTextEdit';
import { EditableFormNode } from '../EditableCollection/EditableNode';

import { EditStringNodeProps } from './EditStringNodeProps';

export const DescriptionNode: React.FC<EditStringNodeProps> = ({
  value,
  mode,
  onSave,
}) => {
  const [description, setDescription] = useState<string>(value);
  const [descriptionFocus, setDescriptionFocus] = useState<boolean>(false);
  const onChange = async (txt: string) => {
    setDescription(txt);
    if (mode.newRecord) {
      await onSave(txt);
    }
  };

  return (
    <EditableFormNode
      mode={mode}
      viewNode={
        <div className="ql-snow w-80 pt-4">
          <div className="ql-editor reading-field">{parse(description)}</div>
        </div>
      }
      editableNode={
        <div>
          <Form.Group className="mb-3" controlId="description">
            <RichTextEdit
              value={description}
              onChange={onChange}
              focus={descriptionFocus}
            />
          </Form.Group>
        </div>
      }
      onSave={() => onSave(description)}
      onClose={() => setDescription(value)}
      setFocus={() => setDescriptionFocus(true)}
    />
  );
};
