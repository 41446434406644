// eslint-disable-next-line import/named
import { UserCredential } from 'firebase/auth';
import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

import { useAuth } from '../../../common/hooks/useAuth';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../../common/navigation';
import politykaPDF from '../../../assets/SLIMBE_POLITYKA.pdf';
import regulaminPDF from '../../../assets/SLIMBE_REGULAMIN.pdf';

export default (
  props: React.PropsWithChildren<{
    show: boolean;
    onHide?: () => void;
    userCred: UserCredential | void;
  }>,
) => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();
  const auth = useAuth();

  const [accept, setAccept] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  const handleSave = () => {
    if (!props.userCred) {
      return;
    }
    slimbeApi.profiles
      .addSlimbeProfile(
        props.userCred.user.email || '',
        props.userCred.user.displayName || '',
        newsletter,
        auth.currentUser?.uid || '',
      )
      .then(prof => {
        auth.updateProfile(prof);
        nav.myProfile();
      })
      .catch(error =>
        console.log(`Wystąpił problem z dodaniem profilu. ${error}`),
      );

    props.onHide && props.onHide();
  };

  const handleCancel = () => {
    void auth.logout();
    props.onHide && props.onHide();
  };

  return (
    <Modal
      show={props.show}
      centered={true}
      backdrop="static"
      backdropClassName="modal-on-modal-backdrop"
      className="modal-on-modal"
    >
      <Modal.Body>
        <div className="text-center">
          <strong>
            Administratorem Twoich danych osobowych będzie Slimbe Marcin
            Jackowski.
          </strong>
          <br />
          <br />
          Dane będą przetwarzane w celu realizacji usług dostarczanych przez
          serwis.
          <br />
          <br />
          Przysługuje Ci prawo do żądania dostępu do swoich danych osobowych,
          ich sprostowania, sprzeciwienia się ich wykorzystywaniu, żądania ich
          usunięcia i przeniesienia. <br />
          <br />
          Więcej dowiesz się z naszej{' '}
          <a
            className="text-decoration-none"
            href={politykaPDF}
            target="_blank"
            rel="license noreferrer"
          >
            polityki prywatności
          </a>
          .
        </div>
        <hr />
        <Row className="mt-3">
          <Col>
            <Form.Check
              id={'1'}
              type="checkbox"
              label={
                <span>
                  Akceptuję{' '}
                  <a
                    className="text-decoration-none"
                    href={regulaminPDF}
                    target="_blank"
                    rel="license noreferrer"
                  >
                    regulamin
                  </a>{' '}
                  oraz{' '}
                  <a
                    className="text-decoration-none"
                    href={politykaPDF}
                    target="_blank"
                    rel="license noreferrer"
                  >
                    politykę prywatności
                  </a>
                </span>
              }
              onChange={() => setAccept(!accept)}
            />
          </Col>
          <Col>
            <Form.Check
              id={'2'}
              type="checkbox"
              label="Chcę otrzymywać newsletter"
              onChange={() => setNewsletter(!newsletter)}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            disabled={!accept}
            onClick={handleSave}
            variant="my-outline-opposed-btn"
            className="me-2"
          >
            Zapisz
          </Button>
          <Button onClick={handleCancel} variant="my-outline-btn">
            Rezygnuj
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
