/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Badge, Row, Col, Container } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useAuth } from '../../../common/hooks/useAuth';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import {
  Article,
  ArticleEntity,
} from '../../../common/slimbeCore/contexts/ArticlesContext';
import { AreasSelector } from '../Tags/AreasSelector';
import { AreaImage } from '../Tags/TagsImgs';
import { DisciplinesSelector } from '../Tags/DisciplinesSelector';
import momencik from '../../../common/momencik';
import { DescriptionNode } from '../FormControl/DescriptionNode';
import { TitleNode } from '../FormControl/TitleNode';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { AuthorNode } from '../FormControl/AuthorNode';
import { ActionFormButtons } from '../FormControl/ActionFormButtons';
import { useNavigation } from '../../../common/navigation';
import { Like } from '../Like/Like';
import { ShortDescriptionNode } from '../FormControl/ShortDescriptionNode';
import { ImageNode } from '../FormControl/ImageNode';
interface ArticleProps {
  article?: Article;
  editMode: boolean;
}

// eslint-disable-next-line complexity
export const ArticleForm: React.FC<ArticleProps> = ({ article, editMode }) => {
  const nav = useNavigation();
  const auth = useAuth();
  const slimbeApi = useSlimbeCore();

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [editable, setEditable] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [mainAreaTags, setMainAreaTags] = useState<string[]>([]);

  const [newArticle, setNewArticle] = useState<ArticleEntity>(
    article?.article || {
      topic: 'Tytuł artykułu...',
      contents: '<h1>Temat artykułu</h1>Opis...',
    },
  );
  useEffect(() => {
    if (article) {
      const userUID = '' + auth?.currentUser?.uid;
      const isAdmin = auth?.userProfile?.isAdmin || false;
      const owner = userUID === article.article.user?.uid;
      setEditable(owner && editMode);
      setViewMode((!editMode && owner) || (isAdmin && !owner));
      setMainAreaTags(article.article.areaArray || []);
    } else {
      setEditable(true);
      setIsNew(true);
    }
  }, [article, auth?.currentUser?.uid, auth?.userProfile?.isAdmin, editMode]);

  const handleSaveTopic = (topic: string) => {
    return handleSave({ topic: topic });
  };

  const handleSaveDescription = (description: string) =>
    handleSave({ contents: description });

  const handleSaveShortDescription = (description: string) =>
    handleSave({ shortDescription: description });

  const handleSave = async (obj: Partial<ArticleEntity>) => {
    try {
      const newObjArticle = { ...newArticle, ...obj };
      setNewArticle(newObjArticle);

      if (newArticle.id) {
        await slimbeApi.articles.updateArticle(newObjArticle);
      }
      return true;
    } catch (err) {
      setErrorText(`Zapis artykułu jest niemożliwy. ${(err as Error).message}`);
      setShowError(true);
      return false;
    }
  };

  const changeViewMode = (active: boolean) => {
    setViewMode(active);
    setEditable(!active);
  };

  const handleAdd = async () => {
    await slimbeApi.articles.addArticle(newArticle);
    nav.myArticles();
  };

  const handleDelete = async () => {
    if (newArticle.id) {
      await slimbeApi.articles.delete(newArticle.id);
    }
    nav.myArticles();
  };
  const paramMode: ParamModeProps = {
    newRecord: isNew,
    viewMode: viewMode,
    editable: editable,
  };
  return (
    <>
      <Container className="p-3 my-form-view">
        <ActionFormButtons
          objectName={'artykuł'}
          mode={paramMode}
          onAdd={handleAdd}
          onDelete={handleDelete}
          setViewMode={changeViewMode}
          onGiveUp={nav.myArticles}
        />
        <Row className="gy-3 mb-3">
          <Col className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
            <Container className="ps-0 pe-0">
              <ImageNode
                anyImg={false}
                title={newArticle?.topic || ''}
                image={newArticle?.image || ''}
                editable={editable}
                onSave={img => handleSave({ image: img })}
              />
              <Row>
                {!editable && (
                  <Col className="col-6">
                    <div className="text-opposed-color ps-1">
                      {momencik(article?.article.time) || ''}
                    </div>
                  </Col>
                )}
                {!editable && (
                  <Col className="col-6 d-flex justify-content-end">
                    <div className="pe-1">
                      <Icon.Watch className="black-icon" />{' '}
                      <Badge bg="primary">{article?.article.duration}</Badge>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="pt-3 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                <Col className="col-md-12 col-12">
                  <ShortDescriptionNode
                    value={newArticle?.shortDescription || ''}
                    mode={paramMode}
                    onSave={handleSaveShortDescription}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col className="col-lg-6 col-md-6 d-flex justify-content-center align-self-center">
            <Row className="gy-3 w-100">
              <Col className="col-md-12 col-sm-12 col-12 d-flex justify-content-end  p-0">
                <Like object={newArticle} />
              </Col>
              <Col className="col-md-12 p-0">
                <TitleNode
                  value={newArticle?.topic || ''}
                  mode={paramMode}
                  onSave={handleSaveTopic}
                />
              </Col>
              <Col className="col-md-12 col-sm-12 col-12 p-0 mt-1">
                <AuthorNode
                  mode={paramMode}
                  author={article?.author || ''}
                  avatar={article?.article.user?.image || ''}
                  userId={article?.article.user?.id || ''}
                />
              </Col>
              <Col className="col-md-12 col-12 d-block d-md-none d-lg-none d-xl-none d-xxl-none p-0">
                <ShortDescriptionNode
                  value={newArticle?.shortDescription || ''}
                  mode={paramMode}
                  onSave={handleSaveShortDescription}
                />
              </Col>
              <Col className="col-md-12 col-sm-12 col-12 p-0 align-self-center">
                <DisciplinesSelector
                  stringArray={newArticle.disciplineArray || []}
                  mode={paramMode}
                  onSave={disc => handleSave({ disciplineArray: disc })}
                />
              </Col>
              <Col className="col-md-5 col-sm-6 col-12 p-0">
                <AreaImage stringArray={mainAreaTags} />
              </Col>
              <Col className="col-md-7 col-sm-6 col-12 p-0">
                <AreasSelector
                  stringArray={newArticle.areaArray || []}
                  mode={paramMode}
                  onSave={area => handleSave({ areaArray: area })}
                  setSelectedTags={tags => setMainAreaTags(tags)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="gy-3 pb-3">
          <Col className="col-md-12 hr pt-2">
            <DescriptionNode
              value={newArticle?.contents || ''}
              mode={paramMode}
              onSave={handleSaveDescription}
            />
          </Col>
        </Row>
        <ActionFormButtons
          objectName={'artykuł'}
          mode={paramMode}
          onAdd={handleAdd}
          onDelete={handleDelete}
          setViewMode={changeViewMode}
          onGiveUp={nav.myArticles}
        />
      </Container>
      <ValidationAlert
        heading={'Zapis artykułu'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
