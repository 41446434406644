import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Image, Container } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { getAuth } from 'firebase/auth';

import { ViewNodeWhitEditAction } from '../EditableCollection/EditableNode';
import {
  AreaBadges,
  DisciplineBadges,
  MainAreaBadges,
} from '../Tags/TagsBadges';

import { CardMedia, MediaType } from './CardMedia';

export interface PresentationCardProps {
  userUID?: string | undefined | null;
  actionOnEdit?: () => void;
  title: string;
  mediaType: MediaType;
  src?: string | null;
  areas?: string[];
  disciplines: string[];
  author?: string;
  avatar?: string;
  duration?: string | null;
  description: string;
  time?: string;
  onSelect: () => void;
  clickActive: boolean;
}

export const PresentationCard: React.FC<PresentationCardProps> = ({
  userUID,
  actionOnEdit,
  title,
  mediaType,
  src,
  areas,
  disciplines,
  author,
  avatar,
  duration,
  description,
  time,
  onSelect,
  clickActive,
}) => {
  const maxMainAreaTags = 5;
  const maxAreaTags = 5;
  const maxDisciplineTags = 5;

  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    if (userUID) {
      const auth = getAuth();
      setEditable(auth?.currentUser?.uid === userUID);
    }
  }, [userUID]);

  const onKeypress = (e: { nativeEvent: { key: string } }) => {
    if (e.nativeEvent.key === 'Enter') {
      onSelect();
    }
  };

  return (
    <ViewNodeWhitEditAction active={editable} onEdit={actionOnEdit}>
      <Card
        className="my-card mb-4"
        onDoubleClick={!clickActive ? onSelect : undefined}
        onClick={clickActive ? onSelect : undefined}
        tabIndex={0}
        onKeyPress={onKeypress}
      >
        <Card.Body>
          <Card.Title
            className="my-title block-with-text mb-3"
            onKeyPress={onKeypress}
            onClick={onSelect}
          >
            {title}
          </Card.Title>
          <div className="mb-3">
            <CardMedia title={title} mediaType={mediaType} src={src} />
          </div>
          <div className="my-card-person mb-3">
            {avatar && (
              <Image
                src={avatar}
                alt="Avatar autora"
                className="img-avatar small-avatar"
              />
            )}{' '}
            {author}{' '}
          </div>
          <div>
            {areas && areas.length > 0 && (
              <Container className="mb-3 my-card-tags p-0">
                <Row>
                  <Col className="col-12 h-100">
                    <MainAreaBadges
                      stringArray={areas}
                      limit={maxMainAreaTags}
                    />
                  </Col>
                  <Col className="col-12 mb-3 h-100">
                    <div className="pt-1">
                      <AreaBadges stringArray={areas} limit={maxAreaTags} />
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
            <div className="select-control mb-3">
              <span className="form-label">Dyscypliny</span>
              <div className="">
                <DisciplineBadges
                  stringArray={disciplines}
                  limit={maxDisciplineTags}
                />
              </div>
            </div>
          </div>
          {duration && (
            <div className="mb-3">
              <Icon.Watch /> <Badge bg="secondary">{duration}</Badge>
            </div>
          )}
          <div className="block-with-text row-5">{description}</div>
        </Card.Body>
        <Card.Footer>
          <small>{time}</small>
        </Card.Footer>
      </Card>
    </ViewNodeWhitEditAction>
  );
};
