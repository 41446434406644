import React, { useEffect, useState } from 'react';
import { Button, Form, NavDropdown, Image } from 'react-bootstrap';

import { AuthState } from '../../common/auth';
import { useAuth } from '../../common/hooks/useAuth';
import {
  currentProfileMenuItems,
  useNavigation,
} from '../../common/navigation';

import AlertModal from './AlertModal';
import ValidationAlert from './ValidationAlert';

interface ProfileButtonProps {
  activePage: string;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({ activePage }) => {
  const nav = useNavigation();
  const auth = useAuth();

  const [avatar, setAvatar] = useState('');
  const [currentProfileLinks, setCurrentProfileLinks] = useState(
    currentProfileMenuItems(activePage),
  );
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    setCurrentProfileLinks(currentProfileMenuItems(activePage));
  }, [activePage]);

  useEffect(() => {
    if (auth.userProfile) {
      const profile = auth.userProfile;
      setAvatar(profile.image || '');
    } else {
      setAvatar('');
    }
  }, [auth.userProfile, auth.currentUser?.email]);

  const handleLogout = () => {
    auth
      .logout()
      .then(() => {
        nav.startPage();
      })
      .catch(() => {
        setErrorText('Błąd podczas wylogowywania.');
        setShowError(true);
      });
  };

  const handleLogin = () => {
    nav.login();
  };

  return (
    <>
      <div className="menu-profile">
        <ValidationAlert
          show={showError}
          modal={true}
          onHide={() => {
            setShowError(false);
          }}
          errors={[errorText]}
        />
        <AlertModal variant="danger">{}</AlertModal>
        {auth.state === AuthState.LoggedOut && (
          <Form>
            <Button variant="my-outline-opposed-btn" onClick={handleLogin}>
              Zaloguj się jako trener
            </Button>
          </Form>
        )}
        {auth.state === AuthState.LoggedIn && (
          <NavDropdown
            title={
              <Image
                src={avatar}
                alt="Profil trenera"
                className="img-avatar"
                hidden={!avatar}
              />
            }
            id="nav-profile-dropdown"
            align={'end'}
          >
            <NavDropdown.ItemText className="profile-name">
              {auth.userProfile?.name}
            </NavDropdown.ItemText>
            <NavDropdown.ItemText className="profile-email">
              {auth.currentUser?.email}
            </NavDropdown.ItemText>
            <NavDropdown.Divider />
            {currentProfileLinks.map(({ path, heading }) => (
              <NavDropdown.Item
                key={path}
                onClick={() => nav.menuItem(path)}
                className="d-flex"
              >
                {heading}
              </NavDropdown.Item>
            ))}
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey="logout" onClick={handleLogout}>
              Wyloguj
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div>
    </>
  );
};

export default ProfileButton;
