import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import FormRow from './FormRow';
import ValidationAlert from './ValidationAlert';

export default (
  props: React.PropsWithChildren<{
    confirmation: string;
    className?: string;
    variant?: string;
    askForAReason?: boolean;
    reasonText?: string;
    reason?: string;
    reasonNotEmpty?: boolean;
    onValidation?: () => Promise<boolean>;
    onOK: (reason?: string) => void;
  }>,
) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState<string>();
  const [showError, setShowError] = useState(false);

  const validate = async () => {
    if (props.onValidation) {
      const status = await props.onValidation();
      return status;
    }
    return true;
  };

  useEffect(() => {
    setShowError(false);
    setReason(props.reason);
  }, [show, props.reason]);

  return (
    <>
      <Button
        className={props.className}
        variant={props.variant}
        onClick={async () => {
          const valid = await validate();
          if (!valid) {
            return;
          }
          setShow(true);
        }}
      >
        {props.children}
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size={props.askForAReason ? 'lg' : undefined}
        centered={true}
        backdrop="static"
        backdropClassName="modal-on-modal-backdrop"
        className="modal-on-modal"
      >
        <Modal.Header>
          <Modal.Title>{props.confirmation}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.askForAReason && (
            <>
              <Form className="d-grid mb-3">
                <FormRow controlId="reason" label={props.reasonText || 'Powód'}>
                  <Form.Control
                    inputMode="text"
                    value={reason || ''}
                    onChange={e => setReason(e.target.value)}
                  />
                </FormRow>
              </Form>
              <ValidationAlert
                show={showError}
                errors={['Proszę podać powód.']}
              />
            </>
          )}
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-secondary">
              Nie
            </Button>
            <Button
              className="ms-1"
              onClick={() => {
                if (props.askForAReason && props.reasonNotEmpty && !reason) {
                  setShowError(true);
                  return;
                }
                setShow(false);
                props.onOK(reason);
              }}
              variant={props.variant}
            >
              Tak
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
