import React, { useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { EditableNode } from '../EditableCollection/EditableNode';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import ValidationAlert from '../../../app/components/ValidationAlert';

export const ProfilePasswordNode: React.FC = () => {
  const slimbeApi = useSlimbeCore();

  let password = '';
  let passwordRepeat = '';
  const [onlyView, setOnlyView] = useState<boolean>(true);
  const [actionButton, setActionButton] = useState<boolean>(false);
  const [activeEditMode, setActiveEditMode] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const passwdRef = useRef<HTMLInputElement>(null);
  const setFocusPasswd = () => {
    passwdRef.current?.focus();
  };

  const showMessage = (txt: string) => {
    setErrorText(txt);
    setShowError(true);
  };

  const onSave = async () => {
    try {
      if (password.length < 6) {
        showMessage('Wpisz hasło dłuższe niż 5 znaków.');
        return false;
      }
      if (password !== passwordRepeat) {
        showMessage('Hasła się nie zgadzają.');
        return false;
      }
      await slimbeApi.signIn.updatePassword(password);
      showMessage('Hasło zmienione.');
      showFormEditPassword(false);
      return true;
    } catch (err) {
      showMessage(`Nie udała się zmiana hasła. ${(err as Error).message}`);
      return false;
    }
  };

  const showFormEditPassword = (show: boolean) => {
    setOnlyView(!show);
    setActionButton(show);
    setActiveEditMode(show);
  };

  const keyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      showFormEditPassword(true);
      event.preventDefault();
    }
  };

  return (
    <>
      <EditableNode
        onlyView={onlyView}
        actionButton={actionButton}
        activeEditMode={activeEditMode}
        viewNode={
          <Form.Group
            className="form-floating col-md-11"
            controlId="passwordControl"
          >
            <Button
              variant="my-outline-opposed-btn w-100"
              onClick={() => showFormEditPassword(true)}
              onKeyPress={keyPress}
            >
              Zmień hasło
            </Button>
          </Form.Group>
        }
        editableNode={
          <>
            <Row>
              <Col className="col-5 col-md-6">
                <Form.Group
                  className="form-floating"
                  controlId="passwordControl"
                >
                  <Form.Control
                    type="password"
                    onChange={e => (password = e.target.value)}
                    ref={passwdRef}
                  />
                  <Form.Label>Hasło</Form.Label>
                </Form.Group>
              </Col>
              <Col className="col-5 col-md-5">
                <Form.Group
                  className="form-floating"
                  controlId="repeatPasswordControl"
                >
                  <Form.Control
                    type="password"
                    onChange={e => (passwordRepeat = e.target.value)}
                  />
                  <Form.Label>Powtórzone hasło</Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </>
        }
        onSave={() => onSave()}
        onClose={() => showFormEditPassword(false)}
        setFocus={setFocusPasswd}
      />
      <ValidationAlert
        heading={'Zmiana hasła'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
