import { DefaultApi } from '../autogenerated/slimbeApiClient/apis/DefaultApi';
import { GetArticleDto } from '../autogenerated/slimbeApiClient/models/GetArticleDto';
import { MediaType } from '../../../slimbe/components/PresentationCard/CardMedia';

import { readingTime } from './articlesUtils';
import { CollectionCard } from './CollectionCard';

export type ArticleEntity = GetArticleDto;

export type Article = {
  article: ArticleEntity;
  author?: string | null;
};

export interface ArticlesContextInterface {
  getArticles: (count?: number) => Promise<Article[] | undefined>;
  getAsCardCollection: (
    count?: number,
  ) => Promise<CollectionCard[] | undefined>;
  getByUserUIDAsCardCollection: (
    userUID?: string | null,
  ) => Promise<CollectionCard[] | undefined>;
  getByUserUID: (userUID?: string | null) => Promise<Article[] | undefined>;
  addArticle: (article: ArticleEntity) => Promise<GetArticleDto>;
  updateArticle: (article: ArticleEntity) => Promise<void>;
  getById: (id?: string | null) => Promise<Article | undefined>;
  delete: (id: string) => Promise<void>;
  searchCardCollection: (
    searchText: string,
    disciplineArray: string[],
    areaArray: string[],
  ) => Promise<CollectionCard[] | undefined>;
}

export const ArticlesContext = (api: DefaultApi) => {
  const getArticles = async (count?: number) => {
    const articles = await api.articleControllerGetAll(count);

    const articlesWithAdd = articles.map(async doc => {
      return {
        article: doc,
        author: doc.user?.name,
      };
    });
    return Promise.all(articlesWithAdd);
  };

  const getByUserUID = async (userUID?: string | null | undefined) => {
    if (!userUID) {
      return;
    }

    const articles = await api.articleControllerGetByUserUid(userUID);

    const articlesWithAdd = articles.map(async doc => {
      return {
        article: doc,
        author: doc.user?.name,
      };
    });
    return Promise.all(articlesWithAdd) as Promise<Article[] | undefined>;
  };

  const getAsCardCollection = (articles: Article[]) => {
    return articles.map(a => {
      return {
        id: a?.article.id || '',
        userUID: a?.article.user?.uid || '',
        title: a?.article.topic || '',
        mediaType: MediaType.Image,
        src: a?.article.image,
        avatar: a?.article.user?.image || '',
        author: a?.author || '',
        description: a?.article.shortDescription || '',
        time: a?.article.time,
        duration: a?.article.duration,
        disciplines: a?.article.disciplineArray || [],
        areas: a?.article.areaArray || [],
      };
    });
  };

  const search = async (
    searchText: string,
    disciplineArray: string[],
    areaArray: string[],
  ) => {
    const articles = await api.articleControllerSearch(
      searchText,
      disciplineArray,
      areaArray,
    );
    const foundArticles = articles.map(async doc => {
      return {
        article: doc,
        author: doc.user?.name,
      };
    });
    return Promise.all(foundArticles) as Promise<Article[] | undefined>;
  };

  return {
    getArticles: async (count?: number) => getArticles(count),

    getAsCardCollection: async (count?: number) => {
      const collection = await getArticles(count);
      return Promise.all(getAsCardCollection(collection));
    },

    getByUserUIDAsCardCollection: async (userUID?: string | null) => {
      const collection = await getByUserUID(userUID);
      return Promise.all(getAsCardCollection(collection || []));
    },

    getByUserUID: async (userUID?: string | null | undefined) =>
      getByUserUID(userUID),

    addArticle: async (article: ArticleEntity) => {
      return await api.articleControllerCreate({
        ...article,
        duration: await readingTime(article.contents || ''),
      });
    },

    updateArticle: async (article: ArticleEntity) => {
      if (!article.id) {
        return;
      }

      return api.articleControllerUpdate(article.id, {
        ...article,
        duration: await readingTime(article.contents || ''),
      });
    },

    getById: async (id: string | null | undefined) => {
      if (!id || id === undefined) {
        return;
      }
      const article = await api.articleControllerGet(id);
      if (article) {
        return {
          article: article,
          author: article.user?.name,
        } as Article;
      } else {
        console.log('No such document!');
      }
    },

    delete: async (id: string) => {
      return api.articleControllerDelete(id);
    },

    searchCardCollection: async (
      searchText: string,
      disciplineArray: string[],
      areaArray: string[],
    ) => {
      const collection = await search(searchText, disciplineArray, areaArray);
      return Promise.all(getAsCardCollection(collection || []));
    },
  };
};
