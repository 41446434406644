import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'quill-paste-smart';

import EditorToolbar, { modules, formats } from './EditorToolbar';

export default (props: {
  value: string;
  readonly?: boolean;
  className?: string;
  onChange: (value: string) => void;
  focus?: boolean;
}) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const nodeRef = useRef<ReactQuill>(null);

  useEffect(() => {
    nodeRef.current?.focus();
  }, [props.focus]);

  useEffect(() => {
    const threeSeconds = 3 * 1000;
    const interval = setInterval(() => {
      if (value !== props.value) {
        props.onChange(value);
      }
    }, threeSeconds);

    return () => {
      clearInterval(interval);
    };
  }, [props, value]);

  return (
    <div
      onBlur={() => {
        if (value !== props.value) {
          props.onChange(value);
        }
      }}
    >
      <EditorToolbar />
      <ReactQuill
        className={props.className}
        theme="snow"
        value={value}
        readOnly={props.readonly}
        onChange={value => setValue(value)}
        ref={nodeRef}
        placeholder={'Napisz coś ...'}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};
