import React, { useState } from 'react';

import { useNavigation } from '../../../common/navigation';
import ValidationAlert from '../ValidationAlert';

import { ResetPassword } from './ResetPassword';

export type Props = {
  onResetPassword: (email: string) => void;
};

export const ResetPasswordDialog: React.FC<Props> = ({ onResetPassword }) => {
  const nav = useNavigation();
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleResetPassword = async (email: string) => {
    try {
      await onResetPassword(email);
      nav.login();
    } catch (err) {
      setErrorText('Błąd podczas resetu hasła: ' + (err as Error).message);
      setShowError(true);
    }
  };

  return (
    <>
      <ValidationAlert
        heading={'RESET HASŁA'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />

      <ResetPassword onResetPassword={handleResetPassword} />
    </>
  );
};
