import React, { useRef } from 'react';
import { Row, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import ConfirmationButton from '../../../app/components/ConfirmationButton';

import { ParamModeProps } from './EditStringNodeProps';

interface ActionFormButtonsProps {
  objectName?: string;
  mode: ParamModeProps;
  setViewMode: (st: boolean) => void;
  onAdd?: () => void;
  onGiveUp?: () => void;
  onDelete?: () => void;
}

export const ActionFormButtons: React.FC<ActionFormButtonsProps> = ({
  objectName,
  mode,
  setViewMode,
  onAdd,
  onDelete,
  onGiveUp,
}) => {
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleOnAdd = () => {
    if (btnRef.current) {
      btnRef.current.setAttribute('disabled', 'disabled');
      onAdd && onAdd();
    }
  };

  return (
    <>
      <Row className="mb-3">
        <div className="d-flex justify-content-end">
          {mode.editable && !mode.viewMode && (
            <Button
              variant="my-outline-btn me-2"
              onClick={() => setViewMode(true)}
            >
              <Icon.EyeFill /> Podgląd
            </Button>
          )}
          {mode.viewMode && (
            <Button
              variant={'my-outline-btn ' + (mode.newRecord ? ' me-2' : '')}
              onClick={() => setViewMode(false)}
            >
              <Icon.PencilFill /> Tryb edycji
            </Button>
          )}
          {mode.newRecord && (
            <>
              <Button variant="my-outline-btn me-2" onClick={onGiveUp}>
                Zrezygnuj
              </Button>
              <Button
                ref={btnRef}
                variant="my-outline-opposed-btn"
                onClick={handleOnAdd}
              >
                Zapisz {objectName}
              </Button>
            </>
          )}
          {!mode.newRecord && mode.editable && onDelete && (
            <ConfirmationButton
              variant="my-outline-btn"
              onOK={onDelete}
              confirmation={'Czy na pewno usunąć ' + objectName + '?'}
            >
              Usuń {objectName}
            </ConfirmationButton>
          )}
        </div>
      </Row>
    </>
  );
};
