import React from 'react';
import { Container, Image, Row, Col } from 'react-bootstrap';

import politykaPDF from '../../assets/SLIMBE_POLITYKA.pdf';
import regulaminPDF from '../../assets/SLIMBE_REGULAMIN.pdf';

export const Footer: React.FC = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="col-12 col-md-6 col-lg-3 align-self-center mb-1">
            <div className="d-flex justify-content-center">
              <a className="text-decoration-none" href="/" aria-label="Slimbe">
                <Image
                  className="img-logo-slimbe footer-logo"
                  alt="Logo Slimbe"
                />
              </a>
            </div>
            <div className="mt-1 d-flex justify-content-center nowrap">
              Ⓒ Marcin Jackowski
            </div>
          </Col>
          <Col className="col-12 col-md-6 col-lg-2 align-self-center mb-1 contact">
            <div className="text-center nowrap">
              Telefon:
              <a
                className="text-decoration-none ms-1"
                href="tel:+48601083527"
                target="_blank"
                rel="noopener noreferrer"
              >
                +48 601 083 527
              </a>
            </div>
            <div className="text-center nowrap">
              Email:
              <a
                className="text-decoration-none ms-1"
                href="mailto:kontakt@slimbe.pl"
                target="_blank"
                rel="noopener noreferrer"
              >
                kontakt@slimbe.pl
              </a>
            </div>
          </Col>
          <Col className="col-12 col-md-6 col-lg-2 align-self-center mb-1">
            <div className="d-flex justify-content-center nowrap">
              <a
                className="text-decoration-none"
                href={politykaPDF}
                target="_blank"
                rel="license noreferrer"
              >
                Polityka prywatności
              </a>
            </div>
          </Col>
          <Col className="col-12 col-md-6 col-lg-2 align-self-center mb-1">
            <div className="d-flex justify-content-center nowrap">
              <a
                className="text-decoration-none"
                href={regulaminPDF}
                target="_blank"
                rel="license noreferrer"
              >
                Regulamin
              </a>
            </div>
          </Col>
          <Col className="col-12 col-md-12 col-lg-3 align-self-center mb-1">
            <div className="d-flex justify-content-center nowrap">
              <a
                className="text-decoration-none"
                href="https://codifive.pl"
                target="_blank"
                rel="external noreferrer"
              >
                Powered by CodiFive Sp. z o. o.
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
