import React, { useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { EditableFormNode } from '../EditableCollection/EditableNode';
import { CardMedia, MediaType } from '../PresentationCard/CardMedia';

import { EditStringNodeProps } from './EditStringNodeProps';

export interface RowMediaProps extends EditStringNodeProps {
  title: string;
}

export const RowMedia: React.FC<RowMediaProps> = ({
  title,
  value,
  mode,
  onSave,
}) => {
  const [srcWorkout, setSrcWorkout] = useState<string>(value);

  const linkRef = useRef<HTMLInputElement>(null);
  const setFocusLink = () => {
    linkRef.current?.focus();
  };
  const parseVideoEmbedUrl = (url: string) => {
    const match =
      /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/.exec(
        url,
      );

    if (match) {
      if (match[3].indexOf('youtu') > -1) {
        return 'https://www.youtube-nocookie.com/embed/' + match[6];
      } else if (match[3].indexOf('vimeo') > -1) {
        return 'https://player.vimeo.com/video/' + match[6];
      }
    }
    return '';
  };

  const srcWorkoutChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const url = parseVideoEmbedUrl(event.target.value);
    setSrcWorkout(url);
    if (mode.newRecord) {
      await onSave(url);
    }
  };

  const srcWorkoutHandleSave = () => {
    return onSave(srcWorkout);
  };

  const srcWorkoutChangeOnClose = () => {
    setSrcWorkout(value);
  };

  return (
    <Row>
      <Col className="col-md-12 col-12">
        <CardMedia
          title={title || ''}
          mediaType={MediaType.Video}
          src={srcWorkout}
        />
      </Col>
      {mode.editable && (
        <Col className="col-md-12 col-12 align-self-start gy-3">
          <EditableFormNode
            mode={mode}
            viewNode={
              <Form.Group className="form-floating col-md-12">
                <Form.Control
                  type="text"
                  placeholder="Link do filmu"
                  readOnly={true}
                  value={value}
                />
                <Form.Label>Link do filmu</Form.Label>
              </Form.Group>
            }
            editableNode={
              <Form.Group
                className={
                  mode.newRecord
                    ? 'form-floating col-12'
                    : 'form-floating col-9 col-sm-9 col-md-11 pe-md-5'
                }
                controlId="linkControl"
              >
                <Form.Control
                  type="text"
                  placeholder="Link do filmu"
                  defaultValue={srcWorkout}
                  onChange={srcWorkoutChange}
                  ref={linkRef}
                />
                <Form.Label>Link do filmu</Form.Label>
              </Form.Group>
            }
            onSave={srcWorkoutHandleSave}
            onClose={srcWorkoutChangeOnClose}
            setFocus={setFocusLink}
          />
        </Col>
      )}
    </Row>
  );
};
