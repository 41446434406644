import React from 'react';

import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../common/navigation';
import { CollectionWall } from '../components/Collections/CollectionWall';

export const CoachesView: React.FC = () => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();

  return (
    <>
      <CollectionWall
        smallCard={true}
        editable={false}
        objectName="trener"
        whatCollection="trenerów"
        getCardCollecion={slimbeApi.profiles.getAsCardCollection}
        navCardView={nav.profileView}
      />
    </>
  );
};
