import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import { EditableFormNode } from '../EditableCollection/EditableNode';

import { EditStringNodeProps } from './EditStringNodeProps';

export const ShortDescriptionNode: React.FC<EditStringNodeProps> = ({
  value,
  mode,
  onSave,
}) => {
  const [description, setDescription] = useState<string>(value);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setDescription(value);
  }, [value]);

  const setFocusDescription = () => {
    descriptionRef.current?.focus();
  };
  const onChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const txt = e.target.value;
    setDescription(txt);
    if (mode.newRecord) {
      await onSave(txt);
    }
  };
  return (
    <EditableFormNode
      mode={mode}
      viewNode={<p className="short-description">{description}</p>}
      editableNode={
        <Form.Group
          className={
            mode.newRecord ? 'col-12' : 'col-9 col-sm-9 col-md-11 pe-md-5'
          }
          controlId="description"
        >
          <Form.Control
            as="textarea"
            maxLength={250}
            rows={3}
            placeholder="Wprowadź krótki opis"
            defaultValue={description}
            onChange={onChange}
            ref={descriptionRef}
          />
        </Form.Group>
      }
      onSave={() => onSave(description)}
      onClose={() => setDescription(value)}
      setFocus={setFocusDescription}
      defaultPressEnter={true}
    />
  );
};
