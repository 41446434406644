import React, { useEffect, useState } from 'react';
import { Badge, Collapse } from 'react-bootstrap';

import { TagEntry } from '../../../common/slimbeCore/contexts/DisciplinesContext';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { getTagsFromStringArray } from '../../../common/slimbeCore/contexts/tagsUtils';

export interface BadgeProps {
  stringArray: string[];
  limit?: number;
}

export const DisciplineBadges: React.FC<BadgeProps> = ({
  stringArray,
  limit,
}) => {
  const slimbeApi = useSlimbeCore();
  return (
    <TagsBadge
      stringArray={stringArray}
      getEntryTag={slimbeApi.disciplinesEntry}
      bg="secondary"
      limit={limit}
    />
  );
};

export const MainAreaBadges: React.FC<BadgeProps> = ({
  stringArray,
  limit,
}) => {
  const slimbeApi = useSlimbeCore();
  return (
    <TagsBadge
      stringArray={stringArray}
      getEntryTag={slimbeApi.areasEntry}
      filter={o => !o.idParent}
      bg="primary"
      limit={limit}
    />
  );
};

export const AreaBadges: React.FC<BadgeProps> = ({ stringArray, limit }) => {
  const slimbeApi = useSlimbeCore();
  return (
    <TagsBadge
      stringArray={stringArray}
      getEntryTag={slimbeApi.areasEntry}
      filter={o => {
        if (o.idParent) {
          return true;
        } else {
          return false;
        }
      }}
      bg="secondary"
      limit={limit}
    />
  );
};

interface TagsBadgeProps {
  stringArray: string[];
  getEntryTag: Promise<TagEntry[]>;
  filter?: (tag: TagEntry) => boolean;
  bg: string;
  limit?: number;
}

const TagsBadge: React.FC<TagsBadgeProps> = ({
  stringArray,
  getEntryTag,
  filter,
  bg,
  limit,
}) => {
  const [tags, setTags] = useState<TagEntry[]>([]);

  useEffect(() => {
    let mounted = true;
    getEntryTag
      .then(entry => {
        let tg = getTagsFromStringArray(entry, stringArray);
        if (mounted) {
          tg = filter ? tg.filter(filter) : tg;
          setTags(tg);
        }
      })
      .catch(err => console.log('Nie udało pobrać się tagów', err));
    return () => {
      mounted = false;
    };
  }, [filter, getEntryTag, stringArray]);

  const [open, setOpen] = useState(false);

  const onKeypress = (event: React.KeyboardEvent) => {
    if (event.code === 'Space') {
      setOpen(!open);
      event.preventDefault();
    }
  };

  return (
    <>
      {tags.length > 0 && (
        <div
          onClick={() => setOpen(!open)}
          onKeyPress={onKeypress}
          aria-controls="collapse-badge"
          aria-expanded={open}
          role="button"
          tabIndex={limit && tags.length > limit ? 0 : -1}
        >
          {tags.slice(0, limit).map(o => (
            <Badge className="ms-0 me-1" bg={bg} key={o.value}>
              {o.label}
            </Badge>
          ))}
          {limit && tags.length > limit && (
            <>
              {!open && (
                <Badge className="ms-0 me-1" bg={bg} key={'...'}>
                  ...
                </Badge>
              )}
              <Collapse in={open}>
                <div id="collapse-badge">
                  {tags.slice(limit).map(o => (
                    <Badge className="ms-0 me-1" bg={bg} key={o.value}>
                      {o.label}
                    </Badge>
                  ))}
                </div>
              </Collapse>
            </>
          )}
        </div>
      )}
    </>
  );
};
