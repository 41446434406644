import React, { SyntheticEvent, useRef } from 'react';
import { Image, Button, Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { imageResizer } from '../../utils/imageResizer';
import { ImageWithMagnifier } from '../Images/ImageWithMagnifier';

interface ImageNodeProps {
  image: string;
  title: string;
  editable: boolean;
  anyImg: boolean;
  onSave: (img: string) => void;
}

export const ImageNode: React.FC<ImageNodeProps> = ({
  image,
  title,
  editable,
  anyImg,
  onSave,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClickImage = (event: SyntheticEvent) => {
    if (editable) {
      event.preventDefault();
      hiddenFileInput?.current?.click();
    }
  };

  const setFile = (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    const file = fileList[0];

    imageResizer(file)
      .then(result => {
        onSave && onSave(result as string);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      <fieldset hidden>
        <input
          type="file"
          accept="image/*"
          ref={hiddenFileInput}
          onChange={e => setFile((e.target as HTMLInputElement).files)}
        />
      </fieldset>
      <Row>
        <Col className="col-md-12 col-12">
          <div
            className={image && anyImg ? 'my-any-media-img' : 'my-media-img'}
          >
            {image ? (
              editable ? (
                <Image
                  className="rounded mx-auto d-block"
                  alt={title}
                  src={image}
                  onClick={handleClickImage}
                />
              ) : (
                <ImageWithMagnifier
                  alt={title}
                  src={image}
                  clickableZoom={true}
                />
              )
            ) : (
              <div className="my-media-default">
                {anyImg ? (
                  <Icon.Image size={200} onClick={handleClickImage} />
                ) : (
                  <>
                    <Icon.BookHalf size={150} onClick={handleClickImage} />
                    <Icon.Activity size={80} />
                  </>
                )}
              </div>
            )}
          </div>
        </Col>
        <Col className="col-12 col-12 align-self-start gy-3">
          {editable && (
            <Button
              className="btn btn-lg btn-register w-100"
              onClick={handleClickImage}
            >
              {!image ? 'Wybierz zdjęcie' : 'Zmień zdjęcie'}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};
