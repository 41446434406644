/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useAuth } from '../../../common/hooks/useAuth';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../../common/navigation';
import {
  Workout,
  WorkoutEntity,
} from '../../../common/slimbeCore/contexts/WorkoutsContext';
import { AreaImage } from '../Tags/TagsImgs';
import { DisciplinesSelector } from '../Tags/DisciplinesSelector';
import { AreasSelector } from '../Tags/AreasSelector';
import { DescriptionNode } from '../FormControl/DescriptionNode';
import { TitleNode } from '../FormControl/TitleNode';
import { RowMedia } from '../FormControl/RowMedia';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { AuthorNode } from '../FormControl/AuthorNode';
import { ActionFormButtons } from '../FormControl/ActionFormButtons';
import { Like } from '../Like/Like';
import { ShortDescriptionNode } from '../FormControl/ShortDescriptionNode';
interface WorkoutProps {
  workout?: Workout;
  editMode: boolean;
}

// eslint-disable-next-line complexity
export const WorkoutForm: React.FC<WorkoutProps> = ({ workout, editMode }) => {
  const nav = useNavigation();
  const auth = useAuth();
  const slimbeApi = useSlimbeCore();

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [editable, setEditable] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [newWorkout, setNewWorkout] = useState<boolean>(false);
  const [mainAreaTags, setMainAreaTags] = useState<string[]>([]);

  const [workoutU, setWorkoutU] = useState<WorkoutEntity>(
    workout?.workout || {
      topic: 'Tytuł treningu...',
      description: '<h1>Opis treningu</h1>Opis...',
    },
  );

  useEffect(() => {
    if (workout) {
      const userUID = '' + auth?.currentUser?.uid;
      const isAdmin = auth?.userProfile?.isAdmin || false;
      const owner = userUID === workout.workout.user?.uid;
      setEditable(owner && editMode);
      setViewMode((!editMode && owner) || (isAdmin && !owner));
      setMainAreaTags(workout.workout.areaArray || []);
    } else {
      setEditable(true);
      setNewWorkout(true);
    }
  }, [
    auth?.currentUser,
    auth?.userProfile,
    slimbeApi.profiles,
    workout,
    editMode,
  ]);

  const handleSave = async (obj: Partial<WorkoutEntity>) => {
    try {
      setWorkoutU({ ...workoutU, ...obj });
      if (workoutU.id) {
        await slimbeApi.workouts.updateWorkout({ ...workoutU, ...obj });
      }
      return true;
    } catch (err) {
      setErrorText(`Zapis treningu jest niemożliwy. ${(err as Error).message}`);
      setShowError(true);
      return false;
    }
  };

  const changeViewMode = (active: boolean) => {
    setViewMode(active);
    setEditable(!active);
  };
  const handleAdd = async () => {
    await slimbeApi.workouts.addWorkout(workoutU, auth.currentUser);
    nav.myWorkouts();
  };

  const handleDelete = async () => {
    if (workoutU.id) {
      await slimbeApi.workouts.delete(workoutU.id);
    }
    nav.myWorkouts();
  };

  const paramMode: ParamModeProps = {
    newRecord: newWorkout,
    viewMode: viewMode,
    editable: editable,
  };
  return (
    <>
      <Container className="p-3 my-form-view">
        <ActionFormButtons
          objectName={'trening'}
          mode={paramMode}
          onAdd={handleAdd}
          onDelete={handleDelete}
          setViewMode={changeViewMode}
          onGiveUp={nav.myWorkouts}
        />
        <Row className="gy-3 mb-3">
          <Col className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
            <Container className="ps-0 pe-0">
              <RowMedia
                title={workoutU?.topic || ''}
                value={workoutU?.link || ''}
                mode={paramMode}
                onSave={txt => handleSave({ link: txt })}
              />
              <Row className="pt-3 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                <Col className="col-md-12 col-12">
                  <ShortDescriptionNode
                    value={workoutU?.shortDescription || ''}
                    mode={paramMode}
                    onSave={txt => handleSave({ shortDescription: txt })}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col className="col-lg-6 col-md-6 d-flex justify-content-center align-self-center">
            <Row className="gy-3 w-100">
              <Col className="col-md-12 col-sm-12 col-12 d-flex justify-content-end p-0">
                <Like object={workoutU} />
              </Col>
              <Col className="col-md-12 p-0">
                <TitleNode
                  value={workoutU?.topic || ''}
                  mode={paramMode}
                  onSave={txt => handleSave({ topic: txt })}
                />
              </Col>
              <Col className="col-md-12 col-sm-12 col-12 p-0 mt-1">
                <AuthorNode
                  mode={paramMode}
                  author={workout?.author || ''}
                  avatar={workout?.workout.user?.image || ''}
                  userId={workout?.workout.user?.id || ''}
                />
              </Col>
              <Col className="col-md-12 col-12 d-block d-md-none d-lg-none d-xl-none d-xxl-none p-0">
                <ShortDescriptionNode
                  value={workoutU?.shortDescription || ''}
                  mode={paramMode}
                  onSave={txt => handleSave({ shortDescription: txt })}
                />
              </Col>
              <Col className="col-md-12 col-sm-12 col-12 p-0 align-self-center">
                <DisciplinesSelector
                  stringArray={workoutU.disciplineArray || []}
                  mode={paramMode}
                  onSave={disc => handleSave({ disciplineArray: disc })}
                />
              </Col>
              <Col className="col-md-5 col-sm-6 col-12 p-0">
                <AreaImage stringArray={mainAreaTags} />
              </Col>
              <Col className="col-md-7 col-sm-6 col-12 p-0 ">
                <AreasSelector
                  stringArray={workoutU.areaArray || []}
                  mode={paramMode}
                  onSave={area => handleSave({ areaArray: area })}
                  setSelectedTags={tags => setMainAreaTags(tags)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="gy-3 pb-3">
          <Col className="col-md-12 hr pt-2">
            <DescriptionNode
              value={workoutU?.description || ''}
              mode={paramMode}
              onSave={desc => handleSave({ description: desc })}
            />
          </Col>
        </Row>
        <ActionFormButtons
          objectName={'trening'}
          mode={paramMode}
          onAdd={handleAdd}
          onDelete={handleDelete}
          setViewMode={changeViewMode}
          onGiveUp={nav.myWorkouts}
        />
      </Container>
      <ValidationAlert
        heading={'Zapis treningu'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
