import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { Slogan } from '../../../common/slimbeCore/contexts/SlogansContext';
import { SloganType } from '../../../common/slimbeCore/validation/schemas';
import { EditableFormNode } from '../EditableCollection/EditableNode';

import { EditStringNodeProps, ParamModeProps } from './EditStringNodeProps';

export interface SloganNodeProps {
  sloganType: SloganType;
  title?: boolean;
  mode: ParamModeProps;
}

export const SloganNode: React.FC<SloganNodeProps> = ({
  sloganType,
  title,
  mode,
}) => {
  const slimbeApi = useSlimbeCore();
  const [slogan, setSlogan] = useState<Slogan | undefined>();
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    let mounted = true;

    slimbeApi.slogans
      .getOneByType(sloganType)
      .then(slg => {
        if (mounted) {
          setSlogan(slg);
        }
      })
      .catch(err => console.log('Nie udało pobrać się sloganów', err));

    return () => {
      mounted = false;
    };
  }, [slimbeApi.slogans, sloganType]);

  const handleSave = async (txt: string) => {
    try {
      if (slogan) {
        slogan.text = txt;
        await slimbeApi.slogans.update(slogan);
      }
      return true;
    } catch (err) {
      setErrorText(`Zapis sloganu jest niemożliwy. ${(err as Error).message}`);
      setShowError(true);
      return false;
    }
  };
  return (
    <>
      {slogan && (
        <SloganEditNode
          mode={mode}
          value={slogan?.text || ''}
          onSave={handleSave}
          title={title}
        />
      )}
      <ValidationAlert
        heading={'Zapis sloganu'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};

interface SloganEditNodeProps extends EditStringNodeProps {
  title?: boolean;
}

const SloganEditNode: React.FC<SloganEditNodeProps> = ({
  title,
  value,
  mode,
  onSave,
}) => {
  const [slogan, setSlogan] = useState<string>(value);
  const sloganRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSlogan(value);
  }, [value]);

  const setFocusSlogan = () => {
    sloganRef.current?.focus();
  };
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const txt = e.target.value;
    setSlogan(txt);
    if (mode.newRecord) {
      await onSave(txt);
    }
  };
  return (
    <EditableFormNode
      narrow={!title}
      mode={mode}
      viewNode={title ? <h1>{slogan}</h1> : <p>{slogan}</p>}
      editableNode={
        <div className="form-name">
          <Form.Group className="form-floating" controlId="topic">
            <Form.Control
              type="text"
              defaultValue={slogan}
              onChange={onChange}
              ref={sloganRef}
            />
            <Form.Label>{title ? 'Tytuł' : 'Slogan'}</Form.Label>
          </Form.Group>
        </div>
      }
      onSave={() => onSave(slogan)}
      onClose={() => setSlogan(value)}
      setFocus={setFocusSlogan}
    />
  );
};
