import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  // eslint-disable-next-line import/named
  UserCredential,
  updateProfile,
} from 'firebase/auth';

import { AuthProvider } from '../../auth';

import {
  errorChangePasswordEmailMessage,
  errorLoginMessage,
  resetPasswordErrorMessage,
} from './firestoreErrorUtils';

export interface SignInContextInterface {
  signIn: (
    username: string,
    password: string,
  ) => Promise<UserCredential | void>;
  signInWithGoogle: () => Promise<UserCredential | void>;
  signInWithFacebook: () => Promise<UserCredential | void>;
  resetPassword: (email: string) => Promise<void>;
  updateEmail: (email: string) => Promise<void>;
  updatePassword: (passwd: string) => Promise<void>;
  addUserWithEmailAndPassword: (
    email: string | undefined | null,
    password: string,
    name: string | undefined | null,
  ) => Promise<UserCredential | undefined>;
}

export const SignInContext = () => {
  return {
    signIn: async (username: string, password: string) => {
      return await AuthProvider.signInWithEmailAndPassword(
        username,
        password,
      ).catch(error => {
        errorLoginMessage(error);
      });
    },

    signInWithGoogle: async () => {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      return signInWithPopup(auth, provider).catch(error => {
        errorLoginMessage(error);
      });
    },

    signInWithFacebook: async () => {
      const provider = new FacebookAuthProvider();
      const auth = getAuth();
      return signInWithPopup(auth, provider).catch(error => {
        errorLoginMessage(error);
      });
    },

    resetPassword: async (email: string) => {
      return AuthProvider.resetPassword(email).catch(error => {
        resetPasswordErrorMessage(error);
      });
    },

    updateEmail: async (email: string) => {
      if (email) {
        return AuthProvider.updateEmail(email)?.catch(error => {
          errorChangePasswordEmailMessage(error);
        });
      }
    },

    updatePassword: async (passwd: string) => {
      if (passwd) {
        return AuthProvider.updatePassword(passwd)?.catch(error => {
          errorChangePasswordEmailMessage(error);
        });
      }
    },

    addUserWithEmailAndPassword: async (
      email: string | undefined | null,
      password: string,
      name: string | undefined | null,
    ) => {
      if (!email || !password) {
        return;
      }

      try {
        const userC = await AuthProvider.createUserWithEmailAndPassword(
          email,
          password,
        );
        await updateProfile(userC.user, {
          displayName: name || email,
        });
        return userC;
      } catch (err) {
        errorLoginMessage(err);
      }
    },
  };
};
