import React, { SyntheticEvent, useRef, useState } from 'react';
import { Image, Modal, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { Profile } from '../../../common/slimbeCore/contexts/ProfilesContext';
import { imageResizer } from '../../utils/imageResizer';

export const ProfilePhoto: React.FC<{
  profile: Profile;
  onSave?: (obj?: Partial<Profile>) => void;
  editable: boolean;
}> = ({ profile, onSave, editable }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [modalShow, setModalShow] = useState(false);

  const setFile = async (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }

    const file = fileList[0];

    imageResizer(file)
      .then(result => {
        if (!profile.uid) {
          console.log('Brak profilu');
        } else {
          onSave && onSave({ image: result as string });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickImage = (event: SyntheticEvent) => {
    if (editable) {
      event.preventDefault();
      hiddenFileInput?.current?.click();
    } else {
      setModalShow(!modalShow);
    }
  };

  const keyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleClickImage(event);
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="edit-field">
        <Stack gap={4} className="mx-auto p-2">
          <fieldset hidden>
            <input
              type="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={e => setFile((e.target as HTMLInputElement).files)}
            />
          </fieldset>
          <Image
            className="mb-3 my-profile-avatar"
            alt={'Avatar trenera:' + profile.name || ''}
            src={profile.image || ''}
            onClick={handleClickImage}
            onKeyPress={event => keyPress(event)}
            tabIndex={editable ? -1 : 0}
          />
        </Stack>
        {editable && (
          <OverlayTrigger placement="top" overlay={<Tooltip>Edytuj</Tooltip>}>
            <Icon.PencilFill
              tabIndex={0}
              className="function-icon icon"
              onClick={handleClickImage}
              onKeyPress={event => keyPress(event)}
            />
          </OverlayTrigger>
        )}
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="image-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {profile.name || ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image
            alt={'Avatar trenera:' + profile.name || ''}
            src={profile.image || ''}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
