/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateArticleDto
 */
export interface CreateUpdateArticleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    contents?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    image?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateArticleDto
     */
    time?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    userUID?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateArticleDto
     */
    areaArray?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateArticleDto
     */
    disciplineArray?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    duration?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateArticleDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateArticleDto
     */
    shortDescription?: string | null;
}

export function CreateUpdateArticleDtoFromJSON(json: any): CreateUpdateArticleDto {
    return CreateUpdateArticleDtoFromJSONTyped(json, false);
}

export function CreateUpdateArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateArticleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'contents': !exists(json, 'contents') ? undefined : json['contents'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'userUID': !exists(json, 'userUID') ? undefined : json['userUID'],
        'areaArray': !exists(json, 'areaArray') ? undefined : json['areaArray'],
        'disciplineArray': !exists(json, 'disciplineArray') ? undefined : json['disciplineArray'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
    };
}

export function CreateUpdateArticleDtoToJSON(value?: CreateUpdateArticleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': value.topic,
        'contents': value.contents,
        'image': value.image,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'userUID': value.userUID,
        'areaArray': value.areaArray,
        'disciplineArray': value.disciplineArray,
        'duration': value.duration,
        'active': value.active,
        'shortDescription': value.shortDescription,
    };
}


