import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import 'react-multi-carousel/lib/styles.css';
import { CollectionCard } from '../../../common/slimbeCore/contexts/CollectionCard';
import { SloganType } from '../../../common/slimbeCore/validation/schemas';
import { CarouselCollection } from '../Collections/CarouselCollection';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { SloganNode } from '../FormControl/SloganNode';

export interface LandingCarouselNodeProps {
  sloganTitleType: SloganType;
  sloganType: SloganType;
  mode: ParamModeProps;
  smallCard: boolean;
  maxCount: number;
  whatCollection: string;
  navMoreCardCollection: () => void;
  getCardCollecion: (
    count?: number | undefined,
  ) => Promise<CollectionCard[] | undefined>;
  navCardView: (id: string | undefined, editMode?: boolean) => void;
}

export const LandingCarouselNode: React.FC<LandingCarouselNodeProps> = ({
  sloganTitleType,
  sloganType,
  mode,
  smallCard,
  maxCount,
  whatCollection,
  getCardCollecion,
  navMoreCardCollection,
  navCardView,
}) => {
  const [collection, setCollection] = useState<CollectionCard[] | undefined>(
    [],
  );

  const [ready, setReady] = useState(false);

  useEffect(() => {
    let mounted = true;

    getCardCollecion(maxCount)
      ?.then(coll => {
        if (mounted) {
          setCollection(coll);
          setReady(true);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem kolekcji kart. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [getCardCollecion, maxCount]);

  return (
    <div className="my-main-section p-5 pb-0 m-3 mb-1">
      <div className="lead">
        <SloganNode sloganType={sloganTitleType} mode={mode} title={true} />
        <SloganNode sloganType={sloganType} mode={mode} />
      </div>
      {ready ? (
        collection && collection?.length > 0 ? (
          <CarouselCollection
            smallCard={smallCard}
            collection={collection}
            onClickMore={navMoreCardCollection}
            actionOnEdit={id => () => navCardView(id, true)}
            onSelect={id => () => navCardView(id)}
          />
        ) : (
          <h3 className="text-center pt-3">Brak {whatCollection} :-(</h3>
        )
      ) : (
        <h3 className="text-center pt-3">
          <Spinner animation="grow" role="status">
            <span className="visually-hidden">
              Trwa ładowanie {whatCollection}...
            </span>
          </Spinner>{' '}
          Trwa ładowanie {whatCollection}...
        </h3>
      )}
    </div>
  );
};
