import React, { useState } from 'react';

import { EditableFormNode } from '../EditableCollection/EditableNode';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';

import { DisciplinesSelect } from './DisciplinesSelect';
import { DisciplineBadges } from './TagsBadges';

interface DisciplinesSelectorProps {
  stringArray: string[];
  mode: ParamModeProps;
  onSave: (stringArray: string[]) => Promise<boolean>;
}

export const DisciplinesSelector: React.FC<DisciplinesSelectorProps> = ({
  stringArray,
  mode,
  onSave,
}) => {
  const [disciplineArray, setDisciplineArray] = useState<string[]>(stringArray);

  const [disciplineSelectFocus, setDisciplineSelectFocus] =
    useState<boolean>(true);

  const onChange = async (discipline: string[]) => {
    setDisciplineArray(discipline);
    if (mode.newRecord) {
      await onSave(discipline);
    }
  };

  const handleSave = () => {
    setDisciplineArray(disciplineArray);
    return onSave(disciplineArray);
  };
  return (
    <EditableFormNode
      mode={mode}
      viewNode={
        <div className="select-control">
          <span className="form-label">Dyscypliny</span>
          <div className="">
            <DisciplineBadges stringArray={disciplineArray} />
          </div>
        </div>
      }
      editableNode={
        <div className="select-control">
          <span className="form-label">Dyscypliny</span>
          <div className="pt-3">
            <DisciplinesSelect
              disciplineArray={disciplineArray}
              propertyChange={onChange}
              focus={disciplineSelectFocus}
            />
          </div>
        </div>
      }
      setFocus={() => setDisciplineSelectFocus(true)}
      onClose={() => setDisciplineArray(stringArray)}
      onSave={handleSave}
    />
  );
};
