// eslint-disable-next-line import/named
import Select, { MultiValue, components } from 'react-select';
import React from 'react';

import { TagEntry } from '../../../common/slimbeCore/contexts/DisciplinesContext';

import { TagsImg } from './TagsImg';

interface TagsSelectProps {
  label?: string;
  tags: TagEntry[];
  selected: TagEntry[];
  onChange: (options: MultiValue<TagEntry>) => void;
  showTags?: boolean;
  focus?: boolean;
  withCheckbox: boolean;
}

export const TagsSelect: React.FC<TagsSelectProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Option = (propsa: any) => {
    return (
      <div>
        <components.Option {...propsa}>
          <input
            type="checkbox"
            checked={propsa.isSelected}
            onChange={() => null}
          />{' '}
          <label>{propsa.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <>
      {props.label && <span className="form-label">{props.label}</span>}
      <Select
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={props.focus}
        isMulti
        closeMenuOnSelect={props.withCheckbox ? false : true}
        hideSelectedOptions={props.withCheckbox ? false : true}
        components={
          props.withCheckbox
            ? {
                Option,
              }
            : {}
        }
        className="tags-select-container"
        classNamePrefix="tags-select"
        name="tags"
        options={props.tags}
        placeholder="Wskaż..."
        value={props.selected}
        onChange={props.onChange}
        formatOptionLabel={tag => (
          <div>
            {props.showTags && <TagsImg image={tag.image} label={tag.label} />}
            <span>{tag.label}</span>
          </div>
        )}
      />
    </>
  );
};
