import React from 'react';
import { Button } from 'react-bootstrap';

export type Props = {
  signInWithGoogle: () => void;
  signInWithFacebook: () => void;
};

export const SignInWithGoogleFb: React.FC<Props> = ({
  signInWithGoogle,
  signInWithFacebook,
}) => {
  const handleGoogleSignIn = async () => {
    await signInWithGoogle();
  };

  const handleFacebookSignIn = async () => {
    await signInWithFacebook();
  };

  return (
    <>
      <Button
        className="w-100 btn btn-lg btn-google mb-4"
        onClick={handleGoogleSignIn}
      >
        Zaloguj przez Google
      </Button>
      <Button
        className="w-100 btn btn-lg btn-facebook mb-5"
        onClick={handleFacebookSignIn}
      >
        Zaloguj przez Facebook
      </Button>
    </>
  );
};
