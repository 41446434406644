import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { PresentationCard } from '../PresentationCard/PresentationCard';
import momencik from '../../../common/momencik';

import { RowCardCollectionProps } from './RowCardCollectionProps';

export const RowCardCollection: React.FC<RowCardCollectionProps> = ({
  collection,
  actionOnEdit,
  onSelect,
}) => {
  const count = collection ? collection?.length : 0;
  return (
    <Row
      xs={1}
      md={count < 2 ? count : 2}
      lg={count < 4 ? count : 4}
      xl={count < 4 ? count : 4}
      className="g-4"
    >
      {Object.keys(collection).map((key, idx) => (
        <Col key={idx}>
          <PresentationCard
            key={idx}
            userUID={collection[idx].userUID}
            actionOnEdit={actionOnEdit(collection[idx].id)}
            title={collection[idx].title}
            mediaType={collection[idx].mediaType}
            src={collection[idx].src}
            author={collection[idx].author}
            avatar={collection[idx].avatar}
            description={collection[idx].description}
            time={momencik(collection[idx].time)}
            onSelect={onSelect(collection[idx].id)}
            areas={collection[idx].areas}
            disciplines={collection[idx].disciplines}
            duration={collection[idx].duration}
            clickActive={true}
          />
        </Col>
      ))}
    </Row>
  );
};
