import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useAuth } from '../../../common/hooks/useAuth';
import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { TitleNode } from '../FormControl/TitleNode';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { AuthorNode } from '../FormControl/AuthorNode';
import { ActionFormButtons } from '../FormControl/ActionFormButtons';
import { useNavigation } from '../../../common/navigation';
import { ShortDescriptionNode } from '../FormControl/ShortDescriptionNode';
import { ImageNode } from '../FormControl/ImageNode';
import { Photo } from '../../../common/slimbeCore/contexts/PhotosContext';

interface PhotoProps {
  photo?: Photo;
  editMode: boolean;
}

export const PhotoForm: React.FC<PhotoProps> = ({ photo, editMode }) => {
  const nav = useNavigation();
  const auth = useAuth();
  const slimbeApi = useSlimbeCore();

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [editable, setEditable] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);

  const [newPhoto, setNewPhoto] = useState<Photo>(
    photo || { topic: 'Tytuł zdjęcia...' },
  );
  useEffect(() => {
    if (photo) {
      const userUID = '' + auth?.currentUser?.uid;
      const isAdmin = auth?.userProfile?.isAdmin || false;
      const owner = userUID === photo.user?.uid;
      setEditable(owner && editMode);
      setViewMode((!editMode && owner) || (isAdmin && !owner));
    } else {
      setEditable(true);
      setIsNew(true);
    }
  }, [photo, auth?.currentUser?.uid, auth?.userProfile?.isAdmin, editMode]);

  const handleSaveTopic = (topic: string) => {
    return handleSave({ topic: topic });
  };

  const handleSaveShortDescription = (description: string) =>
    handleSave({ shortDescription: description });

  const handleSave = async (obj: Partial<Photo>) => {
    try {
      const newObjPhoto = { ...newPhoto, ...obj };
      setNewPhoto(newObjPhoto);

      if (newPhoto.id) {
        await slimbeApi.photos.update(newObjPhoto);
      }
      return true;
    } catch (err) {
      setErrorText(`Zapis zdjęcia jest niemożliwy. ${(err as Error).message}`);
      setShowError(true);
      return false;
    }
  };

  const changeViewMode = (active: boolean) => {
    setViewMode(active);
    setEditable(!active);
  };

  const handleAdd = async () => {
    await slimbeApi.photos.add(newPhoto);
    nav.myPhotos();
  };

  const handleDelete = async () => {
    if (newPhoto.id) {
      await slimbeApi.photos.delete(newPhoto.id);
    }
    nav.myPhotos();
  };
  const paramMode: ParamModeProps = {
    newRecord: isNew,
    viewMode: viewMode,
    editable: editable,
  };
  return (
    <>
      <Container className="p-3 my-form-view">
        <ActionFormButtons
          objectName={'zdjęcie'}
          mode={paramMode}
          onAdd={handleAdd}
          onDelete={handleDelete}
          setViewMode={changeViewMode}
          onGiveUp={nav.myPhotos}
        />
        <Row className="gy-3 mb-3">
          <Col className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
            <Container className="ps-0 pe-0">
              <ImageNode
                title={newPhoto?.topic || ''}
                image={newPhoto?.image || ''}
                editable={editable}
                anyImg={true}
                onSave={img => handleSave({ image: img })}
              />
            </Container>
          </Col>
          <Col className="col-lg-6 col-md-6 d-flex justify-content-center align-self-start">
            <Row className="gy-3 w-100">
              <Col className="col-12 p-0">
                <TitleNode
                  value={newPhoto?.topic || ''}
                  mode={paramMode}
                  onSave={handleSaveTopic}
                />
              </Col>
              <Col className="col-12 p-0 mt-1">
                <AuthorNode
                  mode={paramMode}
                  author={photo?.user?.name || ''}
                  avatar={photo?.user?.image || ''}
                  userId={photo?.user?.id || ''}
                />
              </Col>
              <Col className="col-12 p-0 mt-1">
                <ShortDescriptionNode
                  value={newPhoto?.shortDescription || ''}
                  mode={paramMode}
                  onSave={handleSaveShortDescription}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <ActionFormButtons
          objectName={'zdjęcie'}
          mode={paramMode}
          onAdd={handleAdd}
          onDelete={handleDelete}
          setViewMode={changeViewMode}
          onGiveUp={nav.myPhotos}
        />
      </Container>
      <ValidationAlert
        heading={'Zapis zdjęcia'}
        variant={'warning'}
        show={showError}
        modal={true}
        onHide={() => {
          setShowError(false);
        }}
        errors={[errorText]}
      />
    </>
  );
};
