import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { useNavigation } from '../../../common/navigation';
import bannerMP4 from '../../../assets/videos/banner4.mp4';
import banner from '../../../assets/banner4.png';
import { useAuth } from '../../../common/hooks/useAuth';
import { AuthState } from '../../../common/auth';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { ActionFormButtons } from '../FormControl/ActionFormButtons';
import { SloganType } from '../../../common/slimbeCore/validation/schemas';
import { SloganNode } from '../FormControl/SloganNode';

import { LandingCarouselNode } from './LandingCarouselNode';

export const Landing: React.FC = () => {
  const collectionCount = 20;
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();
  const auth = useAuth();

  const [hideRegisterBtn, setHideRegisterBtn] = useState<boolean>(true);
  const [editable, setEditable] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(false);

  useEffect(() => {
    setHideRegisterBtn(auth.state !== AuthState.LoggedOut);
    setEditable(auth?.userProfile?.isAdmin || false);
  }, [auth.state, auth?.userProfile?.isAdmin]);

  const changeViewMode = (active: boolean) => {
    setViewMode(active);
    setEditable(!active);
  };

  const paramMode: ParamModeProps = {
    newRecord: false,
    viewMode: viewMode,
    editable: editable,
  };

  return (
    <div className="pb-5 landing">
      <div className="me-4">
        <ActionFormButtons mode={paramMode} setViewMode={changeViewMode} />
      </div>
      <div className="main-banner">
        <video autoPlay loop muted playsInline poster={banner}>
          <source src={bannerMP4} type="video/mp4"></source>
        </video>
        <div className="main-banner-text">
          <h1 className="anime-title mb-5">SLIMBE</h1>
          <SloganNode sloganType={SloganType.Main} mode={paramMode} />
          <div className="d-flex justify-content-end mt-3">
            <Button
              hidden={hideRegisterBtn}
              variant="my-outline-opposed-btn"
              onClick={nav.registerProfile}
            >
              Zarejestruj się jako trener
            </Button>
          </div>
        </div>{' '}
      </div>
      <LandingCarouselNode
        sloganTitleType={SloganType.WorkoutTitle}
        sloganType={SloganType.WorkoutMain}
        mode={paramMode}
        smallCard={false}
        maxCount={collectionCount}
        whatCollection="treningów"
        getCardCollecion={slimbeApi.workouts.getAsCardCollection}
        navMoreCardCollection={nav.workouts}
        navCardView={nav.workoutFormView}
      />
      <LandingCarouselNode
        sloganTitleType={SloganType.ProfileTitle}
        sloganType={SloganType.ProfileMain}
        mode={paramMode}
        smallCard={true}
        maxCount={collectionCount}
        whatCollection="trenerów"
        getCardCollecion={slimbeApi.profiles.getAsCardCollection}
        navMoreCardCollection={nav.coaches}
        navCardView={nav.profileView}
      />
      <LandingCarouselNode
        sloganTitleType={SloganType.ArticleTitle}
        sloganType={SloganType.ArticleMain}
        mode={paramMode}
        smallCard={false}
        maxCount={collectionCount}
        whatCollection="artykułów"
        getCardCollecion={slimbeApi.articles.getAsCardCollection}
        navMoreCardCollection={nav.articles}
        navCardView={nav.articleFormView}
      />
    </div>
  );
};
