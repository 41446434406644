import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../common/hooks/useAuth';
import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { Article } from '../../common/slimbeCore/contexts/ArticlesContext';
import { ArticleForm } from '../components/Articles/ArticleForm';
interface ParamTypes {
  id: string;
  editMode: string;
}
export const ArticleFormView: React.FC = () => {
  const slimbeApi = useSlimbeCore();
  const auth = useAuth();
  const { id, editMode } = useParams<ParamTypes>();
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    let mounted = true;
    id &&
      slimbeApi.articles.getById(id).then(art => {
        if (mounted) {
          setArticle(art);
        }
      });

    return () => {
      mounted = false;
    };
  }, [auth?.currentUser?.uid, id, slimbeApi.articles, slimbeApi.profiles]);

  return (
    <>
      {article && (
        <ArticleForm article={article} editMode={editMode === 'true'} />
      )}
      {!id && <ArticleForm editMode={false} />}
    </>
  );
};
