/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetProfileDto,
    GetProfileDtoFromJSON,
    GetProfileDtoFromJSONTyped,
    GetProfileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetWorkoutDto
 */
export interface GetWorkoutDto {
    /**
     * 
     * @type {string}
     * @memberof GetWorkoutDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetWorkoutDto
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetWorkoutDto
     */
    link?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetWorkoutDto
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetWorkoutDto
     */
    time?: Date | null;
    /**
     * 
     * @type {GetProfileDto}
     * @memberof GetWorkoutDto
     */
    user?: GetProfileDto | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWorkoutDto
     */
    areaArray?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetWorkoutDto
     */
    disciplineArray?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetWorkoutDto
     */
    duration?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetWorkoutDto
     */
    active?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetWorkoutDto
     */
    shortDescription?: string | null;
}

export function GetWorkoutDtoFromJSON(json: any): GetWorkoutDto {
    return GetWorkoutDtoFromJSONTyped(json, false);
}

export function GetWorkoutDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetWorkoutDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'user': !exists(json, 'user') ? undefined : GetProfileDtoFromJSON(json['user']),
        'areaArray': !exists(json, 'areaArray') ? undefined : json['areaArray'],
        'disciplineArray': !exists(json, 'disciplineArray') ? undefined : json['disciplineArray'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
    };
}

export function GetWorkoutDtoToJSON(value?: GetWorkoutDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'topic': value.topic,
        'link': value.link,
        'description': value.description,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'user': GetProfileDtoToJSON(value.user),
        'areaArray': value.areaArray,
        'disciplineArray': value.disciplineArray,
        'duration': value.duration,
        'active': value.active,
        'shortDescription': value.shortDescription,
    };
}


