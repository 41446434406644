import * as H from 'history';
import * as Icon from 'react-bootstrap-icons';
import { matchPath } from 'react-router';
import { useHistory } from 'react-router-dom';

import { SignInView } from '../app/containers/SignInView';
import { ArticlesView } from '../slimbe/containers/ArticlesView';
import { ArticleFormView } from '../slimbe/containers/ArticleFormView';
import { CoachesView } from '../slimbe/containers/CoachesView';
import { LandingView } from '../slimbe/containers/LandingView/LandingView';
import { MyArticlesView } from '../slimbe/containers/MyArticlesView';
import { ProfileView } from '../slimbe/containers/ProfileView';
import { RegisterProfileView } from '../slimbe/containers/RegisterProfileView';
import { WorkoutsView } from '../slimbe/containers/WorkoutsView';
import { WorkoutFormView } from '../slimbe/containers/WorkoutFormView';
import { MyWorkoutsView } from '../slimbe/containers/MyWorkoutsView';
import { PasswordResetView } from '../app/containers/PasswordResetView';
import { SearchView } from '../slimbe/containers/SearchView';
import { PhotoFormView } from '../slimbe/containers/PhotoFormView';
import { MyPhotosView } from '../slimbe/containers/MyPhotosView';

export interface MenuItem {
  heading: string;
  path: string;
  icon?: Icon.Icon;
  view: React.ComponentType;
  exact: boolean;
  submenu?: MenuItem[];
  className?: string;
}

export const routesInMenu: MenuItem[] = [
  {
    heading: 'WYSZUKAJ DLA SIEBIE...',
    icon: Icon.Search,
    path: '/search',
    view: SearchView,
    exact: true,
    className: 'search-link-menu',
  },
  {
    heading: 'TRENINGI',
    path: '/workouts',
    view: WorkoutsView,
    exact: true,
  },
  {
    heading: 'ARTYKUŁY',
    path: '/articles',
    view: ArticlesView,
    exact: true,
  },
  {
    heading: 'TRENERZY',
    path: '/coaches',
    view: CoachesView,
    exact: true,
  },
];

export const routesInLoginMenu: MenuItem[] = [
  {
    heading: 'Edycja mojego profilu',
    path: '/myProfile',
    view: ProfileView,
    exact: true,
  },
  {
    heading: 'Moje treningi',
    path: '/myWorkouts',
    view: MyWorkoutsView,
    exact: true,
  },
  {
    heading: 'Moje artykuły',
    path: '/myArticles',
    view: MyArticlesView,
    exact: true,
  },
  {
    heading: 'Moje zdjęcia',
    path: '/myPhotos',
    view: MyPhotosView,
    exact: true,
  },
];

export const routesOutsideMenu: MenuItem[] = [
  {
    heading: 'slimbe',
    path: '/',
    view: LandingView,
    exact: true,
  },
  {
    heading: 'Logowanie',
    path: '/login',
    view: SignInView,
    exact: true,
  },
  {
    heading: 'Reset hasła',
    path: '/passwordReset',
    view: PasswordResetView,
    exact: true,
  },
  {
    heading: 'Trening',
    path: '/workout/:id/:editMode',
    view: WorkoutFormView,
    exact: true,
  },
  {
    heading: 'Nowy trening',
    path: '/workout',
    view: WorkoutFormView,
    exact: true,
  },
  {
    heading: 'Nowy artykuł',
    path: '/article',
    view: ArticleFormView,
    exact: true,
  },
  {
    heading: 'Artykuł',
    path: '/article/:id/:editMode',
    view: ArticleFormView,
    exact: true,
  },
  {
    heading: 'Nowe zdjęcie',
    path: '/photo',
    view: PhotoFormView,
    exact: true,
  },
  {
    heading: 'Zdjęcie',
    path: '/photo/:id/:editMode',
    view: PhotoFormView,
    exact: true,
  },
  {
    heading: 'Profil',
    path: '/profile/:id',
    view: ProfileView,
    exact: true,
  },
  {
    heading: 'Zarejestruj się',
    path: '/registerProfile',
    view: RegisterProfileView,
    exact: true,
  },
  {
    heading: 'Szukaj',
    path: '/search',
    view: SearchView,
    exact: true,
  },
  {
    heading: 'slimbe',
    path: '*',
    view: LandingView,
    exact: true,
  },
];

export const currentMenuItems = (activePage: string, login: boolean) => {
  const items: MenuItem[] = [];

  for (const position of routesInMenu) {
    items.push(position);

    for (const subposition of position.submenu || []) {
      if (matchPath(activePage, subposition.path)) {
        items.push(subposition);
      }
    }
  }
  if (!login) {
    for (const position of routesInLoginMenu) {
      items.push(position);

      for (const subposition of position.submenu || []) {
        if (matchPath(activePage, subposition.path)) {
          items.push(subposition);
        }
      }
    }
  }

  return items;
};

export const currentProfileMenuItems = (activePage: string) => {
  const items: MenuItem[] = [];
  for (const position of routesInLoginMenu) {
    items.push(position);

    for (const subposition of position.submenu || []) {
      if (matchPath(activePage, subposition.path)) {
        items.push(subposition);
      }
    }
  }
  return items;
};

export const useNavigation = () => {
  const actions = (history: H.History, url: string) => history.push(url);
  const history = useHistory();

  return {
    menuItem: (location: string) => actions(history, location),
    startPage: () => actions(history, '/'),
    login: () => actions(history, '/login'),

    myProfile: () => actions(history, '/myProfile'),
    myWorkouts: () => actions(history, '/myWorkouts'),
    myArticles: () => actions(history, '/myArticles'),
    myPhotos: () => actions(history, '/myPhotos'),

    articles: () => actions(history, '/articles'),
    workouts: () => actions(history, '/workouts'),
    coaches: () => actions(history, '/coaches'),

    coachView: (uid: string | undefined) => actions(history, `/profile/${uid}`),

    workoutFormView: (id: string | undefined, editMode = false) =>
      actions(history, id ? `/workout/${id}/${editMode}` : `/workout`),
    articleFormView: (id: string | undefined, editMode = false) =>
      actions(history, id ? `/article/${id}/${editMode}` : `/article`),
    photoFormView: (id: string | undefined, editMode = false) =>
      actions(history, id ? `/photo/${id}/${editMode}` : `/photo`),

    profileView: (id: string | undefined) => actions(history, `/profile/${id}`),

    registerProfile: () => actions(history, '/registerProfile'),
    passwordReset: () => actions(history, '/passwordReset'),
    reload: () => history.go(0),
  };
};
