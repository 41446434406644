import React from 'react';
import {
  // eslint-disable-next-line import/named
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  // eslint-disable-next-line import/named
  UserCredential,
} from 'firebase/auth';

import { Profile } from './slimbeCore/contexts/ProfilesContext';

export interface SlimbeUser {
  uid: string;
  email: string;
  name: string;
  id: string;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export enum AuthState {
  Unknown,
  LoggedIn,
  LoggedOut,
}

interface AuthProviderState {
  state: AuthState;
  currentUser?: SlimbeUser;
  userProfile?: Profile;
  ensureTokenIsValid: () => Promise<void>;
  logout: () => Promise<void>;
  updateProfile: (profile?: Profile) => boolean;
  updateEmailCurrentUser: () => boolean;
}

const unknownState = {
  state: AuthState.Unknown,
  currentUser: undefined,
  userProfile: undefined,
  ensureTokenIsValid: async () => {
    false;
  },
  logout: () => {
    return Promise.resolve(undefined);
  },
  updateProfile: () => false,
  updateEmailCurrentUser: () => false,
};

export const AuthContext = React.createContext<AuthProviderState>(unknownState);

export class AuthProvider extends React.Component<
  AuthProviderProps,
  AuthProviderState
> {
  constructor(props: AuthProviderProps) {
    super(props);
    this.state = unknownState;
  }

  async tokenRefresh(authFirebase: Auth) {
    authFirebase.currentUser
      ?.getIdToken(true)
      .then(idToken => {
        return localStorage.setItem('mzt-token', idToken);
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem tokenu. ${error}`),
      );
  }

  componentDidMount() {
    const authFirebase = getAuth();

    const state: AuthProviderState = unknownState;

    state.logout = async () => {
      localStorage.removeItem('mzt-token');
      localStorage.clear();
      sessionStorage.clear();
      return signOut(getAuth());
    };

    state.updateProfile = (profile: Profile | undefined) => {
      const newState = state;
      newState.userProfile = profile;
      this.setState(newState);

      return true;
    };

    state.updateEmailCurrentUser = () => {
      const user = authFirebase.currentUser;

      if (user) {
        state.state = AuthState.LoggedIn;

        state.currentUser = {
          uid: user?.uid,
          email: user?.email,
          name: user?.displayName,
        } as SlimbeUser;

        this.setState(state);
      }
      return true;
    };

    this.setState(state);

    authFirebase.onIdTokenChanged(async () => {
      await this.tokenRefresh(authFirebase);
    });

    authFirebase.onAuthStateChanged(async user => {
      await this.tokenRefresh(authFirebase);

      if (user) {
        state.state = AuthState.LoggedIn;

        state.currentUser = {
          uid: user?.uid,
          email: user?.email,
          name: user?.displayName,
        } as SlimbeUser;
      } else {
        state.state = AuthState.LoggedOut;
        state.userProfile = undefined;
        state.currentUser = undefined;
        // localStorage.removeItem('mzt-token');
        // sessionStorage.removeItem('user');
      }
      this.setState(state);
    });
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }

  static createUserWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<UserCredential> {
    const authFirebase = getAuth();
    return createUserWithEmailAndPassword(authFirebase, email, password);
  }

  static signInWithEmailAndPassword(
    username: string,
    password: string,
  ): Promise<UserCredential> {
    const authFirebase = getAuth();
    return signInWithEmailAndPassword(authFirebase, username, password);
  }

  static resetPassword(email: string) {
    const authFirebase = getAuth();
    return sendPasswordResetEmail(authFirebase, email);
  }

  static updateEmail(email?: string) {
    const currentUser = getAuth().currentUser;
    if (!email || !currentUser) {
      return Promise.resolve();
    }
    return updateEmail(currentUser, email);
  }

  static updatePassword(pass: string) {
    const currentUser = getAuth().currentUser;
    if (!pass || !currentUser) {
      return Promise.resolve();
    }
    return updatePassword(currentUser, pass);
  }
}
