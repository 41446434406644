import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { EditableFormNode } from '../EditableCollection/EditableNode';

import { EditStringNodeProps } from './EditStringNodeProps';

export interface ProfileInputNodeProps extends EditStringNodeProps {
  icon: Icon.Icon;
  label: string;
  type: string;
  placeholder: string;
}

export const ProfileInputHyperLinkNode: React.FC<ProfileInputNodeProps> = ({
  icon,
  label,
  type,
  placeholder,
  value,
  mode,
  onSave,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const setFocusInput = () => {
    inputRef.current?.focus();
  };
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const txt = e.target.value;
    setInputValue(txt);
    if (mode.newRecord) {
      await onSave(txt);
    }
  };
  const Icon = icon;
  const inputType = type === 'email' ? 'email' : 'text';
  return (
    <EditableFormNode
      mode={mode}
      viewNode={
        <>
          {(mode.editable || inputValue) && (
            <div className="profile-input">
              <a
                href={
                  (type === 'email'
                    ? 'mailto://'
                    : type === 'www'
                    ? inputValue.includes('https://') ||
                      inputValue.includes('http://')
                      ? ''
                      : 'https://'
                    : type === 'phone'
                    ? 'tel:'
                    : '') + inputValue
                }
                title={label}
                target="_blank"
                rel="noreferrer"
              >
                <Icon />
                {inputValue}
              </a>
            </div>
          )}
        </>
      }
      editableNode={
        <Form.Group
          className="form-floating col-10 col-md-11"
          controlId="inputControl"
        >
          <Form.Control
            type={inputType}
            defaultValue={inputValue}
            placeholder={placeholder}
            onChange={onChange}
            ref={inputRef}
          />
          <Form.Label>{label}</Form.Label>
        </Form.Group>
      }
      onSave={() => onSave(inputValue)}
      onClose={() => setInputValue(value)}
      setFocus={setFocusInput}
    />
  );
};
