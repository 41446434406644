import React, { useEffect, useState } from 'react';

import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { TagEntry } from '../../../common/slimbeCore/contexts/DisciplinesContext';

import { TagsSelect } from './TagsSelect';

interface DisciplinesProps {
  disciplineArray: string[];
  propertyChange: (disciplineArray: string[]) => void;
  focus?: boolean;
}
export const DisciplinesSelect: React.FC<DisciplinesProps> = props => {
  const slimbeApi = useSlimbeCore();

  const [tagsDiscipline, setTagsDiscipline] = useState<TagEntry[]>([]);

  const [selectedDiscipline, setSelectedDiscipline] = useState<TagEntry[]>([]);

  useEffect(() => {
    let mounted = true;

    slimbeApi.disciplinesEntry
      .then(t => {
        if (mounted) {
          setTagsDiscipline(t || []);
        }
      })
      .catch(error =>
        console.log(`Wystąpił problem z pobraniem dyscyplin. ${error}`),
      );

    return () => {
      mounted = false;
    };
  }, [slimbeApi]);

  useEffect(() => {
    const selection = [];
    for (const v of props.disciplineArray) {
      const pos = tagsDiscipline.filter(o => o.value === v)[0];
      selection.push(pos);
    }
    setSelectedDiscipline(selection);
  }, [props.disciplineArray, tagsDiscipline]);

  return (
    <>
      <TagsSelect
        focus={props.focus}
        tags={tagsDiscipline}
        selected={selectedDiscipline}
        onChange={options =>
          props.propertyChange(options.map(o => (o as TagEntry).value || ''))
        }
        showTags={false}
        withCheckbox={false}
      />
    </>
  );
};
