/* tslint:disable */
/* eslint-disable */
/**
 * SLIMBE
 * Opis api slimbe
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdatePhotoDto
 */
export interface CreateUpdatePhotoDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePhotoDto
     */
    topic?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePhotoDto
     */
    shortDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePhotoDto
     */
    image?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdatePhotoDto
     */
    time?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdatePhotoDto
     */
    userUID?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdatePhotoDto
     */
    active?: boolean | null;
}

export function CreateUpdatePhotoDtoFromJSON(json: any): CreateUpdatePhotoDto {
    return CreateUpdatePhotoDtoFromJSONTyped(json, false);
}

export function CreateUpdatePhotoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdatePhotoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'topic': !exists(json, 'topic') ? undefined : json['topic'],
        'shortDescription': !exists(json, 'shortDescription') ? undefined : json['shortDescription'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'time': !exists(json, 'time') ? undefined : (json['time'] === null ? null : new Date(json['time'])),
        'userUID': !exists(json, 'userUID') ? undefined : json['userUID'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function CreateUpdatePhotoDtoToJSON(value?: CreateUpdatePhotoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'topic': value.topic,
        'shortDescription': value.shortDescription,
        'image': value.image,
        'time': value.time === undefined ? undefined : (value.time === null ? null : value.time.toISOString()),
        'userUID': value.userUID,
        'active': value.active,
    };
}


