import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useSlimbeCore } from '../../../common/hooks/useSlimbeCore';
import { useCookies } from '../../../common/hooks/useCookies';
import { ArticleEntity } from '../../../common/slimbeCore/contexts/ArticlesContext';
import { WorkoutEntity } from '../../../common/slimbeCore/contexts/WorkoutsContext';
import { Profile } from '../../../common/slimbeCore/contexts/ProfilesContext';

type LikeProps = {
  object: ArticleEntity | WorkoutEntity | Profile;
};

export const Like: React.FC<LikeProps> = ({ object }) => {
  const slimbeApi = useSlimbeCore();
  const cookies = useCookies();
  const [likes, setLikes] = useState(0);
  const [myLike, setMyLike] = useState<boolean>(false);

  useEffect(() => {
    if (object.id && object) {
      'contents' in object
        ? slimbeApi.likes
            .getCountForArticle(object.id)
            .then(count => setLikes(count))
            .catch(error =>
              console.log(
                `Wystąpił problem z pobraniem liczby polubień artykułu. ${error}`,
              ),
            )
        : 'link' in object
        ? slimbeApi.likes
            .getCountForWorkout(object.id)
            .then(count => setLikes(count))
            .catch(error =>
              console.log(
                `Wystąpił problem z pobraniem liczby polubień treningu. ${error}`,
              ),
            )
        : slimbeApi.likes
            .getCountForProfile(object.id)
            .then(count => setLikes(count))
            .catch(error =>
              console.log(
                `Wystąpił problem z pobraniem liczby polubień profilu. ${error}`,
              ),
            );
      const likeGuid = cookies.likeGuid();
      if (likeGuid) {
        'contents' in object
          ? slimbeApi.likes
              .getByGuidArticle(likeGuid, object.id)
              .then(isMyLike => {
                setMyLike(isMyLike);
              })
              .catch(error =>
                console.log(
                  `Wystąpił problem z pobraniem moich polubień. ${error}`,
                ),
              )
          : 'link' in object
          ? slimbeApi.likes
              .getByGuidWorkout(likeGuid, object.id)
              .then(isMyLike => {
                setMyLike(isMyLike);
              })
              .catch(error =>
                console.log(
                  `Wystąpił problem z pobraniem moich polubień. ${error}`,
                ),
              )
          : slimbeApi.likes
              .getByGuidProfile(likeGuid, object.id)
              .then(isMyLike => {
                setMyLike(isMyLike);
              })
              .catch(error =>
                console.log(
                  `Wystąpił problem z pobraniem moich polubień. ${error}`,
                ),
              );
      }
    }
  }, [object, slimbeApi.likes, cookies]);

  const handleLike = async () => {
    const likeGuid = cookies.likeGuid();

    if (likeGuid && object.id) {
      const wynik =
        'contents' in object
          ? await slimbeApi.likes.createForArticle(likeGuid, object)
          : 'link' in object
          ? await slimbeApi.likes.createForWorkout(likeGuid, object)
          : await slimbeApi.likes.createForProfile(likeGuid, object);
      if (wynik) {
        'contents' in object
          ? slimbeApi.likes
              .getCountForArticle(object.id)
              .then(count => setLikes(count))
              .catch(error =>
                console.log(
                  `Wystąpił problem z pobraniem liczby polubień artykułu. ${error}`,
                ),
              )
          : 'link' in object
          ? slimbeApi.likes
              .getCountForWorkout(object.id)
              .then(count => setLikes(count))
              .catch(error =>
                console.log(
                  `Wystąpił problem z pobraniem liczby polubień treningu. ${error}`,
                ),
              )
          : slimbeApi.likes
              .getCountForProfile(object.id)
              .then(count => setLikes(count))
              .catch(error =>
                console.log(
                  `Wystąpił problem z pobraniem liczby polubień profilu. ${error}`,
                ),
              );

        setMyLike(!myLike);
      }
    }
  };

  return (
    <div className="col-md-12 col-sm-12 col-sx-12 d-flex justify-content-end p-0">
      <Button variant="my-outline-btn" onClick={() => handleLike()}>
        {myLike ? <Icon.HandThumbsUpFill /> : <Icon.HandThumbsUp />}
        {` ${likes}`}
      </Button>
    </div>
  );
};
