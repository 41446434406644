import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import 'react-quill/dist/quill.snow.css'; // rich-text-editor
import 'react-loading-skeleton/dist/skeleton.css';
import '../theme/theme.scss';
import '../theme/contrastDefault.scss';
import 'moment/locale/pl';

import { AuthProvider } from '../common/auth';
import { FirebaseContextProvider } from '../common/firebase';
import { SlimbeCoreProvider } from '../common/slimbeCore';
import FacebookPixel from '../tracking/FacebookPixel';

import { Routes } from './containers/Routes';

function App() {
  const createElement = (url: string) => {
    const element = document.createElement('link');
    element.rel = 'stylesheet';
    element.href = url;
    return element;
  };

  const url =
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

  useEffect(() => {
    const element = createElement(url);
    document.head.appendChild(element);
  }, []);

  return (
    <FirebaseContextProvider>
      <AuthProvider>
        <SlimbeCoreProvider>
          {process.env.REACT_APP_PIXEL_ID && <FacebookPixel />}
          <Routes />
        </SlimbeCoreProvider>
      </AuthProvider>
    </FirebaseContextProvider>
  );
}

export default App;
