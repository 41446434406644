import React, { useState } from 'react';

import { EditableFormNode } from '../EditableCollection/EditableNode';
import { ParamModeProps } from '../FormControl/EditStringNodeProps';
import { AreasSelect } from '../Tags/AreasSelect';

import { AreaBadges, MainAreaBadges } from './TagsBadges';

interface AreasSelectorProps {
  stringArray: string[];
  mode: ParamModeProps;
  onSave: (stringArray: string[]) => Promise<boolean>;
  setSelectedTags?: (tags: string[]) => void;
}

export const AreasSelector: React.FC<AreasSelectorProps> = ({
  stringArray,
  mode,
  onSave,
  setSelectedTags,
}) => {
  const [areaArray, setAreaArray] = useState<string[]>(stringArray);
  const [areaSelectFocus, setAreaSelectFocus] = useState<boolean>(true);

  const onChange = async (area: string[]) => {
    setAreaArray(area);
    if (mode.newRecord) {
      await onSave(area);
    }
  };

  const handleSave = () => {
    setAreaArray(areaArray);
    return onSave(areaArray);
  };
  return (
    <EditableFormNode
      mode={mode}
      viewNode={
        <div className="select-control h-100">
          <span className="form-label">Obszary</span>
          <div className="pt-1">
            <MainAreaBadges stringArray={areaArray} />
          </div>
          <div className="pt-1">
            <AreaBadges stringArray={areaArray} />
          </div>
        </div>
      }
      editableNode={
        <div className="select-control h-100">
          <span className="form-label">Obszary</span>
          <div className="pt-3">
            <AreasSelect
              areaArray={areaArray}
              propertyChange={onChange}
              mainAreasChange={tags => setSelectedTags && setSelectedTags(tags)}
              focus={areaSelectFocus}
            />
          </div>
        </div>
      }
      setFocus={() => setAreaSelectFocus(true)}
      onClose={() => {
        setAreaArray(stringArray);
        setSelectedTags && setSelectedTags(stringArray);
      }}
      onSave={handleSave}
    />
  );
};
