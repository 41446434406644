import React, { useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Layout from '../components/Layout';
import {
  routesInMenu,
  routesOutsideMenu,
  MenuItem,
  routesInLoginMenu,
} from '../../common/navigation';
import ErrorBoundary from '../../common/components/ErrorBoundary/ErrorBoundary';
import AlertModal from '../components/AlertModal';
import { useAuth } from '../../common/hooks/useAuth';
import SessionTimeout from '../components/SessionTimeout';
import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';

const allMenuItemRoutes = (menuitem: MenuItem) => {
  const submenu = menuitem.submenu?.map(subitem => (
    <Route
      key={subitem.path}
      path={subitem.path}
      exact={subitem.exact}
      component={subitem.view}
    />
  ));
  const item = (
    <Route
      key={menuitem.path}
      path={menuitem.path}
      exact={menuitem.exact}
      component={menuitem.view}
    />
  );

  if (!submenu) {
    return item;
  }

  return [...submenu, item];
};

export const Routes: React.FC = () => {
  const auth = useAuth();
  const slimbe = useSlimbeCore();

  const routing = [
    ...routesInMenu.map(route => allMenuItemRoutes(route)),
    ...routesInLoginMenu.map(route => allMenuItemRoutes(route)),
    ...routesOutsideMenu.map(route => allMenuItemRoutes(route)),
  ];

  useEffect(() => {
    const user = sessionStorage.getItem('user');
    const cUser = auth.currentUser;

    if (cUser && (!user || !auth.userProfile)) {
      slimbe.profiles
        .getByUserUID(cUser?.uid)
        .then(profile => {
          if (profile?.id) {
            auth.updateProfile(profile);
          }
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem profilu. ${error}`),
        );
      sessionStorage.setItem('user', cUser.uid);
    }
  }, [auth, slimbe.profiles]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Route
          render={({ location }) => (
            <Layout location={location.pathname}>
              <Switch>{routing}</Switch>
              <SessionTimeout timeoutInMinutes={60} />
            </Layout>
          )}
        />
        <AlertModal variant="danger">{}</AlertModal>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
