import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import { Container, Image } from 'react-bootstrap';

import { currentMenuItems, useNavigation } from '../../common/navigation';
import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { useAuth } from '../../common/hooks/useAuth';
import { AuthState } from '../../common/auth';

import ProfileButton from './ProfileButton';

interface NavMainProps {
  activePage: string;
  setContrast: (className: string) => void;
}

const NavMain: React.FC<NavMainProps> = ({ activePage }) => {
  const nav = useNavigation();
  const slimbeApi = useSlimbeCore();
  const authFirebase = useAuth();

  const [currentLinks, setCurrentLinks] = useState(
    currentMenuItems(activePage, authFirebase.state === AuthState.LoggedIn),
  );

  useEffect(() => {
    setCurrentLinks(currentMenuItems(activePage, true));
  }, [authFirebase.state, activePage, authFirebase, slimbeApi.profiles]);
  return (
    <>
      <Navbar
        expand="lg"
        collapseOnSelect
        variant="dark"
        role="banner"
        as="header"
        className="mainbar"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" label="Menu" />
          <Navbar.Brand href="/" className="me-auto">
            <Image className="img-logo-slimbe" alt="Logo Slimbe" />
          </Navbar.Brand>
          <NavbarCollapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {currentLinks.map(({ icon, path, heading, exact, className }) => {
                const Icon = icon ? icon : 'p';
                const tmp = icon ? <Icon /> : '';
                return (
                  <Nav.Link
                    className={'mainbarlink ' + className}
                    key={path}
                    href={path + '#'}
                    onClick={() => nav.menuItem(path)}
                    active={
                      exact ? activePage === path : activePage.startsWith(path)
                    }
                    tabIndex={0}
                  >
                    {tmp} {heading}
                  </Nav.Link>
                );
              })}
            </Nav>
          </NavbarCollapse>
        </Container>
        <ProfileButton activePage={activePage} />
      </Navbar>
    </>
  );
};

export default NavMain;
