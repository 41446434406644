import React from 'react';

import { SignInDialog } from '../components/SignIn/SignInDialog';
import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';

export const SignInView: React.FC = () => {
  const slimbeApi = useSlimbeCore();

  const onSignIn = async (username: string, password: string) => {
    return await slimbeApi.signIn.signIn(username, password);
  };

  const signInWithFacebook = async () => {
    return await slimbeApi.signIn.signInWithFacebook();
  };

  const signInWithGoogle = async () => {
    return await slimbeApi.signIn.signInWithGoogle();
  };

  return (
    <SignInDialog
      onSignIn={onSignIn}
      signInWithGoogle={signInWithGoogle}
      signInWithFacebook={signInWithFacebook}
      open={true}
    />
  );
};
