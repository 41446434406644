import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Profile } from '../../common/slimbeCore/contexts/ProfilesContext';
import { useSlimbeCore } from '../../common/hooks/useSlimbeCore';
import { useAuth } from '../../common/hooks/useAuth';
import { ProfileInfo } from '../components/Profile/ProfileInfo';

interface ParamTypes {
  id: string;
}

export type ProfileViewProps = {
  activeTab?: string;
};

export const ProfileView: React.FC<ProfileViewProps> = ({ activeTab }) => {
  const slimbeApi = useSlimbeCore();
  const auth = useAuth();

  const { id } = useParams<ParamTypes>();
  const [profile, setProfile] = useState<Profile | undefined>();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (id) {
      slimbeApi.profiles
        .getById(id)
        .then(p => {
          if (mounted) {
            const userUID = '' + auth?.currentUser?.uid;
            const isOwner = userUID === p?.uid;
            setProfile(p);
            setEditMode(isOwner);
          }
        })
        .catch(error =>
          console.log(`Wystąpił problem z pobraniem profilu. ${error}`),
        );
    } else {
      setProfile(auth.userProfile);
      setEditMode(true);
    }

    return () => {
      mounted = false;
    };
  }, [auth, id, slimbeApi.profiles]);

  return (
    <>
      {profile && (
        <ProfileInfo
          editMode={editMode}
          profile={profile}
          activeTab={activeTab}
        />
      )}
    </>
  );
};
